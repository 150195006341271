// import React from "react";
// import { Route, Navigate, RouteProps } from "react-router-dom";
// import { hasRights } from "../_helper/helper";

// interface PrivateRouteProps extends Omit<RouteProps, "element"> {
//   element: React.ComponentType<any>;
//   menuKey: string;
// }

// const PrivateRoute: React.FC<PrivateRouteProps> = ({
//   element: Component,
//   menuKey,
//   ...rest
// }) => {
//   const isAuthenticated = !!localStorage.getItem("authToken");

//   const routeProps: RouteProps = {
//     ...rest,
//     element:
//       isAuthenticated && hasRights(menuKey) ? (
//         <Component />
//       ) : (
//         <Navigate to="/login" />
//       ),
//     lazy: undefined,
//     index: false,
//   };

//   return <Route {...routeProps} />;
// };

// export default PrivateRoute;

import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: any }) => {
  const isAuthenticated = localStorage.getItem("Token");

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default PrivateRoute;
