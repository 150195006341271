import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IHotelBlock,
  IHotelBlockState,
  IRoomData,
  ITourDateList,
  ITourList,
} from "./hotelBlockModel";
import {
  ActiveInactiveHotelBlock,
  createHotelBlock,
  deleteHotelBlock,
  getAllActiveHotelBlocks,
  getAllHotelBlocks,
  getHotelBlockByID,
  getHotelListByTour,
  getTourBySectorID,
  getTourDates,
  updateHotelBlock,
} from "./services/hotelBlock.services";

const initialState: IHotelBlockState = {
  loading: false,
  error: "",
  HotelBlockList: [],
  HotelList: [],
  TourList: [],
  TourDateList: [],
  HotelBlockID: null,
  deleteIndex: -1,
  HotelBlockDetail: {
    SectorID: null,
    TourID: null,
    TotalNoOfRooms: null,
    TourDateID: null,
    TourDate: "",
    TourDateCode: "",
    TotalAmount: null,
    AdvanceAmount: null,
    RemainingAmount: null,
    ChequeNo: "",
    BankName: "",
    ChequeDate: "",
    BookBy: null,
    Remarks: "",
    IsActive: true,
    RoomData: [],
  },
};

const hotelBlockSlice = createSlice({
  name: "hotelBlock",
  initialState,
  reducers: {
    clearHotelBlockDetails: (state) => {
      state.HotelBlockDetail = initialState.HotelBlockDetail;
    },
    clearTourDateList: (state) => {
      state.TourDateList = [];
    },
    setHotelBlockID: (state, action) => {
      state.HotelBlockID = action.payload;
    },
    clearHotelBlockID: (state) => {
      state.HotelBlockID = initialState.HotelBlockID;
    },
    setHotelRoomDataDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHotelBlocks.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllHotelBlocks.fulfilled,
      (state, action: PayloadAction<IHotelBlock[]>) => {
        state.loading = false;
        state.HotelBlockList = action.payload || [];
      }
    );
    builder.addCase(getAllHotelBlocks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelBlockList = [];
    });
    builder.addCase(getAllActiveHotelBlocks.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveHotelBlocks.fulfilled,
      (state, action: PayloadAction<IHotelBlock[]>) => {
        state.loading = false;
        state.HotelBlockList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveHotelBlocks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelBlockList = [];
    });
    builder.addCase(getTourBySectorID.pending, (state) => {
      // state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBySectorID.fulfilled,
      (state, action: PayloadAction<ITourList[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getTourBySectorID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(getTourDates.pending, (state) => {
      // state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourDates.fulfilled,
      (state, action: PayloadAction<ITourDateList[]>) => {
        state.loading = false;
        state.TourDateList = action.payload || [];
      }
    );
    builder.addCase(getTourDates.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourDateList = [];
    });
    builder.addCase(getHotelListByTour.pending, (state) => {
      // state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getHotelListByTour.fulfilled,
      (state, action: PayloadAction<IRoomData[]>) => {
        state.loading = false;
        state.HotelBlockDetail = {
          ...state.HotelBlockDetail,
          RoomData: action.payload || [],
        };
      }
    );
    builder.addCase(getHotelListByTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelList = [];
    });
    builder.addCase(createHotelBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createHotelBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createHotelBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateHotelBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHotelBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateHotelBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteHotelBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHotelBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteHotelBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveHotelBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveHotelBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveHotelBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getHotelBlockByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getHotelBlockByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.HotelBlockDetail = action.payload;
    });
    builder.addCase(getHotelBlockByID.rejected, (state, action) => {
      state.loading = false;
      state.HotelBlockDetail = initialState.HotelBlockDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearHotelBlockID,
  setHotelBlockID,
  clearTourDateList,
  clearHotelBlockDetails,
  setHotelRoomDataDeleteIndex,
} = hotelBlockSlice.actions;
export default hotelBlockSlice.reducer;
