import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { clearPaymentReceiptLedgerList } from "../reportSlice";
import FormTextField from "../../../components/formFields/FormTextField";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { COMPANY_LIST_STATIC } from "../../../_contstants/common";

const ManageLedgerForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);

  useEffect(() => {
    return () => {
      dispatch(clearPaymentReceiptLedgerList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      BookingNo: values?.BookingNo,
      CompanyID: values?.CompanyID,
    };
    window.open(`ledgerreportview/${payload?.BookingNo}/${payload?.CompanyID}`);
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 20, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Ledger Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyID"
                      label="Company"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={COMPANY_LIST_STATIC?.map((company: any) => {
                        return {
                          value: company?.ID,
                          label: company?.CompanyName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="BookingNo"
                      label="Booking No"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, left: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ManageLedgerForm;
