import React, { useEffect } from "react";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ShadowCard from "../../components/common/ShadowCard";
import { Link } from "react-router-dom";
import DashBoardCard from "./DashBoardCard";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import moment from "moment";
import { getFilterFromToDate } from "../../_helper/helper";
import { getDashboard } from "./services/dashboard.services";
import { setDateFilter } from "./dashboardSlice";
import { IoTicketOutline } from "react-icons/io5";
import { GiTakeMyMoney } from "react-icons/gi";
import { TbPlaneDeparture } from "react-icons/tb";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { DateRangeFilter } from "../../_contstants/common";
// import { LoadingPanel } from "../../components/layout/Loading";

interface DateFilterChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const DateFilterChangeWatcher: React.FC<DateFilterChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const SelectedDate = formRenderProps.valueGetter("SelectedDate");
  const FromDate = formRenderProps.valueGetter("FromDate");
  const ToDate = formRenderProps.valueGetter("ToDate");
  const next15Days = new Date(new Date().setDate(new Date().getDate() + 15));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (SelectedDate !== 6) {
      formRenderProps.onChange("FromDate", { value: null });
      formRenderProps.onChange("ToDate", { value: null });
      const filter = getFilterFromToDate(SelectedDate);
      if (filter.FromDate === "" && filter.ToDate === "") {
        const DefaultDate = {
          FromdDate: moment(new Date()).format("YYYY-MM-DD"),
          TodDate: moment(next15Days).format("YYYY-MM-DD"),
        };
        dispatch(getDashboard(DefaultDate));
      } else {
        dispatch(getDashboard(filter));
      }
      dispatch(setDateFilter({ SelectedDate, ...filter }));
    }
  }, [SelectedDate]);
  useEffect(() => {
    const filter = {
      FromDate: FromDate
        ? moment(FromDate).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD"),
      ToDate: ToDate
        ? moment(ToDate).format("YYYY-MM-DD")
        : moment(next15Days).format("YYYY-MM-DD"),
    };
    if (FromDate && ToDate) {
      dispatch(getDashboard(filter));
      dispatch(setDateFilter({ SelectedDate, ...filter }));
    }
  }, [FromDate, ToDate]);
  return null;
};

const Dashboard: React.FC = () => {
  // const navigate = useNavigate();

  const dashboardDetails = useAppSelector(
    (state) => state.dashboard.DashboardDetails
  );
  // const loading = useAppSelector((state) => state.dashboard.loading);
  // const gridRef = useRef<any>(null);
  const dateFilter = useAppSelector((state) => state?.dashboard?.dateFilter);
  const formatedDateFilter = {
    ...dateFilter,
    FromDate: dateFilter.FromDate
      ? moment(dateFilter?.FromDate, "YYYY-MM-DD").toDate()
      : "",
    ToDate: dateFilter.ToDate
      ? moment(dateFilter?.ToDate, "YYYY-MM-DD").toDate()
      : "",
  };
  const today = moment(new Date()).format("YYYY-MM-DD");

  const next15Days = moment(
    new Date(new Date().setDate(new Date().getDate() + 15))
  ).format("YYYY-MM-DD");
  // if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {/* <Typography.h4 style={{ margin: "50px", textAlign: "center" }}>
        Welcome to the Portal Radhe Krishna Tourism
      </Typography.h4> */}
      <GridLayout
        style={{ marginBottom: 50 }}
        gap={{ rows: 20, cols: 20 }}
        // cols={[{ width: "70%" }, { width: "30%" }]}
      >
        {/* LEFT SIDE */}
        <GridLayoutItem style={{ width: "100%" }}>
          <ShadowCard style={{ padding: 12 }}>
            <Form
              onSubmit={() => {}}
              initialValues={formatedDateFilter}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "100%" }}>
                  <GridLayout
                    style={{ marginRight: 40 }}
                    gap={{ rows: 20, cols: 20 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem>
                      <Typography.h6 style={{ margin: 0 }}>
                        My Dashboard
                      </Typography.h6>
                    </GridLayoutItem>
                    <GridLayoutItem></GridLayoutItem>
                    <GridLayoutItem className="d-flex justify-content-end align-items-center ">
                      {formRenderProps.valueGetter("SelectedDate")
                        ? ""
                        : "Next 15 Days Data!"}
                      {formRenderProps.valueGetter("SelectedDate") === 6 && (
                        <>
                          <Field
                            wrapperStyle={{
                              margin: "0 0 0 10px",
                              width: 150,
                              flex: "none",
                            }}
                            name="FromDate"
                            component={FormDatePicker}
                            size={"small"}
                            format={"dd/MM/yyyy"}
                            placeholder="From Date"
                          />
                          <Field
                            wrapperStyle={{
                              margin: "0 0 0 10px",
                              width: 150,
                              flex: "none",
                            }}
                            name="ToDate"
                            component={FormDatePicker}
                            format={"dd/MM/yyyy"}
                            size={"small"}
                            minDate={formRenderProps.valueGetter("FromDate")}
                            placeholder="To Date"
                          />
                        </>
                      )}
                      <Field
                        wrapperStyle={{
                          margin: "0 0 0 10px",
                          width: 150,
                          flex: "none",
                        }}
                        name="SelectedDate"
                        component={FormSelectionField}
                        size={"small"}
                        placeholder="Filter"
                        options={DateRangeFilter?.map((date: any) => {
                          return {
                            value: date.value,
                            label: date.label,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    {/* <GridLayoutItem>
                <Typography.h6 style={{ margin: 0, textAlign: "end" }}>
                  Group Code :{" "}
                  <span style={{ fontWeight: "bolder", color: "forestgreen" }}>
                    {DashboardDetails?.GroupCode}
                  </span>
                </Typography.h6>
              </GridLayoutItem> */}
                    <GridLayoutItem>
                      <Link
                        to={
                          !dashboardDetails?.TotalAirlineVoucher
                            ? "#"
                            : "/dashboard/totalairlinevoucher"
                        }
                        // to={"/dashboard/totalairlinevoucher"}
                        state={{
                          filter: {
                            FromDate: dateFilter?.FromDate || today,
                            ToDate: dateFilter?.ToDate || next15Days,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <DashBoardCard
                          icon={IoTicketOutline}
                          amount={dashboardDetails?.TotalAirlineVoucher}
                          cardColor="#5473E8"
                          title="Total Airline Vouchers"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={
                          !dashboardDetails?.PendingPayment
                            ? "#"
                            : "/dashboard/pendingpayment"
                        }
                        state={{
                          filter: {
                            FromDate: dateFilter?.FromDate || today,
                            ToDate: dateFilter?.ToDate || next15Days,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <DashBoardCard
                          icon={GiTakeMyMoney}
                          amount={`₹${dashboardDetails?.PendingPayment}`}
                          cardColor="#36C2CF"
                          title="Total Pending Payments"
                        />
                      </Link>
                    </GridLayoutItem>
                    <GridLayoutItem>
                      <Link
                        to={
                          !dashboardDetails?.TotalDeparture
                            ? "#"
                            : "/dashboard/totaldeparture"
                        }
                        state={{
                          filter: {
                            FromDate: dateFilter?.FromDate || today,
                            ToDate: dateFilter?.ToDate || next15Days,
                          },
                        }}
                        style={{ textDecoration: "none" }}
                      >
                        <DashBoardCard
                          icon={TbPlaneDeparture}
                          amount={dashboardDetails?.TotalDeparture}
                          cardColor="#FB9F26"
                          title="Total Departure"
                        />
                      </Link>
                    </GridLayoutItem>
                    <DateFilterChangeWatcher
                      formRenderProps={formRenderProps}
                    />
                  </GridLayout>
                </FormElement>
              )}
            />
          </ShadowCard>
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

export default Dashboard;
