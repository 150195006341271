import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearAirlineBlockFlightList,
  setAirlineBlockViewDetails,
} from "../reportSlice";
import {
  getAirlineBlockBookingExcelSheetData,
  getAirlineBlockFlights,
} from "../services/reports.services";
import { IFlightPlace } from "../../flightplace/flightplaceModel";
import { getAllActiveFlightPlaces } from "../../flightplace/services/flightplace.services";
import { Button } from "@progress/kendo-react-buttons";
import Airplane from "../../../assets/Images/airplane.jpg";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS } from "../../../_contstants/common";

const AirlineBlockView: React.FC = () => {
  const dispatch = useAppDispatch();
  const isExcelExportAccess = checkAcessRights(
    location.pathname,
    EXCEL_EXPORT_ACCESS
  );

  const loading = useAppSelector((state) => state.reports.loading);
  const listLoading = useAppSelector((state) => state.reports.listLoading);
  const AirlineBlockFlightList = useAppSelector(
    (state) => state.reports.AirlineBlockFlightList
  );
  const FlightPlaceList = useAppSelector(
    (state) => state.flightplace.FlightPlaceList
  );
  const AirlineBlockExcelData = useAppSelector(
    (state) => state.reports.AirlineBlockExcelData
  );

  useEffect(() => {
    dispatch(getAllActiveFlightPlaces());

    return () => {
      dispatch(clearAirlineBlockFlightList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      DepartureAirportID: values.DepartureAirportID
        ? values.DepartureAirportID
        : null,
      ArrivalAirportID: values.ArrivalAirportID
        ? values.ArrivalAirportID
        : null,
      DepartureDate: values.DepartureDate
        ? moment(values.DepartureDate).format("YYYY-MM-DD")
        : "",
    };
    dispatch(getAirlineBlockFlights(payload));
    dispatch(setAirlineBlockViewDetails(payload));
  };

  const handleExportExcelButtonClick = async (AirlineBlockDatesID: any) => {
    const response = await dispatch(
      getAirlineBlockBookingExcelSheetData(AirlineBlockDatesID)
    );
    if (response.meta.requestStatus === "fulfilled") {
      if (response.payload) {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.table_to_sheet(
          document.querySelector(".my-table")
        );
        XLSX.utils.book_append_sheet(wb, ws, "AirlinePassengers");

        const excelBuffer = XLSX.write(wb, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(data, "airlinepassengers.xlsx");
      }
    }
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 20, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Airline Excel
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DepartureAirportID"
                      label="Departure Airport"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={FlightPlaceList?.map(
                        (flightplace: IFlightPlace) => {
                          return {
                            value: flightplace?.ID,
                            label: flightplace?.FlightPlaceName,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="ArrivalAirportID"
                      label="Arrival Airport"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={FlightPlaceList?.map(
                        (flightplace: IFlightPlace) => {
                          return {
                            value: flightplace?.ID,
                            label: flightplace?.FlightPlaceName,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DepartureDate"
                      label="Departure Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <div>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
      <GridLayoutItem style={{ marginTop: 20 }}>
        {AirlineBlockFlightList &&
          AirlineBlockFlightList.length > 0 &&
          AirlineBlockFlightList.map((flightdetails: any) => (
            <ShadowCard style={{ marginBottom: 10 }}>
              <div className="row p-2">
                <div className="col-2 p-2 d-flex align-items-center">
                  <img
                    width={"100%"}
                    src={
                      flightdetails.AirlineLogo
                        ? flightdetails.AirlineLogo
                        : Airplane
                    }
                    alt="No Image"
                  />
                </div>
                <div
                  className="col-3 d-flex justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <div className="p-1">
                    <strong className="m-0">Flight No.</strong>
                    <span className="d-block">
                      {flightdetails?.FlightNo || "-"}
                    </span>
                  </div>
                  <div className="p-1">
                    <strong className="m-0">Departure Time</strong>
                    <span className="d-block">
                      {flightdetails?.DepartureTime || "-"}
                    </span>
                  </div>
                  {flightdetails?.StopageFlightNo && (
                    <div className="p-1">
                      <strong className="m-0">Stopage Flight No</strong>
                      <span className="d-block">
                        {flightdetails?.StopageFlightNo}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className="col-3 d-flex justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <div className="p-1">
                    <strong className="m-0">Arrival Date</strong>
                    <span className="d-block">
                      {flightdetails?.ArrivalDate || "-"}
                    </span>
                  </div>
                  <div className="p-1">
                    <strong className="m-0">Arrival Time</strong>
                    <span className="d-block">
                      {flightdetails?.ArrivalTime || "-"}
                    </span>
                  </div>
                  {flightdetails?.StopageDepartureTime && (
                    <div className="p-1">
                      <strong className="m-0">Stopage Departure Time</strong>
                      <span className="d-block">
                        {flightdetails?.StopageDepartureTime}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className="col-2 d-flex justify-content-center"
                  style={{ flexDirection: "column" }}
                >
                  <div className="p-1">
                    <strong className="m-0">PNR No.</strong>
                    <span className="d-block">
                      {flightdetails?.PNRNo || "-"}
                    </span>
                  </div>
                  <div className="p-1">
                    <strong className="m-0">Available Seats</strong>
                    <span className="d-block">
                      {flightdetails?.AvailableSeats || "-"}
                    </span>
                  </div>
                  {flightdetails?.StopageArrivalTime && (
                    <div className="p-1">
                      <strong className="m-0">Stopage Arrival Time</strong>
                      <span className="d-block">
                        {flightdetails?.StopageArrivalTime}
                      </span>
                    </div>
                  )}
                </div>
                <div
                  className="col-2 d-flex align-items-center"
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                  }}
                >
                  {isExcelExportAccess && (
                    <Button
                      themeColor="primary"
                      fillMode="solid"
                      disabled={listLoading}
                      onClick={() => {
                        handleExportExcelButtonClick(
                          flightdetails?.AirlineBlockDatesID
                        );
                      }}
                    >
                      Excel Export
                    </Button>
                  )}
                </div>
              </div>
            </ShadowCard>
          ))}
      </GridLayoutItem>

      <div className="row p-2 mb-3 d-none">
        {AirlineBlockExcelData && (
          <table className="mt-2 border-gray table-bordered w-100 table my-table">
            <tr>
              <td colSpan={5}>PNR NO : {AirlineBlockExcelData?.PNRNo}</td>
            </tr>
            <tr>
              <td colSpan={5}>DATE : {AirlineBlockExcelData?.DepartureDate}</td>
            </tr>
            <tr>
              <td colSpan={5}>
                SECTOR : {AirlineBlockExcelData?.FromAirportName} to{" "}
                {AirlineBlockExcelData?.ToAirportName}
              </td>
            </tr>
            <tr>
              <th scope="col">PAX TYPE</th>
              <th scope="col">TITLE</th>
              <th scope="col">GENDER</th>
              <th scope="col">FIRST NAME</th>
              <th scope="col">LAST NAME</th>
              <th scope="col">NATIONALITY</th>
              <th scope="col">PASSPORT NO</th>
              <th scope="col">ISSUE DATE</th>
              <th scope="col">EXPIRY DATE</th>
            </tr>
            {AirlineBlockExcelData &&
              AirlineBlockExcelData?.TravellerList &&
              AirlineBlockExcelData?.TravellerList.map((item: any) => (
                <tr>
                  <td>{item?.PaxType}</td>
                  <td>{item?.Title}</td>
                  <td>{item?.Gender}</td>
                  <td>{item?.FirstName}</td>
                  <td>{item?.LastName}</td>
                  <td>{item?.Nationality}</td>
                  <td>{item?.PassportNo}</td>
                  <td>{item?.IssueDate}</td>
                  <td>{item?.ExpiryDate}</td>
                </tr>
              ))}
          </table>
        )}
      </div>
    </GridLayout>
  );
};

export default AirlineBlockView;
