import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import { MdDelete } from "react-icons/md";
import IconButton from "../../components/common/IconButton";
import {
  clearHotelBlockDetails,
  setHotelRoomDataDeleteIndex,
} from "./hotelBlockSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveRoomTypes } from "../roomtype/services/roomType.services";
import { getAllActiveVendors } from "../vendor/services/vendor.services";
import { getAllActiveMealTypes } from "../mealtype/services/mealtype.services";
import { IVendor } from "../vendor/vendorModel";
import { IMealType } from "../mealtype/mealtypeModel";
import { ISectorCity } from "../city/cityModel";

const HotelRoomData = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllActiveVendors());
    dispatch(getAllActiveMealTypes());
    dispatch(getAllActiveRoomTypes());
    return () => {
      dispatch(closeDialog());
      dispatch(clearHotelBlockDetails());
    };
  }, []);

  const HotelDeleteIndex = useAppSelector(
    (state) => state.hotelBlock.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const MealTypeList = useAppSelector((state) => state.mealType.MealTypeList);
  const VendorList = useAppSelector((state) => state.vendor.VendorList);
  const RoomTypeList = useAppSelector((state) => state.roomType.RoomTypeList);
  const HotelBlockDetail = useAppSelector(
    (state) => state.hotelBlock.HotelBlockDetail
  );

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("hotelBlockDeleteDialog"));
    dispatch(setHotelRoomDataDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setHotelRoomDataDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const filteredRoom = HotelBlockDetail?.RoomData?.filter(
        (room: any) => +room?.CityID === +CityID
      );
      const filteredHotelList =
        (filteredRoom &&
          filteredRoom[0]?.HotelList?.map((hotel: any) => {
            return {
              value: hotel.ID,
              label: hotel.HotelName,
            };
          })) ||
        [];
      return filteredHotelList;
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {dialogName === "hotelBlockDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Hotel Block Array"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(HotelDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((RoomData: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "16.30%" },
                  { width: "16.30%" },
                  { width: "16.30%" },
                  { width: "16.30%" },
                  { width: "16.30%" },
                  { width: "16.30%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={6}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.CityID`}
                    label={"Place"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorCityList.map((city: ISectorCity) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.HotelID`}
                    label={"Hotel Name"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={filterHotels(RoomData?.CityID)}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.SupplierID`}
                    label={"Supplier"}
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={
                      VendorList.length > 1
                        ? VendorList?.map((vendor: IVendor) => {
                            return {
                              value: vendor.ID,
                              label: vendor.VendorName,
                            };
                          })
                        : []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.CheckInDate`}
                    label="CheckIn Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.NoOfNights`}
                    label="No Of Nights"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.CheckOutDate`}
                    label="CheckOut Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.MealPlanID`}
                    label={"Plan"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={MealTypeList.map((mealType: IMealType) => {
                      return {
                        value: mealType.ID,
                        label: mealType.MealType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.RoomTypeID`}
                    label={"Room Type"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={RoomTypeList.map((room: any) => {
                      return {
                        value: room.ID,
                        label: room.RoomType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`RoomData.${index}.NoOfRooms`}
                    label="No Of Rooms"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default HotelRoomData;
