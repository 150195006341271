import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFlightPlace, IFlightPlaceState } from "./flightplaceModel";
import {
  ActiveInactiveFlightPlace,
  createFlightPlace,
  deleteFlightPlace,
  getAllActiveFlightPlaces,
  getAllFlightPlaces,
  getFlightPlaceByID,
  updateFlightPlace,
} from "./services/flightplace.services";

const initialState: IFlightPlaceState = {
  loading: false,
  formLoading: false,
  error: "",
  FlightPlaceID: null,
  FlightPlaceList: [],
  FlightPlaceDetail: {
    FlightPlaceName: "",
    FlightPlaceCode: "",
    IsActive: true,
  },
};

const flightplaceSlice = createSlice({
  name: "flightplace",
  initialState,
  reducers: {
    clearFlightPlaceDetails: (state) => {
      state.FlightPlaceDetail = initialState.FlightPlaceDetail;
    },
    setFlightPlaceID: (state, action) => {
      state.FlightPlaceID = action.payload;
    },
    clearFlightPlaceID: (state) => {
      state.FlightPlaceID = initialState.FlightPlaceID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFlightPlaces.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllFlightPlaces.fulfilled,
      (state, action: PayloadAction<IFlightPlace[]>) => {
        state.loading = false;
        state.FlightPlaceList = action.payload || [];
      }
    );
    builder.addCase(getAllFlightPlaces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FlightPlaceList = [];
    });
    builder.addCase(createFlightPlace.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createFlightPlace.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createFlightPlace.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateFlightPlace.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateFlightPlace.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateFlightPlace.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteFlightPlace.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteFlightPlace.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteFlightPlace.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getFlightPlaceByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getFlightPlaceByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.FlightPlaceDetail = action.payload;
    });
    builder.addCase(getFlightPlaceByID.rejected, (state, action) => {
      state.formLoading = false;
      state.FlightPlaceDetail = initialState.FlightPlaceDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveFlightPlaces.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveFlightPlaces.fulfilled,
      (state, action: PayloadAction<IFlightPlace[]>) => {
        state.loading = false;
        state.FlightPlaceList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveFlightPlaces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FlightPlaceList = [];
    });
    builder.addCase(ActiveInactiveFlightPlace.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveFlightPlace.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveFlightPlace.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearFlightPlaceDetails, clearFlightPlaceID, setFlightPlaceID } =
  flightplaceSlice.actions;
export default flightplaceSlice.reducer;
