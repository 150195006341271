import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import stateSlice from "../features/state/stateSlice";
import citySlice from "../features/city/citySlice";
import regionSlice from "../features/region/regionSlice";
import specialityTypeSlice from "../features/specialitytype/specialityTypeSlice";
import sectorSlice from "../features/sector/sectorSlice";
import hotelBlockSlice from "../features/hotelblock/hotelBlockSlice";
import roomTypeSlice from "../features/roomtype/roomTypeSlice";
import vendorTypeSlice from "../features/vendortype/vendorTypeSlice";
import vendorSlice from "../features/vendor/vendorSlice";
import mealtypeSlice from "../features/mealtype/mealtypeSlice";
import nationalitySlice from "../features/nationality/nationalitySlice";
import sectorCitySlice from "../features/sectorcity/sectorCitySlice";
import termsAndConditionSlice from "../features/termsandcondition/termsAndConditionSlice";
import tourSlice from "../features/tour/tourSlice";
import companySlice from "../features/company/companySlice";
import gstSlice from "../features/gst/gstSlice";
import hotelSlice from "../features/hotel/hotelSlice";
import currencySlice from "../features/currency/currencySlice";
import vehicleSharingPaxSlice from "../features/vehiclesharingpax/vehicleSharingPaxSlice";
import airlineblockSlice from "../features/airlineblock/airlineblockSlice";
import airlineSlice from "../features/airline/airlineSlice";
import airlineclassSlice from "../features/airlineclass/airlineclassSlice";
import flightplaceSlice from "../features/flightplace/flightplaceSlice";
import airportSlice from "../features/airport/airportSlice";
import paxTypeSlice from "../features/paxtype/paxTypeSlice";
import routeSlice from "../features/route/routeSlice";
import branchSlice from "../features/branch/branchSlice";
import foodOptionSlice from "../features/foodoption/foodOptionSlice";
import groupRouteSlice from "../features/grouproute/groupRouteSlice";
import tourBookingSlice from "../features/tourbooking/tourBookingSlice";
import hotelBookingSlice from "../features/hotelvoucher/hotelVoucherSlice";
import paymentReceiptSlice from "../features/paymentreceipt/paymentReceiptSlice";
import routeVoucherSlice from "../features/routevoucher/routeVoucherSlice";
import roomCategorySlice from "../features/roomcategory/roomCategorySlice";
import hotelMappingSlice from "../features/hotelmapping/hotelMappingSlice";
import drawerSlice from "../components/drawer/drawerSlice";
import airlineVoucherSlice from "../features/airlinevoucher/airlineVoucherSlice";
import reportSlice from "../features/reports/reportSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import DashboardSlice from "../features/dashboard/dashboardSlice";
// import airlineBlockSlice from "../features/aitlineblock/airlineBlockSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dialog: dialogSlice,
    dashboard: DashboardSlice,
    drawer: drawerSlice,
    user: userSlice,
    country: countrySlice,
    state: stateSlice,
    city: citySlice,
    region: regionSlice,
    specialityType: specialityTypeSlice,
    sector: sectorSlice,
    hotelBlock: hotelBlockSlice,
    mealType: mealtypeSlice,
    roomType: roomTypeSlice,
    vendor: vendorSlice,
    vendorType: vendorTypeSlice,
    nationality: nationalitySlice,
    sectorCity: sectorCitySlice,
    termsandcondition: termsAndConditionSlice,
    tour: tourSlice,
    company: companySlice,
    hotel: hotelSlice,
    gst: gstSlice,
    currency: currencySlice,
    vehicleSharingPax: vehicleSharingPaxSlice,
    airline: airlineSlice,
    airlineblock: airlineblockSlice,
    airlineclass: airlineclassSlice,
    flightplace: flightplaceSlice,
    airport: airportSlice,
    paxType: paxTypeSlice,
    route: routeSlice,
    branch: branchSlice,
    foodOption: foodOptionSlice,
    groupRoute: groupRouteSlice,
    tourBooking: tourBookingSlice,
    hotelVoucher: hotelBookingSlice,
    paymentReceipt: paymentReceiptSlice,
    routeVoucher: routeVoucherSlice,
    roomCategory: roomCategorySlice,
    hotelMapping: hotelMappingSlice,
    airlineVoucher: airlineVoucherSlice,
    reports: reportSlice,
    menu: menuSlice,
    rights: rightsSlice,
    userType: userTypeSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
