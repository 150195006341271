import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast } from "../../../components/toast/Toasts";
// import { IDashboardDetails } from "../dashboardModel";

export const getDashboard = createAsyncThunk(
  "DashBoard/DashBoardCount",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/DashBoardCount`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching InquirySubtype:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllTotalAirlineVouchers = createAsyncThunk(
  "DashBoard/AirlineVoucherDashBoard",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/AirlineVoucherDashBoard`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Airline Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getAllTotalPendingPayments = createAsyncThunk(
  "DashBoard/PendingPaymenteList",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/PendingPaymenteList`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Pending Payments:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const getAllTotalDeparture = createAsyncThunk(
  "DashBoard/DashBoardNextDeparture",
  async (filter: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/DashBoard/DashBoardNextDeparture`,
        filter
      );
      if (response.data.Status === 200) {
        return response.data?.Data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      console.error("Error Fetching Departure:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
