import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import ShadowCard from "../../../components/common/ShadowCard";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormTextField from "../../../components/formFields/FormTextField";
import { BOOLEAN } from "../../../_contstants/common";
import { findIndex } from "lodash";
import { getAllActiveCities } from "../../city/services/city.services";
import { getAllActiveStates } from "../../state/services/state.services";
import { getAllActiveCountries } from "../../country/services/country.services";
import { ICity } from "../../city/cityModel";
import { IState } from "../../state/stateModel";
import { ICountry } from "../../country/countryModel";
import TouristPaxDetailsArray from "./TouristPaxDetailsArray";
import {
  clearTourBookingActiveStep,
  // clearTourBookingCostDetails,
  // clearTourBookingDetails,
  decreaseTourBookingActiveStep,
} from "../tourBookingSlice";
import { useLocation } from "react-router-dom";
import moment from "moment";
import {
  createTouristInfo,
  // getTourBookingByID,
} from "../services/tourBooking.services";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CompanyCityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("CompanyStateID", { value: city.StateID });
      formRenderProps.onChange("CompanyCountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CompanyCityID", { value: "" });
      formRenderProps.onChange("CompanyStateID", { value: "" });
      formRenderProps.onChange("CompanyCountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};

const TouristInfo: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const TourBookingID = location.state?.TourBookingID;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const TourBookingDetail = useAppSelector(
    (state) => state.tourBooking.TourBookingDetail
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());

    // return () => {
    //   console.log("cleared from touristinfo");
    //   dispatch(clearTourBookingDetails());
    //   dispatch(clearTourBookingCostDetails());
    // };
  }, []);

  // useEffect(() => {
  //   if (TourBookingID) {
  //     console.log("touristinfo details called getTourBookingByID");
  //     dispatch(getTourBookingByID(TourBookingID));
  //   }
  // }, [TourBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingDetail]);

  const handleSubmit = async (values: any) => {
    if (TourBookingID) {
      const insertPayload = {
        ID: TourBookingID,
        IsCompanyInvoice: values.IsCompanyInvoice === 1,
        CompanyName: values.CompanyName ? values?.CompanyName : "",
        CompanyAddress: values.CompanyAddress ? values?.CompanyAddress : "",
        CompanyCityID: values.CompanyCityID ? values?.CompanyCityID : null,
        CompanyStateID: values.CompanyStateID ? values?.CompanyStateID : null,
        CompanyCountryID: values.CompanyCountryID
          ? values?.CompanyCountryID
          : null,
        CompanyGSTNo: values.CompanyGSTNo ? values?.CompanyGSTNo : "",
        CompanyPANNo: values.CompanyPANNo ? values?.CompanyPANNo : "",
        RoomPaxes: values.RoomPaxes
          ? values.RoomPaxes.map((roompax: any, index: any) => {
              return {
                RoomNo: (index + 1).toString(),
                PaxTypeID: roompax.PaxTypeID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID ? +touristpax.ID : null,
                        DOB: touristpax.DOB
                          ? moment(touristpax.DOB).format("YYYY-MM-DD")
                          : "",
                        TourBookingID: TourBookingID,
                        FirstName: touristpax.FirstName
                          ? touristpax.FirstName
                          : "",
                        LastName: touristpax.LastName
                          ? touristpax.LastName
                          : "",
                        MobileNo: touristpax.MobileNo
                          ? touristpax.MobileNo
                          : "",
                        RoomNo: (index + 1).toString(),
                        DiscountAmount: touristpax.DiscountAmount
                          ? +touristpax.DiscountAmount
                          : null,
                        Amount: touristpax.Amount ? +touristpax.Amount : null,
                        Initial: touristpax.Initial ? touristpax.Initial : "",
                        Gender: touristpax.Gender ? touristpax.Gender : "",
                        Age: touristpax.Age ? +touristpax.Age : null,
                        ExtraCost: touristpax.ExtraCost
                          ? +touristpax.ExtraCost
                          : null,
                        IsVisa: touristpax.IsVisa ? touristpax.IsVisa : true,
                        IsInsurance: touristpax.IsInsurance
                          ? touristpax.IsInsurance
                          : true,
                        FoodOptionID: touristpax.FoodOptionID
                          ? +touristpax.FoodOptionID
                          : null,
                        PassportNo: touristpax.PassportNo
                          ? touristpax.PassportNo
                          : "",
                        IssueDate: touristpax.IssueDate
                          ? moment(touristpax.IssueDate).format("YYYY-MM-DD")
                          : null,
                        ExpiryDate: touristpax.ExpiryDate
                          ? moment(touristpax.ExpiryDate).format("YYYY-MM-DD")
                          : null,
                        NationalityID: touristpax.NationalityID
                          ? +touristpax.NationalityID
                          : null,
                      };
                    })
                  : [],
              };
            })
          : [],
      };
      const response = await dispatch(createTouristInfo(insertPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(clearTourBookingActiveStep());
        navigate("/tourbooking");
      }
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Company Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="IsCompanyInvoice"
                  label="Company Invoice"
                  component={FormSelectionField}
                  options={BOOLEAN?.map((invoice: any) => {
                    return {
                      value: invoice?.value,
                      label: invoice?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={2}></GridLayoutItem>
              {formRenderProps.valueGetter("IsCompanyInvoice") === 1 && (
                <>
                  <GridLayoutItem>
                    <Field
                      name="CompanyName"
                      label="Company Name"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyAddress"
                      label="Company Address"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyGSTNo"
                      label="GST No"
                      component={FormTextField}
                      // max={15}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyPANNo"
                      label="PAN No"
                      component={FormTextField}
                      // max={10}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CompanyCityID"}
                      name={"CompanyCityID"}
                      label={"Company City"}
                      component={FormSelectionField}
                      options={CityList?.map((city: ICity) => {
                        return {
                          value: city?.ID,
                          label: city?.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      id={"CompanyStateID"}
                      name={"CompanyStateID"}
                      label={"Company State"}
                      component={FormSelectionField}
                      options={StateList?.map((state: IState) => {
                        return {
                          value: state?.ID,
                          label: state?.StateName,
                        };
                      })}
                    />
                    <Field
                      id={"CompanyCountryID"}
                      name={"CompanyCountryID"}
                      label={"Company Country"}
                      component={FormSelectionField}
                      options={CountryList?.map((country: ICountry) => {
                        return {
                          value: country?.ID,
                          label: country?.CountryName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                </>
              )}
            </GridLayout>
            <CityChangeWatcher
              CityList={CityList}
              formRenderProps={formRenderProps}
            />
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4>{"Pax Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={TouristPaxDetailsArray}
                  name="RoomPaxes"
                />
              </GridLayoutItem>
              {/* <PaxDetailsChangeWatcher formRenderProps={formRenderProps} /> */}
              <GridLayoutItem
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => dispatch(decreaseTourBookingActiveStep())}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default TouristInfo;
