import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createRoute,
  getAllRoutes,
  getRouteByID,
  updateRoute,
} from "./services/route.services";
import { clearRouteDetails } from "./routeSlice";
import FormTextArea from "../../components/formFields/FormTextArea";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { ISector } from "../sector/sectorModel";
import { closed } from "../../components/drawer/drawerSlice";

const CreateRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const RouteDetail = useAppSelector((state) => state.route.RouteDetail);
  const RouteID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.route.formLoading);

  useEffect(() => {
    if (RouteID) {
      dispatch(getRouteByID(RouteID));
    }
  }, [RouteID]);

  useEffect(() => {
    dispatch(getAllActiveSectors());

    return () => {
      dispatch(clearRouteDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!RouteID) {
        const insertPayload = {
          SectorID: values.SectorID,
          RouteName: values.RouteName,
          Description: values.Description,
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(createRoute(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearRouteDetails());
          dispatch(getAllRoutes());
        }
      } else {
        const editPayload = {
          ID: RouteID,
          SectorID: values.SectorID,
          RouteName: values.RouteName,
          Description: values.Description,
          IsActive: values.IsActive ? values.IsActive : false,
        };
        const response = await dispatch(updateRoute(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearRouteDetails());
          dispatch(getAllRoutes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={RouteDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="RouteName"
                label="Route"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                id="SectorID"
                name="SectorID"
                label="Sector"
                component={FormSelectionField}
                validator={requiredValidator}
                options={SectorList?.map((sector: ISector) => {
                  return {
                    value: sector?.ID,
                    label: sector?.SectorName,
                  };
                })}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Description"
                label="Description"
                component={FormTextArea}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={!RouteID ? "Create" : "Update"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateRoute;
