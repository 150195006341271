import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import FormTextField from "../../components/formFields/FormTextField";
import { Button } from "@progress/kendo-react-buttons";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ONWARDJOURNEYTYPE } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";
import { getAllActiveUsers } from "../user/services/user.services";
import { IUser } from "../user/userModel";
import AirlineVoucherSegmentArray from "./AirlineVoucherSegmentArray";
import AirlineVoucherTravellersArray from "./AirlineVoucherTravellersArray";
import {
  createAirlineVoucher,
  getAirlineSegmentView,
  getAirlineVoucherByID,
  getTourBookingPaxList,
  updateAirlineVoucher,
} from "./services/airlineVoucher.services";
import moment from "moment";
import { clearAirlineVoucherDetails } from "./airlineVoucherSlice";
import { IAirlineBlock } from "../airlineblock/airlineblockModel";
import {
  getAllAirlineBlocks,
  getBlockDatesByAirlineBlockID,
} from "../airlineblock/services/airlineblock.services";
import DepartureSegmentArray from "./DepartureSegmentArray";
import ReturnSegmentArray from "./ReturnSegmentArray";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import { clearAirlineBlockDates } from "../airlineblock/airlineblockSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const AirlineBlockChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isdBlockDateRef = React.useRef(true);
  const AirlineBlockID = formRenderProps.valueGetter("AirlineBlockID");
  useEffect(() => {
    if (AirlineBlockID) {
      const payload = {
        AirlineBlockID: AirlineBlockID ? AirlineBlockID : null,
      };
      dispatch(getBlockDatesByAirlineBlockID(payload));
    } else {
      dispatch(clearAirlineBlockDates());
    }
    if (!isdBlockDateRef.current) {
      formRenderProps.onChange("AirlineBlockDateID", {
        value: null,
      });
    } else {
      isdBlockDateRef.current = false;
    }
  }, [AirlineBlockID]);
  return null;
};

const CreateAirlineVoucher: React.FC = () => {
  // const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const AirlineVoucherID = location.state?.AirlineVoucherID;
  const loading = useAppSelector((state) => state.airlineVoucher.loading);
  const AirlineBlockList = useAppSelector(
    (state) => state.airlineblock.AirlineBlockList
  );
  const AirlineVoucherDetails = useAppSelector(
    (state) => state.airlineVoucher.AirlineVoucherDetails
  );
  const UserList = useAppSelector((state) => state.user.UserList);
  const AirlineBlockDates = useAppSelector(
    (state) => state.airlineblock.AirlineBlockDates
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(getAllAirlineBlocks());

    return () => {
      dispatch(clearAirlineVoucherDetails());
    };
  }, []);

  useEffect(() => {
    if (AirlineVoucherID) {
      dispatch(getAirlineVoucherByID(AirlineVoucherID));
    }
  }, [AirlineVoucherID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [AirlineVoucherDetails]);

  const getAvailableSeats = (AirlineBlockDateID: number): number | null => {
    const tourdate = AirlineBlockDates.tourdates.find(
      (date) => date.AirlineBlockDateID === AirlineBlockDateID
    );
    return tourdate ? tourdate.AvailableSeats : null;
  };

  const handleChangeAirlineBlockDate = async (
    formRenderProps: FormRenderProps
  ) => {
    const AirlineBlockID = formRenderProps.valueGetter("AirlineBlockID");
    const AirlineBlockDateID =
      formRenderProps.valueGetter("AirlineBlockDateID");
    const NoOfPax = formRenderProps.valueGetter("NoOfPax");
    // const TotalNoOfSeats = getAvailableSeats(e?.value);
    if (AirlineBlockID && AirlineBlockDateID && NoOfPax) {
      const payload = {
        AirlineBlockID: AirlineBlockID ? +AirlineBlockID : null,
        AirlineBlockDateID: AirlineBlockDateID ? +AirlineBlockDateID : null,
        TotalNoOfSeats: NoOfPax ? +NoOfPax : null,
      };

      const response = await dispatch(getAirlineSegmentView(payload));
      if (response.meta.requestStatus === "fulfilled") {
        const SegmentArray = JSON.parse(JSON.stringify(response.payload))?.map(
          (segment: any) => {
            return {
              ID: segment.ID ? +segment.ID : null,
              VendorID: segment.VendorID ? +segment.VendorID : null,
              AirlineID: segment.AirlineID ? +segment.AirlineID : null,
              FlightNo: segment.FlightNo ? segment.FlightNo : "",
              VendorName: segment.VendorName ? segment.VendorName : "",
              JourneyType: segment.JourneyType ? segment.JourneyType : "",
              FromAirportID: segment.FromAirportID
                ? +segment.FromAirportID
                : null,
              FromAirportName: segment.FromAirportName
                ? segment.FromAirportName
                : "",
              ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
              ToAirportName: segment.ToAirportName ? segment.ToAirportName : "",
              PNRNo: segment.PNRNo ? segment.PNRNo : "",
              DepartureDate: segment.DepartureDate
                ? moment(segment.DepartureDate).toDate()
                : "",
              DepartureTime: segment.DepartureTime
                ? moment(segment.DepartureTime).toDate()
                : "",
              ArrivalDate: segment.ArrivalDate
                ? moment(segment.ArrivalDate).toDate()
                : "",
              ArrivalTime: segment.ArrivalTime
                ? moment(segment.ArrivalTime).toDate()
                : "",
              Boarding: segment.Boarding ? segment.Boarding : "",
              ClassID: segment.ClassID ? +segment.ClassID : null,
              ClassName: segment.ClassName ? +segment.ClassName : null,
              TotalPrice: segment.TotalPrice ? +segment.TotalPrice : 0,
            };
          }
        );
        formRenderProps.onChange(`airlineVoucherSegments`, {
          value: SegmentArray,
        });
        // formRenderProps.onChange(`airlineVoucherSegments`, {
        //   value: [],
        // });
      } else {
        formRenderProps.onChange(`airlineVoucherSegments`, {
          value: [],
        });
      }
    }
  };

  const handleSubmit = async (values: any) => {
    const departureSegments = values?.DEPARTURE || [];
    const returnSegments = values?.RETURN || [];

    values?.Journey === "TWO WAY"
      ? (values.airlineVoucherSegments = [])
      : values.airlineVoucherSegments;

    const mergedSegments = [
      ...departureSegments,
      ...returnSegments,
      ...values.airlineVoucherSegments,
    ].map((segment: any) => {
      return {
        VendorID: segment.VendorID ? +segment.VendorID : null,
        AirlineID: segment.AirlineID ? +segment.AirlineID : null,
        FlightNo: segment.FlightNo ? segment.FlightNo : "",
        JourneyType: segment.JourneyType ? segment.JourneyType : "",
        FromAirportID: segment.FromAirportID ? +segment.FromAirportID : null,
        ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
        PNRNo: segment.PNRNo ? segment.PNRNo : "",
        TotalPrice: segment.TotalPrice ? +segment.TotalPrice : null,
        DepartureDate: segment.DepartureDate
          ? moment(segment.DepartureDate).format("YYYY-MM-DD")
          : "",
        DepartureTime: segment.DepartureTime
          ? moment(segment.DepartureTime).format("HH:mm:ss")
          : "",
        ArrivalDate: segment.ArrivalDate
          ? moment(segment.ArrivalDate).format("YYYY-MM-DD")
          : "",
        ArrivalTime: segment.ArrivalTime
          ? moment(segment.ArrivalTime).format("HH:mm:ss")
          : "",
        Boarding: segment.Boarding ? segment.Boarding : "",
        ClassID: segment.ClassID ? +segment.ClassID : null,
      };
    });

    if (AirlineVoucherID) {
      try {
        const editPayload = {
          ID: AirlineVoucherID,
          TourBookingNo: values.TourBookingNo ? values.TourBookingNo : "",
          AirlineBlockID: values.AirlineBlockID ? values.AirlineBlockID : null,
          CompanyID: values.CompanyID ? values.CompanyID : null,
          TicketPurchasedDate: values.TicketPurchasedDate
            ? moment(values.TicketPurchasedDate).format("YYYY-MM-DD")
            : "",
          Journey: values.Journey ? values.Journey : "",
          NoOfPax: values.NoOfPax ? values.NoOfPax : null,
          TotalPrice: values.TotalPrice ? values.TotalPrice : null,
          OTP: values.OTP ? values.OTP : "",
          BookBy: values.BookBy ? values.BookBy : null,
          Remarks: values.Remarks ? values.Remarks : "",
          OldAirlineVoucherNo: values.OldAirlineVoucherNo
            ? values.OldAirlineVoucherNo
            : "",
          AirlineBlockDateID: values.AirlineBlockDateID
            ? values.AirlineBlockDateID
            : null,
          airlineVoucherSegments:
            values?.Journey === "TWO WAY"
              ? mergedSegments
              : values?.airlineVoucherSegments.map((segment: any) => {
                  return {
                    VendorID: segment.VendorID ? +segment.VendorID : null,
                    AirlineID: segment.AirlineID ? +segment.AirlineID : null,
                    FlightNo: segment.FlightNo ? segment.FlightNo : "",
                    JourneyType: segment.JourneyType ? segment.JourneyType : "",
                    FromAirportID: segment.FromAirportID
                      ? +segment.FromAirportID
                      : null,
                    ToAirportID: segment.ToAirportID
                      ? +segment.ToAirportID
                      : null,
                    PNRNo: segment.PNRNo ? segment.PNRNo : "",
                    TotalPrice: segment.TotalPrice ? +segment.TotalPrice : null,
                    DepartureDate: segment.DepartureDate
                      ? moment(segment.DepartureDate).format("YYYY-MM-DD")
                      : "",
                    DepartureTime: segment.DepartureTime
                      ? moment(segment.DepartureTime).format("HH:mm:ss")
                      : "",
                    ArrivalDate: segment.ArrivalDate
                      ? moment(segment.ArrivalDate).format("YYYY-MM-DD")
                      : "",
                    ArrivalTime: segment.ArrivalTime
                      ? moment(segment.ArrivalTime).format("HH:mm:ss")
                      : "",
                    Boarding: segment.Boarding ? segment.Boarding : "",
                    ClassID: segment.ClassID ? +segment.ClassID : null,
                  };
                }),
          airlineVoucherTravellers: values.airlineVoucherTravellers
            ? values.airlineVoucherTravellers.map((traveller: any) => {
                return {
                  FirstName: traveller.FirstName ? traveller.FirstName : "",
                  LastName: traveller.LastName ? traveller.LastName : "",
                  //   TicketFrom: traveller.TicketFrom ? traveller.TicketFrom : "",
                  //   AirlineBlockID: traveller.AirlineBlockID ? +traveller.AirlineBlockID : null,
                  //   Price: traveller.Price ? +traveller.Price : null,
                  //   FileName: traveller.FileName ? traveller.FileName : "",
                  Initial: traveller.Initial ? traveller.Initial : "",
                  Gender: traveller.Gender ? traveller.Gender : "",
                  PaxType: traveller.PaxType ? traveller.PaxType : "",
                  IsSelected: traveller.IsSelected
                    ? traveller.IsSelected
                    : false,
                  PassportNo: traveller.PassportNo ? traveller.PassportNo : "",
                  IssueDate: traveller.IssueDate
                    ? moment(traveller.IssueDate).format("YYYY-MM-DD")
                    : null,
                  ExpiryDate: traveller.ExpiryDate
                    ? moment(traveller.ExpiryDate).format("YYYY-MM-DD")
                    : null,
                  NationalityID: traveller.NationalityID
                    ? traveller.NationalityID
                    : null,
                };
              })
            : [],
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(updateAirlineVoucher(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/airlinevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          TourBookingNo: values.TourBookingNo ? values.TourBookingNo : "",
          AirlineBlockID: values.AirlineBlockID ? values.AirlineBlockID : null,
          CompanyID: values.CompanyID ? values.CompanyID : null,
          TicketPurchasedDate: values.TicketPurchasedDate
            ? moment(values.TicketPurchasedDate).format("YYYY-MM-DD")
            : "",
          Journey: values.Journey ? values.Journey : "",
          NoOfPax: values.NoOfPax ? values.NoOfPax : null,
          TotalPrice: values.TotalPrice ? values.TotalPrice : null,
          OTP: values.OTP ? values.OTP : "",
          BookBy: values.BookBy ? values.BookBy : null,
          Remarks: values.Remarks ? values.Remarks : "",
          OldAirlineVoucherNo: values.OldAirlineVoucherNo
            ? values.OldAirlineVoucherNo
            : "",
          AirlineBlockDateID: values.AirlineBlockDateID
            ? values.AirlineBlockDateID
            : null,
          airlineVoucherSegments:
            values?.Journey === "TWO WAY"
              ? mergedSegments
              : values?.airlineVoucherSegments.map((segment: any) => {
                  return {
                    VendorID: segment.VendorID ? +segment.VendorID : null,
                    AirlineID: segment.AirlineID ? +segment.AirlineID : null,
                    FlightNo: segment.FlightNo ? segment.FlightNo : "",
                    JourneyType: segment.JourneyType ? segment.JourneyType : "",
                    FromAirportID: segment.FromAirportID
                      ? +segment.FromAirportID
                      : null,
                    ToAirportID: segment.ToAirportID
                      ? +segment.ToAirportID
                      : null,
                    PNRNo: segment.PNRNo ? segment.PNRNo : "",
                    TotalPrice: segment.TotalPrice ? +segment.TotalPrice : null,
                    DepartureDate: segment.DepartureDate
                      ? moment(segment.DepartureDate).format("YYYY-MM-DD")
                      : "",
                    DepartureTime: segment.DepartureTime
                      ? moment(segment.DepartureTime).format("HH:mm:ss")
                      : "",
                    ArrivalDate: segment.ArrivalDate
                      ? moment(segment.ArrivalDate).format("YYYY-MM-DD")
                      : "",
                    ArrivalTime: segment.ArrivalTime
                      ? moment(segment.ArrivalTime).format("HH:mm:ss")
                      : "",
                    Boarding: segment.Boarding ? segment.Boarding : "",
                    ClassID: segment.ClassID ? +segment.ClassID : null,
                  };
                }),
          airlineVoucherTravellers: values.airlineVoucherTravellers
            ? values.airlineVoucherTravellers.map((traveller: any) => {
                return {
                  FirstName: traveller.FirstName ? traveller.FirstName : "",
                  LastName: traveller.LastName ? traveller.LastName : "",
                  //   TicketFrom: traveller.TicketFrom ? traveller.TicketFrom : "",
                  //   AirlineBlockID: traveller.AirlineBlockID ? +traveller.AirlineBlockID : null,
                  //   Price: traveller.Price ? +traveller.Price : null,
                  //   FileName: traveller.FileName ? traveller.FileName : "",
                  Initial: traveller.Initial ? traveller.Initial : "",
                  Gender: traveller.Gender ? traveller.Gender : "",
                  PaxType: traveller.PaxType ? traveller.PaxType : "",
                  IsSelected: traveller.IsSelected
                    ? traveller.IsSelected
                    : false,
                  PassportNo: traveller.PassportNo ? traveller.PassportNo : "",
                  IssueDate: traveller.IssueDate
                    ? moment(traveller.IssueDate).format("YYYY-MM-DD")
                    : null,
                  ExpiryDate: traveller.ExpiryDate
                    ? moment(traveller.ExpiryDate).format("YYYY-MM-DD")
                    : null,
                  NationalityID: traveller.NationalityID
                    ? traveller.NationalityID
                    : null,
                };
              })
            : [],
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(createAirlineVoucher(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/airlinevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleJourneySelect = async (
    e: any,
    formRenderProps: FormRenderProps
  ) => {
    if (e?.value) {
      formRenderProps.onChange(`Journey`, {
        value: "ONE WAY",
      });
    }
  };

  const handleTourBookingAction = async (
    TourBookingNo: number,
    formRenderProps: FormRenderProps
  ) => {
    const response = await dispatch(getTourBookingPaxList(+TourBookingNo));
    if (response.meta.requestStatus === "fulfilled") {
      const TravellersArray = JSON.parse(JSON.stringify(response.payload)).map(
        (pax: any) => {
          return {
            ...pax,
            IssueDate: pax.IssueDate ? moment(pax.IssueDate).toDate() : "",
            ExpiryDate: pax.ExpiryDate ? moment(pax.ExpiryDate).toDate() : "",
          };
        }
      );
      formRenderProps.onChange(`airlineVoucherTravellers`, {
        value: TravellersArray,
      });
    } else {
      formRenderProps.onChange(`airlineVoucherTravellers`, {
        value: [],
      });
    }
  };

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={AirlineVoucherDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 70 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "30%" },
                  { width: "10%" },
                  { width: "30%" },
                  { width: "30%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Airline Ticket</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TourBookingNo"
                    label="Tour Booking No"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 42,
                      left: 28,
                    }}
                  >
                    <Button
                      fillMode={"solid"}
                      themeColor={"primary"}
                      size={"large"}
                      onClick={() =>
                        handleTourBookingAction(
                          formRenderProps.valueGetter("TourBookingNo"),
                          formRenderProps
                        )
                      }
                      type="button"
                    >
                      View
                    </Button>
                  </div>
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="OldAirlineVoucherNo"
                    label="Old Voucher No"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 10, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name="TicketPurchasedDate"
                    label="Ticket Purchased Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="NoOfPax"
                    label="No Of Pax"
                    type="number"
                    onChange={() =>
                      handleChangeAirlineBlockDate(formRenderProps)
                    }
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"AirlineBlockID"}
                    name={"AirlineBlockID"}
                    label={"Airline Block"}
                    component={FormSelectionField}
                    onChange={(e) => handleJourneySelect(e, formRenderProps)}
                    options={AirlineBlockList?.map(
                      (airlineblock: IAirlineBlock) => {
                        return {
                          value: airlineblock?.ID,
                          label: airlineblock?.Title,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem></GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"AirlineBlockDateID"}
                    name={"AirlineBlockDateID"}
                    label={"Block Dates"}
                    validator={
                      formRenderProps.valueGetter("AirlineBlockID") &&
                      requiredValidator
                    }
                    component={FormSelectionField}
                    onChange={() =>
                      handleChangeAirlineBlockDate(formRenderProps)
                    }
                    options={AirlineBlockDates?.tourdates?.map(
                      (airlineblock: any) => {
                        return {
                          value: airlineblock?.AirlineBlockDateID,
                          label: airlineblock?.TourDate,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"Journey"}
                    name={"Journey"}
                    label={"Onward Journey"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={ONWARDJOURNEYTYPE?.map((journey: any) => {
                      return {
                        value: journey?.value,
                        label: journey?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                {formRenderProps &&
                  formRenderProps.valueGetter("AirlineBlockDateID") && (
                    <GridLayoutItem
                      colSpan={2}
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <div>
                        <div
                          style={{
                            display: "inline-block",
                            background: "limegreen",
                            padding: "7px 14px",
                            fontSize: 18,
                            borderRadius: 5,
                            color: "white",
                            fontWeight: 500,
                          }}
                        >
                          Only{" "}
                          {getAvailableSeats(
                            formRenderProps.valueGetter("AirlineBlockDateID")
                          )}{" "}
                          seats available
                        </div>
                      </div>
                    </GridLayoutItem>
                  )}
                <AirlineBlockChangeWatcher formRenderProps={formRenderProps} />
                {/* <SegmentViewChangeWatcher formRenderProps={formRenderProps} /> */}
                <GridLayoutItem colSpan={4}>
                  {formRenderProps?.valueGetter("Journey") === "TWO WAY" ? (
                    <>
                      <div>
                        <span
                          style={{
                            background: "yellow",
                            padding: "5px 10px",
                            marginBottom: 5,
                            fontWeight: 600,
                            borderRadius: 26,
                            display: "inline-block",
                          }}
                        >
                          Departure
                        </span>
                        <FieldArray
                          AirlineVoucherID={AirlineVoucherID}
                          formRenderProps={formRenderProps}
                          component={DepartureSegmentArray}
                          name="DEPARTURE"
                        />
                      </div>
                      <div>
                        <span
                          style={{
                            background: "yellow",
                            padding: "5px 10px",
                            marginBottom: 5,
                            fontWeight: 600,
                            borderRadius: 26,
                            display: "inline-block",
                          }}
                        >
                          Return
                        </span>
                        <FieldArray
                          AirlineVoucherID={AirlineVoucherID}
                          formRenderProps={formRenderProps}
                          component={ReturnSegmentArray}
                          name="RETURN"
                        />
                      </div>
                    </>
                  ) : (
                    <FieldArray
                      AirlineVoucherID={AirlineVoucherID}
                      formRenderProps={formRenderProps}
                      component={AirlineVoucherSegmentArray}
                      name="airlineVoucherSegments"
                    />
                  )}
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {formRenderProps.valueGetter("airlineVoucherTravellers") &&
              formRenderProps.valueGetter("airlineVoucherTravellers").length >
                0 && (
                <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                  <GridLayout
                    gap={{ rows: 10, cols: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem>
                      <FieldArray
                        formRenderProps={formRenderProps}
                        component={AirlineVoucherTravellersArray}
                        name="airlineVoucherTravellers"
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
              )}

            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem rowSpan={2}>
                  <Field
                    name={"Remarks"}
                    label={"Remarks"}
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"BookBy"}
                    label={"BookBy"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <RippleButton
                      fillMode="outline"
                      themeColor="primary"
                      style={{ marginRight: 4 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </RippleButton>
                    <ButtonWithLoading
                      label={AirlineVoucherID ? "Update" : "Add"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateAirlineVoucher;
