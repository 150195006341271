import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearUserWiseTourBookingReportList,
  setUserWiseTourBookingReport,
} from "../reportSlice";
import { getAllActiveUsers } from "../../user/services/user.services";
import { IUser } from "../../user/userModel";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { ITour } from "../../tour/tourModel";

const UserWiseBookingReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const UserList = useAppSelector((state) => state.user.UserList);
  const TourList = useAppSelector((state) => state.tour.TourList);

  useEffect(() => {
    dispatch(getAllActiveUsers());
    dispatch(getAllActiveTours());

    return () => {
      dispatch(clearUserWiseTourBookingReportList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      FromDate: values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : null,
      ToDate: values?.ToDate
        ? moment(values?.ToDate).format("YYYY-MM-DD")
        : null,
      UserID: values?.UserID ? values?.UserID : null,
      TourID: values?.TourID ? values?.TourID : "0",
    };
    dispatch(setUserWiseTourBookingReport(payload));
    window.open(
      `userwisebookingreportview/${payload?.UserID}/${payload?.TourID}/${payload?.FromDate}/${payload?.ToDate}`
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      User Wise Tour Booking Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="UserID"
                      label="User"
                      component={FormSelectionField}
                      options={UserList?.map((user: IUser) => {
                        return {
                          value: user?.ID,
                          label: `${user?.FirstName} ${user?.LastName}`,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourID"
                      label="Tour"
                      component={FormSelectionField}
                      options={TourList?.map((tour: ITour) => {
                        return {
                          value: tour?.ID,
                          label: tour?.TourName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                    <Field
                      name="FromDate"
                      wrapperStyle={{ width: "100%" }}
                      label="From Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                    <Field
                      wrapperStyle={{ width: "100%" }}
                      name="ToDate"
                      label="To Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, left: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default UserWiseBookingReportForm;
