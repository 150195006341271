import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IFlightPlace } from "../flightplaceModel";

export const getAllFlightPlaces = createAsyncThunk(
  "FlightPlaces/FindAllFlightPlaces",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/FindAllFlightPlaces`,
        {
          FlightPlaceName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Flight Places:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createFlightPlace = createAsyncThunk(
  "FlightPlaces/InsertFlightPlaces",
  async (FlightPlaceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/InsertFlightPlaces`,
        FlightPlaceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating flight place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateFlightPlace = createAsyncThunk(
  "FlightPlaces/UpdateFlightPlaces",
  async (FlightPlaceData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/UpdateFlightPlaces`,
        FlightPlaceData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating flight place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteFlightPlace = createAsyncThunk(
  "FlightPlaces/DeleteFlightPlaces",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/DeleteFlightPlaces`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting flight place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getFlightPlaceByID = createAsyncThunk(
  "FlightPlaces/FindByIDFlightPlaces",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/FindByIDFlightPlaces`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        FlightPlaceName: result.FlightPlaceName,
        FlightPlaceCode: result.FlightPlaceCode,
        IsActive: result.IsActive,
      } as IFlightPlace;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching flight place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveFlightPlaces = createAsyncThunk(
  "FlightPlaces/FindAllActiveFlightPlaces",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/FlightPlaces/FindAllActiveFlightPlaces`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Flight Places:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveFlightPlace = createAsyncThunk(
  "FlightPlaces/ActiveInActiveFlightPlaces",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/ActiveInActiveFlightPlaces`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive flight place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
