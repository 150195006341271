import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import {
  createCompany,
  getCompanyByID,
  updateCompany,
} from "./services/company.services";
import { clearCompanyDetails } from "./companySlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ICity } from "../city/cityModel";
import { findIndex } from "lodash";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveStates } from "../state/services/state.services";
import { getAllActiveCountries } from "../country/services/country.services";
import ShadowCard from "../../components/common/ShadowCard";
// import FormTextArea from "../../components/formFields/FormTextArea";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};
interface GSTChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const HandleGstNoChange: React.FC<GSTChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const GSTNo = formRenderProps.valueGetter("GSTNo");
  const PANNo = formRenderProps.valueGetter("PANNo");
  const handleGstNoChange = () => {
    formRenderProps.onChange("GSTNo", { value: GSTNo.toUpperCase() });
  };
  const handlePanNoChange = () => {
    formRenderProps.onChange("PANNo", { value: PANNo.toUpperCase() });
  };

  useEffect(() => {
    handleGstNoChange();
  }, [GSTNo]);

  useEffect(() => {
    handlePanNoChange();
  }, [PANNo]);

  return null;
};

const CreateCompany: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const CompanyID = location.state?.CompanyID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.company.loading);
  const CompanyDetail = useAppSelector((state) => state.company.CompanyDetail);
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);

  useEffect(() => {
    if (CompanyID) {
      dispatch(getCompanyByID(CompanyID));
    }
    return () => {
      dispatch(clearCompanyDetails());
    };
  }, [CompanyID]);

  useEffect(() => {
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
  }, []);

  const handleSubmit = async (values: any) => {
    if (CompanyID) {
      try {
        const updatePayload = {
          ID: CompanyID,
          CompanyName: values.CompanyName,
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? values.CityID : null,
          StateID: values.StateID ? values.StateID : null,
          CountryID: values.CountryID ? values.CountryID : null,
          OwnerName: values.OwnerName ? values.OwnerName : "",
          Pincode: values.Pincode ? values.Pincode?.toString() : "",
          MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
          EmailID: values.EmailID ? values.EmailID : "",
          PANNo: values.PANNo ? values.PANNo?.toString() : "",
          GSTNo: values.GSTNo ? values.GSTNo?.toString() : "",
          AadharNo: values.AadharNo ? values.AadharNo?.toString() : "",
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateCompany(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/company");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          CompanyName: values.CompanyName,
          Address: values.Address ? values.Address : "",
          CityID: values.CityID ? values.CityID : null,
          StateID: values.StateID ? values.StateID : null,
          CountryID: values.CountryID ? values.CountryID : null,
          OwnerName: values.OwnerName ? values.OwnerName : "",
          Pincode: values.Pincode ? values.Pincode?.toString() : "",
          MobileNo: values.MobileNo ? values.MobileNo?.toString() : "",
          EmailID: values.EmailID ? values.EmailID : "",
          PANNo: values.PANNo ? values.PANNo?.toString() : "",
          GSTNo: values.GSTNo ? values.GSTNo?.toString() : "",
          AadharNo: values.AadharNo ? values.AadharNo?.toString() : "",
          IsActive: true,
        };
        const response = await dispatch(createCompany(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={CompanyDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {CompanyID ? "Update Company" : "Create Company"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="CompanyName"
                    maxLength="100"
                    label="Company Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="OwnerName"
                    maxLength="100"
                    label="Owner Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="MobileNo"
                    type="number"
                    label="Mobile No"
                    max="10"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="EmailID"
                    label="Email"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="Address"
                    label="Address"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"CityID"}
                    name={"CityID"}
                    label={"City"}
                    component={FormSelectionField}
                    options={CityList.map((city: any) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"StateID"}
                    name={"StateID"}
                    label={"State"}
                    component={FormSelectionField}
                    // disabled={true}
                    options={StateList.map((state: any) => {
                      return {
                        value: state.ID,
                        label: state.StateName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"CountryID"}
                    name={"CountryID"}
                    label={"Country"}
                    component={FormSelectionField}
                    // disabled={true}
                    options={CountryList.map((country: any) => {
                      return {
                        value: country.ID,
                        label: country.CountryName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Pincode"
                    type="number"
                    label="Pin Code"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="PANNo"
                    label="PAN No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="GSTNo"
                    label="GST No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="AadharNo"
                    label="Aadhar No"
                    type="number"
                    max="12"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/company")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={CompanyID ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <HandleGstNoChange formRenderProps={formRenderProps} />
            <CityChangeWatcher
              CityList={CityList}
              formRenderProps={formRenderProps}
            />
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateCompany;
