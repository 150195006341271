import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITourBookingPlace } from "../tourBookingModel";
import moment from "moment";

export const getAllTourBookings = createAsyncThunk(
  "TourBooking/FindAllTourBooking",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindAllTourBooking`,
        {
          TourBookingNo: "",
          FirstName: "",
          MobileNo: "",
          Sector: "",
          Tour: "",
          TourDateCode: "",
          GroupBookingNo: "",
          BookBy: "",
          Branch: "",
          Limit: 500,
          Skip: 0,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Bookings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourBooking = createAsyncThunk(
  "TourBooking/InsertTourBooking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTourBooking`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourBooking = createAsyncThunk(
  "TourBooking/UpdateTourBooking",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/UpdateTourBooking`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourBooking = createAsyncThunk(
  "TourBooking/DeleteTourBooking",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/DeleteTourBooking`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingByID = createAsyncThunk(
  "TourBooking/FindByIDTourBooking",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/FindByIDTourBooking`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        IsLTCTour: result.IsLTCTour ? 1 : 2,
        SectorID: result.SectorID,
        SubSectorID: result.SubSectorID,
        TourID: result.TourID,
        RoomTypeID: result.RoomTypeID,
        RoomType: result.RoomType,
        TourBookingNo: result.TourBookingNo,
        CompanyID: result.CompanyID,
        Sector: result.Sector,
        TourName: result.TourName,
        Company: result.Company,
        TravelType: result.TravelType,
        TourMonth: result.TourMonth ? +result.TourMonth : null,
        TourDateID: result.TourDateID ? result.TourDateID : null,
        TourDate: result.TourDate ? result.TourDate : "",
        TourDateCode: result.TourDateCode,
        TourStartDate: result.TourStartDate
          ? moment(result.TourStartDate, "YYYY-MM-DD").toDate()
          : "",
        NoOfNights: result.NoOfNights,
        TourEndDate: result.TourEndDate
          ? moment(result.TourEndDate, "YYYY-MM-DD").toDate()
          : "",
        FirstName: result.FirstName,
        LastName: result.LastName,
        MobileNo: result.MobileNo,
        EmailID: result.EmailID,
        Address: result.Address,
        CityID: result.CityID,
        CityName: result.CityName,
        City: result.City,
        StateID: result.StateID,
        State: result.State,
        CountryID: result.CountryID,
        Country: result.Country,
        PinCode: result.PinCode,
        MobileNoDuringTravelling: result.MobileNoDuringTravelling,
        ResidentPhoneNo: result.ResidentPhoneNo,
        EmergencyNo: result.EmergencyNo,
        PANCardNo: result.PANCardNo,
        PassportNo: result.PassportNo,
        AadharNo: result.AadharNo,
        IsCompanyInvoice: result.IsCompanyInvoice ? 1 : 2,
        CompanyName: result.CompanyName,
        CompanyAddress: result.CompanyAddress,
        CompanyCityID: result.CompanyCityID,
        CompanyCity: result.CompanyCity,
        CompanyStateID: result.CompanyStateID,
        CompanyState: result.CompanyState,
        CompanyCountryID: result.CompanyCountryID,
        CompanyCountry: result.CompanyCountry,
        CompanyGSTNo: result.CompanyGSTNo,
        CompanyPANNo: result.CompanyPANNo,
        TotalAdults: result.TotalAdults,
        TotalExtraAdults: result.TotalExtraAdults,
        TotalCWB: result.TotalCWB,
        TotalCNB: result.TotalCNB,
        TotalInfants: result.TotalInfants,
        TotalNoOfPax: result.TotalNoOfPax,
        TotalNoOfRooms: result.TotalNoOfRooms,
        PickUpDate: result.PickUpDate
          ? moment(result.PickUpDate, "YYYY-MM-DD").toDate()
          : "",
        PickUpPlace: result.PickUpPlace ? result.PickUpPlace : "",
        PickUpTime: result.PickUpTime
          ? moment(result.PickUpTime, "HH:mm:ss").toDate()
          : "",
        PickUpFlightNo: result.PickUpFlightNo,
        DropDate: result.DropDate
          ? moment(result.DropDate, "YYYY-MM-DD").toDate()
          : "",
        DropPlace: result.DropPlace ? result.DropPlace : "",
        DropTime: result.DropTime
          ? moment(result.DropTime, "HH:mm:ss").toDate()
          : "",
        DropFlightNo: result.DropFlightNo,
        RouteVoucherNo: result.RouteVoucherNo,
        VehicleTypeID: result.VehicleTypeID,
        VendorID: result.VendorID,
        Vendor: result.Vendor,
        NoOfSeats: result.NoOfSeats,
        Remarks: result.Remarks,
        TicketBookedBy: result.TicketBookedBy,
        FlightNo: result.FlightNo,
        FlightTicketNo: result.FlightTicketNo,
        HotelVoucherNo: result.HotelVoucherNo,
        AvailableNoOfRooms: result.AvailableNoOfRooms,
        TotalNoOfSeats: result.TotalNoOfSeats,
        AvailableNoOfSeats: result.AvailableNoOfSeats,
        IsGST: result.IsGST,
        IsTCS: result.IsTCS,
        TotalAmount: result.TotalAmount,
        TotalGSTAmount: result.TotalGSTAmount,
        TotalExtraCost: result.TotalExtraCost,
        TotalCost: result.TotalCost,
        VehicleCost: result.VehicleCost,
        VehicleSharingPaxID: result.VehicleSharingPaxID,
        SpecialityTypeID: result.SpecialityTypeID,
        SpecialityType: result.SpecialityType,
        Gender: result.Gender,
        OldTourBookingNo: result.OldTourBookingNo,
        BusSeat: result.BusSeat,
        DiscountType: result.DiscountType,
        DiscountAmount: result.DiscountAmount,
        AdminDiscountAmount: result.AdminDiscountAmount,
        DiscountValue: result.DiscountValue,
        TotalDiscountAmount: result.TotalDiscountAmount,
        TotalTCSAmount: result.TotalTCSAmount,
        Kasar: result.Kasar,
        ServiceChargeAmount: result.ServiceChargeAmount,
        VehicleType: result.VehicleType,
        SectorType: result.SectorType,
        ExtraBed: result.ExtraBed,
        VehicleSharing: result.VehicleSharing,
        GroupBookingNo: result.GroupBookingNo,
        FoodOptionID: result.FoodOptionID,
        FoodType: result.FoodType,
        TourPlaceBooking: result.TourPlaceBooking
          ? result.TourPlaceBooking.map((tourplace: any) => {
              return {
                ID: tourplace.ID,
                TourBookingID: tourplace.TourBookingID,
                TourID: tourplace.TourID,
                PlaceID: tourplace.PlaceID,
                City: tourplace.City,
                CheckinDate: tourplace.CheckinDate
                  ? moment(tourplace.CheckinDate, "YYYY-MM-DD").toDate()
                  : "",
                NoOfNights: tourplace.NoOfNights,
                NoOfDays: tourplace.NoOfDays,
                CheckOutDate: tourplace.CheckOutDate
                  ? moment(tourplace.CheckOutDate, "YYYY-MM-DD").toDate()
                  : "",
                Position: tourplace.Position,
                Remarks: tourplace.Remarks,
                HotelID: tourplace.HotelID,
                HotelName: tourplace.HotelName,
                MealPlanID: tourplace.MealPlanID,
              };
            })
          : [],
        RoomPaxes: result.RoomPaxes
          ? result.RoomPaxes.map((roompax: any) => {
              return {
                RoomNo: roompax.RoomNo,
                PaxTypeID: roompax.PaxTypeID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID,
                        TourBookingID: touristpax.TourBookingID,
                        FirstName: touristpax.FirstName,
                        LastName: touristpax.LastName,
                        MobileNo: touristpax.MobileNo,
                        DOB: touristpax.DOB
                          ? moment(touristpax.DOB, "YYYY-MM-DD").toDate()
                          : "",
                        PNR: touristpax.PNR,
                        RoomNo: touristpax.RoomNo,
                        DiscountType: touristpax.DiscountType,
                        DiscountAmount: touristpax.DiscountAmount,
                        Amount: touristpax.Amount,
                        Initial: touristpax.Initial,
                        Gender: touristpax.Gender,
                        Age: touristpax.Age,
                        ExtraCost: touristpax.ExtraCost,
                        Remarks: touristpax.Remarks,
                        IsIsVisa: touristpax.IsVisa,
                        Insurance: touristpax.IsInsurance,
                        FoodOptionID: touristpax.FoodOptionID,
                        PassportNo: touristpax.PassportNo,
                        IssueDate: touristpax.IssueDate
                          ? moment(touristpax.IssueDate, "YYYY-MM-DD").toDate()
                          : "",
                        ExpiryDate: touristpax.ExpiryDate
                          ? moment(touristpax.ExpiryDate, "YYYY-MM-DD").toDate()
                          : "",
                        NationalityID: touristpax.NationalityID,
                      };
                    })
                  : [],
              };
            })
          : [],
        PaxTypeList: result.PaxTypeList
          ? result.PaxTypeList.map((paxtype: any) => {
              return {
                PaxTypeID: paxtype.PaxTypeID,
                NoOfRoom: paxtype.NoOfRoom,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourPlacesByTourID = createAsyncThunk(
  "TourPlace/TourPlaceByTourIDDate",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourPlace/TourPlaceByTourIDDate`,
        {
          TourID: null,
          TourDate: "",
        }
      );
      const result = response.data?.Data as any;
      const TourPlaceDetails = result.map((tourPlace: any) => ({
        TourID: tourPlace.TourID,
        Tour: tourPlace.Tour,
        PlaceID: tourPlace.PlaceID,
        Place: tourPlace.Place,
        NoOfDays: tourPlace.NoOfDays,
        NoOfNights: tourPlace.NoOfNights,
        CheckinDate: tourPlace.CheckinDate,
        CheckOutDate: tourPlace.CheckOutDate,
      })) as ITourBookingPlace[];
      return TourPlaceDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMonthWiseTourDatesByTourID = createAsyncThunk(
  "TourDates/MonthWiseTourDateByTourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourDates/MonthWiseTourDateByTourID`,
        {
          TourID,
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCheckHotelBlockRoom = createAsyncThunk(
  "HotelBlock/CheckHotelBlockRoom",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/CheckHotelBlockRoom`,
        data
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourCost = createAsyncThunk(
  "TourCost/GetTourcost",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourCost/GetTourcost`,
        data
      );
      return response.data?.Data as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour dates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBySectorIDAndTravelType = createAsyncThunk(
  "Tour/TourBySectorIDAndTravelType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourBySectorIDAndTravelType`,
        formData
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour by Sector and Travel Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGetPaxTourCostWithName = createAsyncThunk(
  "TourCost/GetPaxTourCostWithName",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourCost/GetPaxTourCostWithName`,
        formData
      );
      return response.data?.Data?.map((e: any) => ({
        ID: e.ID,
        Amount: e.Amount ? +e.Amount : null,
        ExtraCost: e.ExtraCost ? +e.ExtraCost : null,
        Discount: e.Discount ? +e.Discount : null,
        PaxType: e.PaxType,
        SrNo: e.SrNo,
        FirstName: e.FirstName,
        LastName: e.LastName,
        MobileNo: e.MobileNo,
        Initial: e.Initial,
        Age: e.Age,
        DOB: e.DOB ? moment(e.DOB, "YYYY-MM-DD").toDate() : "",
        Gender: e.Gender,
        FoodOptionID: e.FoodOptionID ? +e.FoodOptionID : null,
        PassportNo: e.PassportNo ? e.PassportNo : "",
        IssueDate: e.IssueDate
          ? moment(e.IssueDate, "YYYY-MM-DD").toDate()
          : "",
        ExpiryDate: e.ExpiryDate
          ? moment(e.ExpiryDate, "YYYY-MM-DD").toDate()
          : "",
        NationalityID: e.NationalityID ? +e.NationalityID : null,
      })) as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour by Sector and Travel Type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTouristInfo = createAsyncThunk(
  "TourBooking/InsertTouristInfo",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/InsertTouristInfo`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
