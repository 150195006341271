import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllActiveCompanies } from "../../company/services/company.services";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { getAllActiveSectors } from "../../sector/services/sector.services";
import { getTourBySectorID } from "../../hotelblock/services/hotelBlock.services";
import { getMonthWiseTourDatesByTourID } from "../../tourbooking/services/tourBooking.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const SectorID = formRenderProps.valueGetter("SectorID");
  const TourID = formRenderProps.valueGetter("TourID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getTourBySectorID(SectorID));
    }
    if (TourID) {
      dispatch(getMonthWiseTourDatesByTourID(TourID));
    }
  }, [SectorID, TourID]);
  return null;
};

const VehicleAllotmentReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const TourList = useAppSelector((state) => state?.hotelBlock?.TourList);
  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  useEffect(() => {
    dispatch(getAllActiveSectors());

    dispatch(getAllActiveCompanies());
    dispatch(getAllActiveTours());
    return () => {
      // dispatch(clearCompanyWiseBookingReportList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    window.open(`vehicleallotmentreportview/${values?.TourDateID}`);
  };
  const filterDates = (Month: number, TourBookingDataListDetails: any) => {
    if (Month) {
      const filteredMonth = TourBookingDataListDetails?.MonthData?.filter(
        (room: any) => +room?.Month === +Month
      );
      return filteredMonth[0]?.TourdateData || [];
    }
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Vehicle Allotment Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="SectorID"
                      label="Sector"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={SectorList?.map((sector: any) => {
                        return {
                          value: sector?.ID,
                          label: sector?.SectorName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourID"
                      label="Tour"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={TourList?.map((tour: any) => {
                        return {
                          value: tour?.TourID,
                          label: tour?.TourName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourMonth"
                      label="Tour Month"
                      component={FormSelectionField}
                      options={TourBookingDataListDetails?.MonthData?.map(
                        (data: any) => {
                          return {
                            value: data?.Month,
                            label: data?.MonthName,
                          };
                        }
                      )}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourDateID"
                      label="Tour Date"
                      component={FormSelectionField}
                      options={filterDates(
                        formRenderProps.valueGetter("TourMonth"),
                        TourBookingDataListDetails
                      )?.map((e: any) => ({
                        label: moment(e.TourDate, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ),
                        value: e.TourDateCode,
                      }))}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  {/* <TourDateChangeWatcher formRenderProps={formRenderProps} /> */}
                  <SectorChangeWatcher formRenderProps={formRenderProps} />
                  <GridLayoutItem
                    colSpan={2}
                    style={{
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, right: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default VehicleAllotmentReportForm;
