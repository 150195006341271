import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { getLedgerReport } from "../services/reports.services";
import moment from "moment";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import Logo from "../../../assets/Images/logo.png";

const ManageLedgerView = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/ledgerreportform",
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights("/ledgerreportform", PRINT_ACCESS);

  const BookingNo = params?.BookingNo;
  const CompanyID = params?.CompanyID;

  const loading = useAppSelector((state) => state.reports.loading);
  const PaymentReceiptLedger = useAppSelector(
    (state) => state.reports.PaymentReceiptLedger
  );

  useEffect(() => {
    if (BookingNo && CompanyID) {
      dispatch(
        getLedgerReport({
          BookingNo: BookingNo,
          CompanyID: +CompanyID !== 3 ? +CompanyID : 0,
        })
      );
    }
  }, [BookingNo, CompanyID]);

  let totalDebitAmount = 0;
  let totalCreditAmount = 0;
  PaymentReceiptLedger &&
    PaymentReceiptLedger.length > 0 &&
    PaymentReceiptLedger?.forEach((booking: any) => {
      totalDebitAmount += booking?.DebitAmount || 0;
      totalCreditAmount += booking?.CreditAmount || 0;
    });

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "LedgerReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "ledgerreport.xlsx");
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    let report_container = document.querySelector(".report-container") as any;
    if (!table) {
      console.error("Table element not found.");
      return;
    }
    printbuttons.style.display = "none";
    report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    report_container.style.padding = "20px 10px";
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        className="report-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 10px",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          {/* Ledger Report{" "} */}
        </Typography.h4>
        <div className="printbuttons">
          {isPrintAccess && (
            <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={printTable}
            >
              Print
            </Button>
          )}
          {isExcelExportAccess && (
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={handleExportExcel}
            >
              Excel Export
            </Button>
          )}
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 d-flex justify-content-center align-items-center">
            <img src={Logo} alt="" width="100%" />
          </div>
          <div
            className="col-10 "
            style={{ fontSize: 12, borderLeft: "1px solid grey" }}
          >
            {PaymentReceiptLedger && (
              <>
                <span style={{ fontWeight: 700 }}>
                  {PaymentReceiptLedger[0]?.CustomerName}
                </span>
                <br />
                <span>
                  {PaymentReceiptLedger[0]?.MobileNo} <br />{" "}
                  {PaymentReceiptLedger[0]?.EmailID}
                </span>
                <br />
                <span>{PaymentReceiptLedger[0]?.Address}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid ">
        <div className="d-flex justify-content-center py-2">
          <Typography.h4 style={{ margin: "0" }}>
            Ledger Report{" "}
            <span style={{ fontSize: 15 }}>
              {PaymentReceiptLedger &&
                PaymentReceiptLedger.length > 0 &&
                `(
              ${PaymentReceiptLedger[0]?.TourName} ${
                  PaymentReceiptLedger[0]?.TourDate && "-"
                } ${PaymentReceiptLedger[0]?.TourDate}
              )`}
            </span>
          </Typography.h4>
        </div>
      </div>
      <div className="table-responsive" style={{ margin: "0 10px" }}>
        <table className="table table-bordered reportScreens">
          <thead>
            <tr>
              {/* <th style={{ background: "black", color: "white" }}>No</th> */}
              <th style={{ background: "black", color: "white" }}>
                Transaction Date
              </th>
              <th style={{ background: "black", color: "white" }}>
                Description
              </th>
              <th style={{ background: "black", color: "white" }}>
                Debit Amount
              </th>
              <th style={{ background: "black", color: "white" }}>
                Credit Amount
              </th>
              <th style={{ background: "black", color: "white" }}>Balance</th>
            </tr>
          </thead>
          <tbody>
            {PaymentReceiptLedger &&
              PaymentReceiptLedger.length > 0 &&
              PaymentReceiptLedger?.map((ledger: any) => (
                <tr>
                  {/* <td>{index + 1}</td> */}
                  <td>
                    {ledger?.TransactionDate
                      ? moment(ledger?.TransactionDate, "DD/MM/YYYY").format(
                          "DD MMM YYYY"
                        )
                      : ""}
                  </td>
                  <td>{ledger?.Description}</td>
                  <td>{ledger?.DebitAmount || 0}</td>
                  <td>{ledger?.CreditAmount || 0}</td>
                  <td>{ledger?.Balance || 0}</td>
                </tr>
              ))}
            <tr className="fw-bold">
              <td
                colSpan={2}
                className="text-end pe-5"
                style={{ background: "lightgray", color: "black" }}
              >
                Total
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalDebitAmount}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalCreditAmount}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalDebitAmount - totalCreditAmount}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManageLedgerView;
