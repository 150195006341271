import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISectorCity, ISectorCityState } from "./sectorCityModel";
import {
  ActiveInactiveSectorCity,
  createSectorCity,
  deleteSectorCity,
  getAllActiveSectorCities,
  getAllSectorCities,
  getSectorCityByID,
  updateSectorCity,
} from "./services/sectorCity.services";

const initialState: ISectorCityState = {
  loading: false,
  error: "",
  SectorCityList: [],
  SectorCityID: null,
  SectorCityDetail: {
    SectorID: null,
    CityID: null,
    IsActive: false,
  },
};

const sectorCitySlice = createSlice({
  name: "sectorCity",
  initialState,
  reducers: {
    clearSectorCityDetails: (state) => {
      state.SectorCityDetail = initialState.SectorCityDetail;
    },
    setSectorCityID: (state, action) => {
      state.SectorCityID = action.payload;
    },
    clearSectorCityID: (state) => {
      state.SectorCityID = initialState.SectorCityID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSectorCities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSectorCities.fulfilled,
      (state, action: PayloadAction<ISectorCity[]>) => {
        state.loading = false;
        state.SectorCityList = action.payload || [];
      }
    );
    builder.addCase(getAllSectorCities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorCityList = [];
    });
    builder.addCase(createSectorCity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createSectorCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createSectorCity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSectorCity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateSectorCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSectorCity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSectorCity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSectorCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSectorCity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSectorCityByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getSectorCityByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.SectorCityDetail = action.payload;
    });
    builder.addCase(getSectorCityByID.rejected, (state, action) => {
      state.loading = false;
      state.SectorCityDetail = initialState.SectorCityDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSectorCities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSectorCities.fulfilled,
      (state, action: PayloadAction<ISectorCity[]>) => {
        state.loading = false;
        state.SectorCityList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSectorCities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorCityList = [];
    });
    builder.addCase(ActiveInactiveSectorCity.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSectorCity.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSectorCity.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearSectorCityDetails, clearSectorCityID, setSectorCityID } =
  sectorCitySlice.actions;
export default sectorCitySlice.reducer;
