import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IGST } from "../gstModel";

export const getAllGST = createAsyncThunk("GST/FindAllGST", async () => {
  try {
    const response = await axiosApiInstance.post(`${API_URL}/GST/FindAllGST`, {
      Title: "",
    });
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching GST:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createGST = createAsyncThunk(
  "GST/InsertGST",
  async (GSTData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/InsertGST`,
        GSTData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateGST = createAsyncThunk(
  "GST/UpdateGST",
  async (GSTData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/UpdateGST`,
        GSTData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteGST = createAsyncThunk(
  "GST/DeleteGST",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(`${API_URL}/GST/DeleteGST`, {
        ID,
      });
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGSTByID = createAsyncThunk(
  "GST/FindByIDGST",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/FindByIDGST`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        Title: result.Title,
        GSTPer: result.GSTPer,
        CGSTPer: result.CGSTPer,
        SGSTPer: result.SGSTPer,
        IsApplyOnServiceTax: result.IsApplyOnServiceTax,
        IsActive: result.IsActive,
      } as IGST;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveGST = createAsyncThunk(
  "GST/FindAllActiveGST",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/GST/FindAllActiveGST`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveGST = createAsyncThunk(
  "GST/ActiveInActiveGST",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GST/ActiveInActiveGST`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive GST:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
