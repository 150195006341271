import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import { TbReportSearch } from "react-icons/tb";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MdTour } from "react-icons/md";
import { LuBookPlus } from "react-icons/lu";
import { RiHotelLine } from "react-icons/ri";
import { LiaCalendarDaySolid } from "react-icons/lia";
import { GiCommercialAirplane, GiTicket } from "react-icons/gi";
import { SiHiveBlockchain } from "react-icons/si";
import { GrTransaction } from "react-icons/gr";
import { IoMdSettings } from "react-icons/io";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Management", "usermanagement", <UserOutlined />, [
    // getItem("Menu", "menu"),
    // getItem("Rights", "rights"),
    getItem("User Type", "usertype"),
    getItem("User", "user"),
  ]),
  getItem("Masters", "masters", <IoMdSettings />, [
    getItem("Country", "country"),
    getItem("State", "state"),
    getItem("City", "city"),
    getItem("Company", "company"),
    getItem("Region", "region"),
    getItem("Sector", "sector"),
    // getItem("Sector City", "sectorcity"),
    getItem("GST", "gst"),
    getItem("Terms And Conditions", "termsandcondition"),
    getItem("Nationality", "nationality"),
    getItem("Speciality Type", "specialitytype"),
    getItem("Room Type", "roomtype"),
    getItem("Meal Type", "mealtype"),
    getItem("Vendor", "vendor"),
    getItem("Vendor Type", "vendortype"),
    getItem("Currency", "currency"),
    getItem("Airline", "airline"),
    getItem("Airport", "airport"),
    getItem("Airline Class", "airlineclass"),
    getItem("Vehicle Sharing Pax", "vehiclesharingpax"),
    getItem("Flight Place", "flightplace"),
    getItem("Pax Type", "paxtype"),
    getItem("Route", "route"),
    getItem("Branch", "branch"),
    getItem("Food Option", "foodoption"),
    getItem("Room Category", "roomcategory"),
    getItem("Hotel Mapping", "hotelmapping"),
  ]),
  getItem("Add Tour", "tour", <MdTour />),
  getItem("Booking Form", "tourbooking", <LuBookPlus />),
  getItem("Hotel Details", "hotel", <RiHotelLine />),
  getItem("Day Wise Itinerary", "grouproute", <LiaCalendarDaySolid />),
  getItem("Airline Ticket", "airlinevoucher", <GiCommercialAirplane />),
  getItem("Payment Receipt", "paymentreceipt", <GrTransaction />),
  getItem("Voucher", "voucher", <GiTicket />, [
    getItem("Hotel Voucher", "hotelvoucher"),
    getItem("Route Voucher", "routevoucher"),
  ]),
  getItem("Block", "block", <SiHiveBlockchain />, [
    getItem("Airline Block", "airlineblock"),
    getItem("Hotel Block", "hotelblock"),
  ]),
  getItem("Reports", "reports", <TbReportSearch />, [
    getItem("Company Wise Booking Report", "companywisebookingreportform"),
   // getItem("Company Wise Pending Payment Report", "companywisependingpaymentreportform"),
    getItem("Vehicle Allotment Report", "vehicleallotmentreportform"),
    getItem("User Wise Tour Booking Report", "userwisetourbookingreportform"),
    getItem("Hotel Block Room Status", "hotelblockroomstatusform"),
    getItem("Customer Report", "customerreportform"),
    getItem("Tour Report", "tourreportform"),
    getItem("Tour Booking Invoice", "tourbookinginvoiceform"),
    getItem("Airline Excel", "airlineblockview"),
    getItem("Ledger Report", "ledgerreportform"),
  ]),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  let userResponse: {
    MenuID: number;
    MenuKey: string;
    MenuName: string;
    RightsID: string;
  }[];

  try {
    userResponse = JSON.parse(userResponseString);
  } catch (error) {
    userResponse = [];
  }

  const allowedKeys = new Set(userResponse.map((item) => item.MenuKey));

  function filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.reduce((filteredItems, item: any) => {
      if (allowedKeys.has(item.key)) {
        if (item.children) {
          const filteredChildren = filterMenuItems(item.children);
          filteredItems.push({ ...item, children: filteredChildren });
        } else {
          filteredItems.push(item);
        }
      } else if (item.children) {
        const filteredChildren = filterMenuItems(item.children);
        if (filteredChildren.length > 0) {
          filteredItems.push({ ...item, children: filteredChildren });
        }
      }
      return filteredItems;
    }, [] as MenuItem[]);
  }

  let filteredItems = filterMenuItems(items);

  const hasDashboardAccess = userResponse.some(
    (item) => item.MenuKey === "dashboard"
  );
  if (hasDashboardAccess) {
    filteredItems = [
      getItem("Dashboard", "", <PieChartOutlined />),
      ...filteredItems,
    ];
  }

  function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.map((item: any) =>
      getItem(
        item.label,
        item.key,
        item.icon,
        item.children ? mapToGetItemFormat(item.children) : undefined
      )
    );
  }

  const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h4 style={{ textAlign: "center", color: "white" }}>RK</h4>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        // mode="vertical"
        items={formattedItems}
      />
    </Sider>
  );
};

export default Header;
