import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createGST,
  getAllGST,
  getGSTByID,
  updateGST,
} from "./services/gst.services";
import { clearGSTDetails } from "./gstSlice";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import { closed } from "../../components/drawer/drawerSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const GSTChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const GSTPer = formRenderProps.valueGetter("GSTPer");
  const halfValue = GSTPer / 2;
  useEffect(() => {
    formRenderProps.onChange("SGSTPer", {
      value: halfValue,
    });
    formRenderProps.onChange("CGSTPer", {
      value: halfValue,
    });
  }, [GSTPer]);
  return null;
};

const CreateGst: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const GSTDetail = useAppSelector((state) => state.gst.GSTDetail);
  const GSTID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.gst.formLoading);

  useEffect(() => {
    if (GSTID) {
      dispatch(getGSTByID(GSTID));
    }
  }, [GSTID]);

  useEffect(() => {
    return () => {
      dispatch(clearGSTDetails());
      dispatch(closed());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      if (!GSTID) {
        const insertPayload = {
          Title: values.Title,
          GSTPer: values.GSTPer ? +values.GSTPer : 0,
          CGSTPer: values.CGSTPer ? +values.CGSTPer : 0,
          SGSTPer: values.SGSTPer ? +values.SGSTPer : 0,
          IsApplyOnServiceTax: values.IsApplyOnServiceTax,
          IsActive: true,
        };
        const response = await dispatch(createGST(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearGSTDetails());
          dispatch(getAllGST());
        }
      } else {
        const editPayload = {
          ID: GSTID,
          Title: values.Title,
          GSTPer: values.GSTPer,
          CGSTPer: values.CGSTPer,
          SGSTPer: values.SGSTPer,
          IsApplyOnServiceTax: values.IsApplyOnServiceTax,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateGST(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearGSTDetails());
          dispatch(getAllGST());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={GSTDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="Title"
                label="Title"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="GSTPer"
                label="GST Percentage"
                type="number"
                max="2"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{ width: "100%", display: "flex", gap: "10px" }}
            >
              <Field
                wrapperClassName="w-100"
                name="CGSTPer"
                label="CGST Percentage"
                type="number"
                disabled={true}
                component={FormTextField}
              />
              <Field
                wrapperClassName="w-100"
                name="SGSTPer"
                label="SGST Percentage"
                type="number"
                disabled={true}
                component={FormTextField}
              />
            </GridLayoutItem>
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="IsApplyOnServiceTax"
                label="Applicable On Service Tax"
                component={FormCheckbox}
              />
            </GridLayoutItem>
            <GridLayoutItem
              style={{
                width: "100%",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  marginTop: "14px",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 4 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={GSTID ? "Update" : " Create"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </div>
            </GridLayoutItem>
          </GridLayout>
          <GSTChangeWatcher formRenderProps={formRenderProps} />
        </FormElement>
      )}
    />
  );
};

export default CreateGst;
