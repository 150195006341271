import * as React from "react";
import {
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { filterBy } from "@progress/kendo-data-query";
import { Error, Hint, Label } from "@progress/kendo-react-labels";
const delay = 300;

const FormMultiSelectionFiled = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    size = "large",
    disabled,
    hint,
    value,
    options = [],
    onChange,
    isSelectAll = false,
    textField = "label",
    wrapperStyle,
    name,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef<any>(null);
  const showValidationMessage: string | false | null =
    touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : "";
  const errorId: string = showValidationMessage ? `${id}_error` : "";
  const labelId: string = label ? `${id}_label` : "";

  const [data, setData] = React.useState(options);
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-undef
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    setData(options);
  }, [options]);

  const handleFilterChange = (event: MultiSelectFilterChangeEvent) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setData(filterBy(options?.slice(), event.filter));
      setLoading(false);
    }, delay);
    setLoading(true);
  };

  const handleSelect = (event: any) => {
    const selectedValuesArray = event.target.value;
    const values = selectedValuesArray.map((option: any) => option.value);
    const allValues = options.map((option: any) => option.value);
    if (values && values.includes("selectall")) {
      onChange({ value: allValues });
    } else {
      onChange({ value: values });
    }
  };

  let initialValue = options?.filter((option: any) => {
    return (value || []).includes(option.value);
  });

  return (
    <>
      <FieldWrapper style={wrapperStyle}>
        <Label
          // editorValue={value}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          // label={label}
          //   labelClassName="k-form-label"
        >
          {label}
        </Label>
        <MultiSelect
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
          data={
            data?.length > 0 && isSelectAll
              ? [{ label: "Select All", value: "selectall" }, ...data]
              : data
          }
          name={name}
          value={Array.isArray(initialValue) ? initialValue : []}
          filterable={true}
          onFilterChange={handleFilterChange}
          loading={loading}
          size={size}
          textField={textField}
          onChange={handleSelect}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </>
  );
};

export default FormMultiSelectionFiled;
