import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete, MdPrint } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import AlertBox from "../../components/common/AlertBox";
import {
  deleteAirlineVoucher,
  getAirlineTicketPDFByID,
  getAllAirlineVouchers,
} from "./services/airlineVoucher.services";
import {
  clearAirlineVoucherID,
  setAirlineVoucherID,
} from "./airlineVoucherSlice";
import moment from "moment";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
  PRINT_ACCESS,
} from "../../_contstants/common";

const AirlineVoucher: React.FC = () => {
  const navigate = useNavigate();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleAirlineVoucherCreate = () => {
    navigate("/airlinevoucher/create");
  };

  return (
    <>
      <DeleteAirlineVoucherDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Airline Ticket List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleAirlineVoucherCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Add Airline Ticket
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <AirlineVoucherGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const AirlineVoucherGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const isPrintAccess = checkAcessRights(location.pathname, PRINT_ACCESS);

  const AirlineVoucherList = useAppSelector(
    (state) => state.airlineVoucher.AirlineVoucherList
  );
  const loading = useAppSelector((state) => state.airlineVoucher.loading);

  useEffect(() => {
    dispatch(getAllAirlineVouchers());
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AirlineVoucherList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditAirlineVoucher = (ID: number) => {
      navigate("/airlinevoucher/edit", {
        state: {
          AirlineVoucherID: ID,
        },
      });
    };

    const handlePrintAirlineVoucher = async (ID: number) => {
      const res = await dispatch(getAirlineTicketPDFByID(ID));
      if (res.meta.requestStatus === "fulfilled") {
        window.open(res.payload?.PdfPath);
      }
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteAirlineVoucher"));
      dispatch(setAirlineVoucherID(ID));
    };
    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditAirlineVoucher(props.dataItem?.ID)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isPrintAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Print"
            onClick={() => handlePrintAirlineVoucher(props.dataItem?.ID)}
          >
            <MdPrint
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 11 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {AirlineVoucherList && AirlineVoucherList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(AirlineVoucherList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : AirlineVoucherList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={AirlineVoucherList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="AirlineVoucherNo"
            title="Airline Ticket No"
            width={200}
          />
          <Column field="TourBookingNo" title="Tour Booking No" width={200} />
          <Column field="Name" title="Name" width={200} />
          <Column field="PNR" title="PNR" width={200} />
          <Column
            field="TicketPurchasedDate"
            title="Ticket Purchased Date"
            width={200}
            cell={(props) => {
              return (
                <td>
                  {props.dataItem?.TicketPurchasedDate
                    ? moment(
                        props.dataItem?.TicketPurchasedDate,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")
                    : ""}
                </td>
              );
            }}
          />
          <Column field="Journey" title="Journey" width={200} />
          <Column field="PNR" title="PNR" width={200} />
          <Column field="NoOfPax" title="No Of Pax" width={200} />
          <Column field="TotalPrice" title="Total Price" width={200} />
          <Column field="BookBy" title="Book By" width={200} />
          <Column field="IsStatus" title="Is Status" width={200} />
          {(isEditAccess || isDeleteAccess || isPrintAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"140px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteAirlineVoucherDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const AirlineVoucherID = useAppSelector(
    (state) => state.airlineVoucher.AirlineVoucherID
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteAirlineVoucher(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllAirlineVouchers());
        dispatch(closeDialog());
        dispatch(clearAirlineVoucherID());
      } else {
        dispatch(getAllAirlineVouchers());
        dispatch(closeDialog());
        dispatch(clearAirlineVoucherID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteAirlineVoucher" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Airline Ticket"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Airline Ticket?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AirlineVoucherID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default AirlineVoucher;
