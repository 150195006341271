import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IVendor, IVendorInitialState } from "./vendorModel";
import {
  ActiveInactiveVendor,
  createVendor,
  deleteVendor,
  getAllActiveVendors,
  getAllVendors,
  getVendorByID,
  updateVendor,
} from "./services/vendor.services";

const initialState: IVendorInitialState = {
  loading: false,
  error: "",
  VendorList: [],
  VendorID: null,
  VendorDetail: {
    VendorTypeID: null,
    VendorName: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    PinCode: null,
    PANNo: "",
    GSTNo: "",
    IsActive: false,
  },
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    clearVendorDetails: (state) => {
      state.VendorDetail = initialState.VendorDetail;
    },
    setVendorID: (state, action) => {
      state.VendorID = action.payload;
    },
    clearVendorID: (state) => {
      state.VendorID = initialState.VendorID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVendors.fulfilled,
      (state, action: PayloadAction<IVendor[]>) => {
        state.loading = false;
        state.VendorList = action.payload || [];
      }
    );
    builder.addCase(getAllVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorList = [];
    });
    builder.addCase(createVendor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createVendor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVendor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateVendor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVendor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVendor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVendorByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getVendorByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.VendorDetail = action.payload;
    });
    builder.addCase(getVendorByID.rejected, (state, action) => {
      state.loading = false;
      state.VendorDetail = initialState.VendorDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveVendors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVendors.fulfilled,
      (state, action: PayloadAction<IVendor[]>) => {
        state.loading = false;
        state.VendorList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveVendors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VendorList = [];
    });
    builder.addCase(ActiveInactiveVendor.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveVendor.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearVendorDetails, clearVendorID, setVendorID } =
  vendorSlice.actions;
export default vendorSlice.reducer;
