import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useRef, useState } from "react";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import RippleButton from "../../../components/common/RippleButton";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { BOOLEAN, VEHICLESHARING } from "../../../_contstants/common";
import { getAllActiveSectors } from "../../sector/services/sector.services";
import { ISector } from "../../sector/sectorModel";
import {
  // getAllActiveCities,
  getCityBySectorID,
} from "../../city/services/city.services";
import { ICompany } from "../../company/companyModel";
import { getAllActiveCompanies } from "../../company/services/company.services";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormTextField from "../../../components/formFields/FormTextField";
import { IState } from "../../state/stateModel";
import { ICountry } from "../../country/countryModel";
import { getAllActiveStates } from "../../state/services/state.services";
import { getAllActiveCountries } from "../../country/services/country.services";
import { findIndex } from "lodash";
import { getAllActiveFoodOptions } from "../../foodoption/services/foodOption.services";
import { IFoodOption } from "../../foodoption/foodOptionModel";
import PaxDetailsArray from "./PaxDetailsArray";
import FormTimePicker from "../../../components/formFields/FormTimeField";
import PlaceInfoArray from "./PlaceInfoArray";
import { ITourList } from "../tourBookingModel";
import FormTextArea from "../../../components/formFields/FormTextArea";
import {
  createTourBooking,
  getCheckHotelBlockRoom,
  getMonthWiseTourDatesByTourID,
  getTourBookingByID,
  // getTourBookingByID,
  getTourBySectorIDAndTravelType,
  getTourCost,
  updateTourBooking,
} from "../services/tourBooking.services";
import moment from "moment";
import { ErrorToast } from "../../../components/toast/Toasts";
import {
  clearTourBookingCostDetails,
  // clearTourBookingDetails,
  increaseTourBookingActiveStep,
} from "../tourBookingSlice";
import {
  requiredValidator,
  requiredValidatorNumber0,
} from "../../../components/formFields/CommonValidator";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import { IVehicleSharingPax } from "../../vehiclesharingpax/vehicleSharingPaxModel";
import { getAllActiveVehicleSharingPaxs } from "../../vehiclesharingpax/services/vehicleSharingPax.services";

export const TRAVELTYPE = [
  { label: "TOUR", value: "TOUR" },
  { label: "PACKAGE", value: "PACKAGE" },
];
export const DISCOUNTTYPE = [
  { label: "FLAT", value: 1 },
  { label: "PERCENTAGE", value: 2 },
];

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  const TravelType = formRenderProps.valueGetter("TravelType");
  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
    if (SectorID && TravelType) {
      const payload = {
        SectorID: SectorID,
        TravelType: TravelType,
      };
      dispatch(getTourBySectorIDAndTravelType(payload));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourID", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [SectorID, TravelType]);
  return null;
};

const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateIDRef = useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");
  const TravelType = formRenderProps.valueGetter("TravelType");

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );

  useEffect(() => {
    if (TourID) {
      dispatch(getMonthWiseTourDatesByTourID(TourID));
    }
    if (!isTourDateIDRef.current) {
      formRenderProps.onChange("TourDateID", {
        value: null,
      });
    } else {
      isTourDateIDRef.current = false;
    }
  }, [TourID]);

  useEffect(() => {
    if (TravelType === "PACKAGE") {
      formRenderProps.onChange("TourCode", {
        value: TourBookingDataListDetails?.TourCode,
      });
    } else {
      formRenderProps.onChange("TourCode", {
        value: "",
      });
    }
  }, [TourBookingDataListDetails]);

  return null;
};

const filterDates = (Month: number, TourBookingDataListDetails: any) => {
  if (Month) {
    const filteredMonth = TourBookingDataListDetails?.MonthData?.filter(
      (room: any) => +room?.Month === +Month
    );
    return filteredMonth[0]?.TourdateData || [];
  }
};

const TourDateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const TourDateID = formRenderProps.valueGetter("TourDateID");
  const TourMonth = formRenderProps.valueGetter("TourMonth");
  const NoofNights = formRenderProps.valueGetter("NoOfNights");
  const TravelType = formRenderProps.valueGetter("TravelType");

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );

  const TourDateCodeDetails = useAppSelector(
    (state) => state.tourBooking.TourDateCodeDetails
  );

  useEffect(() => {
    const TourDate = filterDates(TourMonth, TourBookingDataListDetails)?.filter(
      (e: any) => e.ID === +TourDateID
    )[0]?.TourDate;
    if (TravelType === "TOUR") {
      const formData = {
        TourID: formRenderProps.valueGetter("TourID")?.toString(),
        TourDate: TourDate || "",
      };
      dispatch(getCheckHotelBlockRoom(formData));
      formRenderProps.onChange("TourStartDate", {
        value: TourDate ? moment(TourDate).toDate() : "",
      });
    }
    if (NoofNights && TourDate && TravelType === "TOUR") {
      const newDate =
        moment(TourDate)
          ?.add(+NoofNights || 0, "days")
          ?.toDate() || "";
      formRenderProps.onChange("TourEndDate", {
        value: newDate,
      });
    }
  }, [TourDateID, TourBookingDataListDetails]);

  useEffect(() => {
    if (TourDateCodeDetails?.TourDateCodeList) {
      formRenderProps.onChange("TourDateCode", {
        value: TourDateCodeDetails?.TourDateCodeList[0]?.TourDateCode,
      });
    } else {
      formRenderProps.onChange("TourDateCode", {
        value: "",
      });
    }
  }, [TourDateCodeDetails?.TourDateCodeList]);

  return null;
};

const PaxChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const TourBookingCostDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingCostDetails
  );
  const TravelType = formRenderProps.valueGetter("TravelType");
  const TourMonth = formRenderProps.valueGetter("TourMonth");
  const TourID = formRenderProps.valueGetter("TourID");
  const RoomTypeID = formRenderProps.valueGetter("RoomTypeID");
  const RateNoOfNights = formRenderProps.valueGetter("NoOfNights");
  const VehicleSharingPaxID = formRenderProps.valueGetter(
    "VehicleSharingPaxID"
  );
  const SpecialityTypeID = formRenderProps.valueGetter("SpecialityTypeID");
  const TourDateID = formRenderProps.valueGetter("TourDateID");
  const PaxTypeList = formRenderProps.valueGetter("PaxTypeList");
  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  const TourDate = filterDates(TourMonth, TourBookingDataListDetails)?.filter(
    (e: any) => e.ID === +TourDateID
  )[0]?.TourDate;

  useEffect(() => {
    if (
      TourID &&
      RoomTypeID &&
      RateNoOfNights &&
      VehicleSharingPaxID &&
      PaxTypeList &&
      SpecialityTypeID &&
      TravelType === "TOUR"
      // TourDateID
    ) {
      const payload = {
        TourID: TourID,
        RoomTypeID: RoomTypeID,
        RateNoOfNights: +RateNoOfNights,
        VehicleSharingPaxID: VehicleSharingPaxID,
        SpecialityTypeID: SpecialityTypeID,
        TourDate: TourDate,
        PaxTypeList: PaxTypeList.map((e: any) => ({
          PaxTypeID: e?.PaxTypeID || 0,
          NoOfRoom: +e?.NoOfRoom || 0,
        })),
      };
      dispatch(getTourCost(payload));
    }
  }, [
    PaxTypeList.map((item: any) => item.NoOfRoom).join("-"),
    PaxTypeList.map((item: any) => item.PaxTypeID).join("-"),
    TourID,
    RoomTypeID,
    RateNoOfNights,
    VehicleSharingPaxID,
    SpecialityTypeID,
    TourDateID,
    TourBookingDataListDetails,
    TravelType,
  ]);

  useEffect(() => {
    formRenderProps.onChange(`TotalAmount`, {
      value: TourBookingCostDetails.TotalAmount,
    });
  }, [TourBookingCostDetails]);

  useEffect(() => {
    const totalNoOfRooms = PaxTypeList?.reduce(
      (accumulator: any, currentItem: any) => {
        return accumulator + +currentItem.NoOfRoom;
      },
      0
    );
    const currentTotalRooms =
      +formRenderProps.valueGetter("TotalNoOfRooms") || 0;
    if (totalNoOfRooms > currentTotalRooms) {
      const diffrence = totalNoOfRooms - currentTotalRooms;
      ErrorToast(
        `You can enter only ${
          formRenderProps.valueGetter("TotalNoOfRooms") || 0
        } Rooms`
      );
      formRenderProps.onChange(
        `PaxTypeList.${PaxTypeList?.length - 1}.NoOfRooms`,
        {
          value: diffrence,
        }
      );
    }
  }, [PaxTypeList.map((item: any) => item.NoOfRoom).join("-")]);

  return null;
};

interface StateChangeWatcherProps {
  formRenderProps: FormRenderProps;
  StateList: IState[];
}

const StateChangeWatcher: React.FC<StateChangeWatcherProps> = ({
  StateList,
  formRenderProps,
}) => {
  const stateID = formRenderProps.valueGetter("StateID");

  useEffect(() => {
    const StateID = stateID ? stateID : undefined;
    const stateIndex = findIndex(StateList, { ID: StateID });
    if (stateIndex > -1) {
      const state = StateList[stateIndex];
      formRenderProps.onChange("CountryID", { value: state.CountryID });
    } else {
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [stateID]);
  return null;
};
const TotalAmountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const TotalAmount = formRenderProps.valueGetter("TotalAmount");
  const TotalExtraCost = formRenderProps.valueGetter("TotalExtraCost") || 0;
  const DiscountType = formRenderProps.valueGetter("DiscountType") || 0;
  const DiscountValue = formRenderProps.valueGetter("DiscountValue") || 0;
  const DiscountAmount = formRenderProps.valueGetter("DiscountAmount") || 0;
  const AdminDiscountAmount =
    formRenderProps.valueGetter("AdminDiscountAmount") || 0;
  const TotalDiscountAmount =
    formRenderProps.valueGetter("TotalDiscountAmount") || 0;
  const ServiceChargeAmount =
    formRenderProps.valueGetter("ServiceChargeAmount") || 0;
  const TotalGSTAmount = formRenderProps.valueGetter("TotalGSTAmount") || 0;
  const TotalTCSAmount = formRenderProps.valueGetter("TotalTCSAmount") || 0;
  const Kasar = formRenderProps.valueGetter("Kasar") || 0;
  const VehicleCost = formRenderProps.valueGetter("VehicleCost") || 0;
  const IsGST = formRenderProps.valueGetter("IsGST");
  const IsTCS = formRenderProps.valueGetter("IsTCS");

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  // console.log("TourBookingDataListDetails", TourBookingDataListDetails);

  const TourBookingDetail = useAppSelector(
    (state) => state.tourBooking.TourBookingDetail
  );

  const GSTPer = TourBookingDataListDetails?.GSTPer;
  const TCSPer = TourBookingDataListDetails?.TCSPer;
  const IsApplyOnServiceTax = TourBookingDataListDetails?.IsApplyOnServiceTax;

  useEffect(() => {
    if (GSTPer > 0 && !TourBookingID) {
      formRenderProps.onChange("IsGST", {
        value: true,
      });
    }
  }, [GSTPer]);

  useEffect(() => {
    if (DiscountType === "2" || DiscountType === 2) {
      formRenderProps.onChange("DiscountAmount", {
        value: (
          ((+TotalAmount + +TotalExtraCost) * DiscountValue) /
          100
        )?.toFixed(2),
      });
    } else {
      formRenderProps.onChange("DiscountAmount", {
        value: (+DiscountValue)?.toFixed(2),
      });
    }
  }, [TotalAmount, TotalExtraCost, DiscountValue]);

  useEffect(() => {
    formRenderProps.onChange("TotalDiscountAmount", {
      value: (+DiscountAmount + +AdminDiscountAmount)?.toFixed(2),
    });
  }, [DiscountAmount, AdminDiscountAmount]);

  useEffect(() => {
    if (TourBookingDetail) {
      formRenderProps.onChange("TotalAmount", {
        value: TourBookingDetail?.TotalAmount,
      });
    }
  }, [TourBookingDetail]);

  useEffect(() => {
    formRenderProps.onChange("TotalCost", {
      value: (
        +TotalAmount +
        +TotalExtraCost +
        +ServiceChargeAmount +
        +TotalGSTAmount +
        +VehicleCost +
        +TotalTCSAmount -
        +TotalDiscountAmount -
        +Kasar
      )?.toFixed(2),
    });
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    ServiceChargeAmount,
    TotalGSTAmount,
    TotalTCSAmount,
    VehicleCost,
    Kasar,
    TourBookingDetail,
  ]);

  useEffect(() => {
    if (GSTPer > 0) {
      if (IsGST) {
        if (IsApplyOnServiceTax === true) {
          formRenderProps.onChange("TotalGSTAmount", {
            value: ((+ServiceChargeAmount * +GSTPer) / 100)?.toFixed(2),
          });
        } else {
          formRenderProps.onChange("TotalGSTAmount", {
            value: (
              ((+TotalAmount + +TotalExtraCost - +TotalDiscountAmount) *
                +GSTPer) /
              100
            )?.toFixed(2),
          });
        }
      } else {
        formRenderProps.onChange("TotalGSTAmount", {
          value: 0,
        });
      }
    } else {
      if (IsApplyOnServiceTax === true) {
        formRenderProps.onChange("TotalGSTAmount", {
          value: ((+ServiceChargeAmount * +GSTPer) / 100)?.toFixed(2),
        });
      } else {
        formRenderProps.onChange("TotalGSTAmount", {
          value: (((+TotalAmount + +TotalExtraCost) * +GSTPer) / 100)?.toFixed(
            2
          ),
        });
      }
    }
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    ServiceChargeAmount,
    GSTPer,
    IsGST,
    IsApplyOnServiceTax,
  ]);

  useEffect(() => {
    if (TCSPer > 0 && !TourBookingID) {
      formRenderProps.onChange("IsTCS", {
        value: true,
      });
    }
  }, [TCSPer]);

  useEffect(() => {
    const totalAmount =
      +TotalAmount + +TotalExtraCost + +TotalGSTAmount - +TotalDiscountAmount;
    if (TourBookingDataListDetails?.SectorType === "INTERNATIONAL") {
      if (IsTCS) {
        if (totalAmount <= 700000) {
          formRenderProps.onChange("TotalTCSAmount", {
            value: ((+totalAmount * TCSPer) / 100)?.toFixed(2),
          });
        } else {
          const firstTCSAmount = (700000 * TCSPer) / 100;
          const remainingTCSAmount = +totalAmount - 700000;
          const secondTCSAmount = (+remainingTCSAmount * 20) / 100;

          formRenderProps.onChange("TotalTCSAmount", {
            value: (+firstTCSAmount + +secondTCSAmount)?.toFixed(2),
          });
        }
      } else {
        formRenderProps.onChange("TotalTCSAmount", {
          value: 0,
        });
      }
    } else {
      formRenderProps.onChange("TotalTCSAmount", {
        value: 0,
      });
    }
  }, [
    TotalAmount,
    TotalExtraCost,
    TotalDiscountAmount,
    TotalGSTAmount,
    IsTCS,
    TCSPer,
    TourBookingDataListDetails?.SectorType,
  ]);

  return null;
};

const PlaceDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourPlaceBooking = formRenderProps.valueGetter("TourPlaceBooking");

  useEffect(() => {
    if (TourPlaceBooking && TourPlaceBooking.length > 0) {
      TourPlaceBooking.map((item: any, index: number) => {
        const CheckOutDate = item.CheckinDate
          ? moment(item.CheckinDate)
              .add(+item?.NoOfNights || 0, "days")
              .toDate()
          : "";
        formRenderProps.onChange(`TourPlaceBooking.${index}.CheckOutDate`, {
          value: CheckOutDate || "",
        });
      });
    }
  }, [
    TourPlaceBooking.map((item: any) => item.NoOfNights).join("-"),
    TourPlaceBooking.map((item: any) => item.CheckinDate).join("-"),
  ]);
  return null;
};

const TourDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourStartDate = formRenderProps.valueGetter("TourStartDate");
  const NoOfNights = formRenderProps.valueGetter("NoOfNights");
  useEffect(() => {
    if (TourStartDate) {
      const newDate =
        moment(TourStartDate)
          ?.add(+NoOfNights || 0, "days")
          ?.toDate() || "";
      formRenderProps.onChange("TourEndDate", {
        value: newDate,
      });
    }
  }, [TourStartDate, NoOfNights]);
  return null;
};

const TourBookingDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const TourBookingID = location.state?.TourBookingID;
  const loading = useAppSelector((state) => state.tourBooking.loading);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const TourList = useAppSelector((state) => state.tourBooking.TourList);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const VehicleSharingPaxList = useAppSelector(
    (state) => state.vehicleSharingPax.VehicleSharingPaxList
  );
  // const SpecialityTypeList = useAppSelector(
  //   (state) => state.specialityType.SpecialityTypeList
  // );
  // const RoomTypeList = useAppSelector((state) => state.roomType.RoomTypeList);
  // const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const FoodOptionList = useAppSelector(
    (state) => state.foodOption.FoodOptionList
  );
  // const TourBookingPlaces = useAppSelector(
  //   (state) => state.tourBooking.TourBookingPlaces
  // );
  const TourBookingDetail = useAppSelector(
    (state) => state.tourBooking.TourBookingDetail
  );
  const TourBookingCostDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingCostDetails
  );

  const TourBookingDataListDetails = useAppSelector(
    (state) => state.tourBooking.TourBookingDataListDetails
  );
  const TourDateCodeDetails = useAppSelector(
    (state) => state.tourBooking.TourDateCodeDetails
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveCompanies());
    // dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
    dispatch(getAllActiveFoodOptions());
    dispatch(getAllActiveVehicleSharingPaxs());

    return () => {
      //   console.log("cleared from tourbookingdetails")
      //   dispatch(clearTourBookingDetails());
      dispatch(clearTourBookingCostDetails());
    };
  }, []);

  // useEffect(() => {
  //   if (TourBookingID) {
  //     console.log("tourbooking details called getTourBookingByID");
  //     dispatch(getTourBookingByID(TourBookingID));
  //   }
  // }, [TourBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourBookingDetail]);

  // const handleTourDateChange = (
  //   e: any,
  //   formRenderProps: FormRenderProps,
  //   DateCodeList: any[]
  // ) => {
  //   const value = e?.value;
  //   const TourDate = DateCodeList?.filter((e: any) => e.ID === value)[0]
  //     ?.TourDate;
  //   const NoofNights = formRenderProps.valueGetter("NoOfNights");
  //   // const TourDateCode =
  //   //   (TourDateCodeDetails?.TourDateCodeList &&
  //   //     TourDateCodeDetails?.TourDateCodeList[0]?.TourDateCode) ||
  //   //   "";
  //   const formData = {
  //     TourID: formRenderProps.valueGetter("TourID")?.toString(),
  //     TourDate: TourDate,
  //   };
  //   dispatch(getCheckHotelBlockRoom(formData));
  //   formRenderProps.onChange("TourStartDate", {
  //     value: moment(TourDate).toDate(),
  //   });
  //   if (NoofNights && TourDate) {
  //     const newDate =
  //       moment(TourDate)
  //         ?.add(+NoofNights || 0, "days")
  //         ?.toDate() || "";
  //     formRenderProps.onChange("TourEndDate", {
  //       value: newDate,
  //     });
  //   }
  //   // formRenderProps.onChange("TourDateCode", {
  //   //   value: TourDateCode,
  //   // });
  // };

  const handleTourMonthChange = (e: any, formRenderProps: FormRenderProps) => {
    const NoofNights = TourBookingDataListDetails.NoOfNights;
    formRenderProps.onChange("NoOfNights", {
      value: NoofNights,
    });
  };

  // const handleTourStartDateChange = (
  //   e: any,
  //   formRenderProps: FormRenderProps
  // ) => {
  //   const NoofNights = formRenderProps.valueGetter("NoOfNights");
  //   if (NoofNights && e?.value) {
  //     const newDate =
  //       moment(e?.value)
  //         ?.add(+NoofNights || 0, "days")
  //         ?.toDate() || "";
  //     formRenderProps.onChange("TourEndDate", {
  //       value: newDate,
  //     });
  //   }
  // };

  // const handleNoofNightsChange = (e: any, formRenderProps: FormRenderProps) => {
  //   const TourStartDate = formRenderProps.valueGetter("TourStartDate");
  //   const NoofNights = e?.value;
  //   if (TourStartDate && NoofNights) {
  //     const newDate =
  //       moment(TourStartDate)
  //         ?.add(+NoofNights || 0, "days")
  //         ?.toDate() || "";
  //     formRenderProps.onChange("TourEndDate", {
  //       value: newDate,
  //     });
  //   }
  // };

  const handleSubmit = async (values: any) => {
    try {
      if (!TourBookingID) {
        const insertPayload = {
          IsLTCTour: values.IsLTCTour === 1,
          SectorID: values.SectorID ? +values.SectorID : null,
          TourID: values.TourID ? values.TourID : "",
          RoomTypeID: values.RoomTypeID ? +values.RoomTypeID : null,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          TravelType: values.TravelType ? values.TravelType : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TourMonth: values.TourMonth ? values.TourMonth.toString() : "",
          TourDateID: values.TourDateID ? values.TourDateID : null,
          TourDateCode: values.TourDateCode ? values.TourDateCode : "",
          TourStartDate: values.TourStartDate
            ? moment(values.TourStartDate).format("YYYY-MM-DD")
            : "",
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          TourEndDate: values.TourEndDate
            ? moment(values.TourEndDate).format("YYYY-MM-DD")
            : "",
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          OTP: values.OTP ? values.OTP : "",
          Address: values.Address ? values.Address : "",
          CityName: values.CityName,
          // CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          PinCode: values.PinCode ? values.PinCode : "",
          MobileNoDuringTravelling: values.MobileNoDuringTravelling
            ? values.MobileNoDuringTravelling
            : "",
          ResidentPhoneNo: values.ResidentPhoneNo ? values.ResidentPhoneNo : "",
          EmergencyNo: values.EmergencyNo ? values.EmergencyNo : "",
          PANCardNo: values.PANCardNo ? values.PANCardNo : "",
          PassportNo: values.PassportNo ? values.PassportNo : "",
          AadharNo: values.AadharNo ? values.AadharNo : "",
          PickUpDate: values.PickUpDate
            ? moment(values.PickUpDate).format("YYYY-MM-DD")
            : "",
          PickUpPlace: values.PickUpPlace ? values.PickUpPlace : "",
          PickUpTime: values.PickUpTime
            ? moment(values.PickUpTime).format("HH:mm:ss")
            : "",
          PickUpFlightNo: values.PickUpFlightNo ? values.PickUpFlightNo : "",
          DropDate: values.DropDate
            ? moment(values.DropDate).format("YYYY-MM-DD")
            : "",
          DropPlace: values.DropPlace ? values.DropPlace : "",
          DropTime: values.DropTime
            ? moment(values.DropTime).format("HH:mm:ss")
            : "",
          DropFlightNo: values.DropFlightNo ? values.DropFlightNo : "",
          SMS: values.SMS ? values.SMS : "",
          EmployeeMobileNo: values.EmployeeMobileNo
            ? values.EmployeeMobileNo
            : "",
          EmailTemplate: values.EmailTemplate ? values.EmailTemplate : "",
          EmailSubject: values.EmailSubject ? values.EmailSubject : "",
          TourPlaceBooking: values.TourPlaceBooking
            ? values.TourPlaceBooking.map((tourplace: any) => {
                return {
                  TourID: tourplace.TourID ? tourplace.TourID : null,
                  TourBookingID: tourplace.TourBookingID
                    ? tourplace.TourBookingID
                    : null,
                  PlaceID: tourplace.PlaceID ? tourplace.PlaceID : null,
                  CheckinDate: tourplace.CheckinDate
                    ? moment(tourplace.CheckinDate).format("YYYY-MM-DD")
                    : "",
                  NoOfNights: tourplace.NoOfNights
                    ? tourplace.NoOfNights
                    : null,
                  CheckOutDate: tourplace.CheckOutDate
                    ? moment(tourplace.CheckOutDate).format("YYYY-MM-DD")
                    : "",
                  Position: tourplace.Position ? +tourplace.Position : null,
                  HotelID: tourplace.HotelID ? +tourplace.HotelID : null,
                  MealPlanID: tourplace.MealPlanID
                    ? +tourplace.MealPlanID
                    : null,
                };
              })
            : [],
          TotalNoOfRooms: values.TotalNoOfRooms ? +values.TotalNoOfRooms : null,
          TotalNoOfSeats: values.TotalNoOfSeats ? +values.TotalNoOfSeats : null,
          VehicleSharingPaxID: values.VehicleSharingPaxID
            ? +values.VehicleSharingPaxID
            : null,
          SpecialityTypeID: values.SpecialityTypeID
            ? +values.SpecialityTypeID
            : null,
          OldTourBookingNo: values.OldTourBookingNo
            ? values.OldTourBookingNo
            : "",
          IsGST: values.IsGST ? values.IsGST : false,
          IsTCS: values.IsTCS ? values.IsTCS : false,
          TotalAmount: values.TotalAmount ? +values.TotalAmount : null,
          TotalAdults: values.TotalAdults ? +values.TotalAdults : null,
          TotalExtraAdults: values.TotalExtraAdults
            ? +values.TotalExtraAdults
            : null,
          TotalCWB: values.TotalCWB ? +values.TotalCWB : null,
          TotalCNB: values.TotalCNB ? +values.TotalCNB : null,
          TotalInfants: values.TotalInfants ? +values.TotalInfants : null,
          TotalNoOfPax: values.TotalNoOfPax ? +values.TotalNoOfPax : null,
          TotalCost: values.TotalCost ? +values.TotalCost : null,
          VehicleCost: values.VehicleCost ? +values.VehicleCost : null,
          DiscountType: values.DiscountType ? +values.DiscountType : null,
          DiscountValue: values.DiscountValue ? +values.DiscountValue : null,
          DiscountAmount: values.DiscountAmount ? +values.DiscountAmount : null,
          AdminDiscountAmount: values.AdminDiscountAmount
            ? +values.AdminDiscountAmount
            : null,
          TotalDiscountAmount: values.TotalDiscountAmount
            ? +values.TotalDiscountAmount
            : null,
          TotalTCSAmount: values.TotalTCSAmount ? +values.TotalTCSAmount : null,
          TotalGSTAmount: values.TotalGSTAmount ? +values.TotalGSTAmount : null,
          TotalExtraCost: values.TotalExtraCost ? +values.TotalExtraCost : null,
          Kasar: values.Kasar ? +values.Kasar : null,
          ExtraBed: values.ExtraBed ? +values.ExtraBed : null,
          ServiceChargeAmount: values.ServiceChargeAmount
            ? +values.ServiceChargeAmount
            : null,
          VehicleSharing: values.VehicleSharing
            ? values.VehicleSharing.toString()
            : "",
          GroupBookingNo: values.GroupBookingNo ? values.GroupBookingNo : "",
          TourCode: values.TourCode ? values.TourCode : "",
          FoodOptionID: values.FoodOptionID ? +values.FoodOptionID : null,
          IsActive: values.IsActive ? values.IsActive : true,
          PaxTypeList: values.PaxTypeList
            ? values.PaxTypeList.map((item: any) => {
                return {
                  PaxTypeID: item.PaxTypeID ? +item.PaxTypeID : null,
                  NoOfRoom: item.NoOfRoom ? +item.NoOfRoom : null,
                };
              })
            : [],
        };
        const response = await dispatch(createTourBooking(insertPayload));
        if (
          response?.meta?.requestStatus === "fulfilled" &&
          response.payload.Data?.ID
        ) {
          navigate("/tourbooking/edit", {
            state: {
              TourBookingID: response.payload.Data?.ID,
            },
          });
          dispatch(increaseTourBookingActiveStep());
        }
      } else {
        const editPayload = {
          ID: TourBookingID,
          IsLTCTour: values.IsLTCTour === 1,
          SectorID: values.SectorID ? +values.SectorID : null,
          TourID: values.TourID ? values.TourID : "",
          RoomTypeID: values.RoomTypeID ? +values.RoomTypeID : null,
          CompanyID: values.CompanyID ? +values.CompanyID : null,
          TravelType: values.TravelType ? values.TravelType : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TourMonth: values.TourMonth ? values.TourMonth.toString() : "",
          TourDateID: values.TourDateID ? values.TourDateID : null,
          TourDateCode: values.TourDateCode ? values.TourDateCode : "",
          TourStartDate: values.TourStartDate
            ? moment(values.TourStartDate).format("YYYY-MM-DD")
            : "",
          NoOfNights: values.NoOfNights ? +values.NoOfNights : null,
          TourEndDate: values.TourEndDate
            ? moment(values.TourEndDate).format("YYYY-MM-DD")
            : "",
          FirstName: values.FirstName ? values.FirstName : "",
          LastName: values.LastName ? values.LastName : "",
          MobileNo: values.MobileNo ? values.MobileNo : "",
          EmailID: values.EmailID ? values.EmailID : "",
          OTP: values.OTP ? values.OTP : "",
          Address: values.Address ? values.Address : "",
          CityName: values.CityName,
          // CityID: values.CityID ? +values.CityID : null,
          StateID: values.StateID ? +values.StateID : null,
          CountryID: values.CountryID ? +values.CountryID : null,
          PinCode: values.PinCode ? values.PinCode : "",
          MobileNoDuringTravelling: values.MobileNoDuringTravelling
            ? values.MobileNoDuringTravelling
            : "",
          ResidentPhoneNo: values.ResidentPhoneNo ? values.ResidentPhoneNo : "",
          EmergencyNo: values.EmergencyNo ? values.EmergencyNo : "",
          PANCardNo: values.PANCardNo ? values.PANCardNo : "",
          PassportNo: values.PassportNo ? values.PassportNo : "",
          AadharNo: values.AadharNo ? values.AadharNo : "",
          PickUpDate: values.PickUpDate
            ? moment(values.PickUpDate).format("YYYY-MM-DD")
            : "",
          PickUpPlace: values.PickUpPlace ? values.PickUpPlace : "",
          PickUpTime: values.PickUpTime
            ? moment(values.PickUpTime).format("HH:mm:ss")
            : "",
          PickUpFlightNo: values.PickUpFlightNo ? values.PickUpFlightNo : "",
          DropDate: values.DropDate
            ? moment(values.DropDate).format("YYYY-MM-DD")
            : "",
          DropPlace: values.DropPlace ? values.DropPlace : "",
          DropTime: values.DropTime
            ? moment(values.DropTime).format("HH:mm:ss")
            : "",
          DropFlightNo: values.DropFlightNo ? values.DropFlightNo : "",
          SMS: values.SMS ? values.SMS : "",
          EmployeeMobileNo: values.EmployeeMobileNo
            ? values.EmployeeMobileNo
            : "",
          EmailTemplate: values.EmailTemplate ? values.EmailTemplate : "",
          EmailSubject: values.EmailSubject ? values.EmailSubject : "",
          TourPlaceBooking: values.TourPlaceBooking
            ? values.TourPlaceBooking.map((tourplace: any) => {
                return {
                  TourID: tourplace.TourID ? tourplace.TourID : null,
                  TourBookingID: tourplace.TourBookingID
                    ? tourplace.TourBookingID
                    : null,
                  PlaceID: tourplace.PlaceID ? tourplace.PlaceID : null,
                  CheckinDate: tourplace.CheckinDate
                    ? moment(tourplace.CheckinDate).format("YYYY-MM-DD")
                    : "",
                  NoOfNights: tourplace.NoOfNights
                    ? tourplace.NoOfNights
                    : null,
                  CheckOutDate: tourplace.CheckOutDate
                    ? moment(tourplace.CheckOutDate).format("YYYY-MM-DD")
                    : "",
                  Position: tourplace.Position ? +tourplace.Position : null,
                  HotelID: tourplace.HotelID ? +tourplace.HotelID : null,
                  MealPlanID: tourplace.MealPlanID
                    ? +tourplace.MealPlanID
                    : null,
                };
              })
            : [],
          TotalNoOfRooms: values.TotalNoOfRooms ? +values.TotalNoOfRooms : null,
          TotalNoOfSeats: values.TotalNoOfSeats ? +values.TotalNoOfSeats : null,
          VehicleSharingPaxID: values.VehicleSharingPaxID
            ? +values.VehicleSharingPaxID
            : null,
          SpecialityTypeID: values.SpecialityTypeID
            ? +values.SpecialityTypeID
            : null,
          OldTourBookingNo: values.OldTourBookingNo
            ? values.OldTourBookingNo
            : "",
          IsGST: values.IsGST ? values.IsGST : false,
          IsTCS: values.IsTCS ? values.IsTCS : false,
          TotalAmount: values.TotalAmount ? +values.TotalAmount : null,
          TotalAdults: values.TotalAdults ? +values.TotalAdults : null,
          TotalExtraAdults: values.TotalExtraAdults
            ? +values.TotalExtraAdults
            : null,
          TotalCWB: values.TotalCWB ? +values.TotalCWB : null,
          TotalCNB: values.TotalCNB ? +values.TotalCNB : null,
          TotalInfants: values.TotalInfants ? +values.TotalInfants : null,
          TotalNoOfPax: values.TotalNoOfPax ? +values.TotalNoOfPax : null,
          TotalCost: values.TotalCost ? +values.TotalCost : null,
          VehicleCost: values.VehicleCost ? +values.VehicleCost : null,
          DiscountType: values.DiscountType ? +values.DiscountType : null,
          DiscountValue: values.DiscountValue ? +values.DiscountValue : null,
          DiscountAmount: values.DiscountAmount ? +values.DiscountAmount : null,
          AdminDiscountAmount: values.AdminDiscountAmount
            ? +values.AdminDiscountAmount
            : null,
          TotalDiscountAmount: values.TotalDiscountAmount
            ? +values.TotalDiscountAmount
            : null,
          TotalTCSAmount: values.TotalTCSAmount ? +values.TotalTCSAmount : null,
          TotalGSTAmount: values.TotalGSTAmount ? +values.TotalGSTAmount : null,
          TotalExtraCost: values.TotalExtraCost ? +values.TotalExtraCost : null,
          Kasar: values.Kasar ? +values.Kasar : null,
          ExtraBed: values.ExtraBed ? +values.ExtraBed : null,
          ServiceChargeAmount: values.ServiceChargeAmount
            ? +values.ServiceChargeAmount
            : null,
          VehicleSharing: values.VehicleSharing
            ? values.VehicleSharing.toString()
            : "",
          GroupBookingNo: values.GroupBookingNo ? values.GroupBookingNo : "",
          TourCode: values.TourCode ? values.TourCode : "",
          FoodOptionID: values.FoodOptionID ? +values.FoodOptionID : null,
          IsActive: values.IsActive ? values.IsActive : true,
          PaxTypeList: values.PaxTypeList
            ? values.PaxTypeList.map((item: any) => {
                return {
                  PaxTypeID: item.PaxTypeID ? +item.PaxTypeID : null,
                  NoOfRoom: item.NoOfRoom ? +item.NoOfRoom : null,
                };
              })
            : [],
        };
        const response = await dispatch(updateTourBooking(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          if (TourBookingID) {
            dispatch(getTourBookingByID(TourBookingID));
          }
          dispatch(increaseTourBookingActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourBookingDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tour Booking Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="IsLTCTour"
                  label="Is LTC"
                  component={FormSelectionField}
                  options={BOOLEAN?.map((ltc: any) => {
                    return {
                      value: ltc?.value,
                      label: ltc?.label,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TravelType"
                  label="Travel Type"
                  component={FormSelectionField}
                  options={TRAVELTYPE?.map((traveltype: any) => {
                    return {
                      value: traveltype?.value,
                      label: traveltype?.label,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"SectorID"}
                  name={"SectorID"}
                  label={"Sector"}
                  component={FormSelectionField}
                  options={SectorList?.map((sector: ISector) => {
                    return {
                      value: sector?.ID,
                      label: sector?.SectorName,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"TourID"}
                  name={"TourID"}
                  label={"Tour"}
                  component={FormSelectionField}
                  options={TourList?.map((tour: ITourList) => {
                    return {
                      value: tour?.TourID,
                      label: tour?.TourName,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"CompanyID"}
                  name={"CompanyID"}
                  label={"Company"}
                  component={FormSelectionField}
                  options={CompanyList?.map((company: ICompany) => {
                    return {
                      value: company?.ID,
                      label: company?.CompanyName,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <TourChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem
                style={{ display: "flex", justifyContent: "space-between" }}
                colSpan={3}
              >
                <Typography.h4>{"Tour Date Section"}</Typography.h4>
                {TourDateCodeDetails?.AvailableRooms && (
                  <div
                    style={{
                      backgroundColor: "red",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      borderRadius: 5,
                      height: 30,
                      color: "white",
                    }}
                  >
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {TourDateCodeDetails?.AvailableRooms}
                      {" Rooms Available"}
                    </Typography.h5>
                  </div>
                )}
              </GridLayoutItem>
              {formRenderProps.valueGetter("TravelType") === "TOUR" ? (
                <>
                  <GridLayoutItem>
                    <Field
                      name="TourMonth"
                      label="Tour Month"
                      component={FormSelectionField}
                      onChange={(e) =>
                        handleTourMonthChange(e, formRenderProps)
                      }
                      options={TourBookingDataListDetails?.MonthData?.map(
                        (data: any) => {
                          return {
                            value: data?.Month,
                            label: data?.MonthName,
                          };
                        }
                      )}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourDateID"
                      label="Tour Date"
                      component={FormSelectionField}
                      options={filterDates(
                        formRenderProps.valueGetter("TourMonth"),
                        TourBookingDataListDetails
                      )?.map((e: any) => ({
                        label: moment(e.TourDate, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        ),
                        value: e.ID,
                      }))}
                      validator={requiredValidator}
                    />
                    <TourDateChangeWatcher formRenderProps={formRenderProps} />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      name={"TourDateCode"}
                      label={"Tour Date Code"}
                      component={FormSelectionField}
                      options={TourDateCodeDetails?.TourDateCodeList?.map(
                        (datecode: any) => ({
                          label: datecode.TourDateCode,
                          value: datecode.TourDateCode,
                        })
                      )}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                </>
              ) : (
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"TourCode"}
                    label={"Tour Code"}
                    component={FormTextField}
                    disabled
                  />
                </GridLayoutItem>
              )}
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourStartDate"}
                  label={"Tour Start Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"NoOfNights"}
                  label={"No Of Nights"}
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"TourEndDate"}
                  label={"Tour End Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"SpecialityTypeID"}
                  label={"Speciality Type"}
                  component={FormSelectionField}
                  options={TourBookingDataListDetails?.TourSpecialityData?.map(
                    (specialitytype: any) => {
                      return {
                        value: specialitytype?.SpecialityTypeID,
                        label: specialitytype?.SpecialityType,
                      };
                    }
                  )}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"RoomTypeID"}
                  label={"Tour Type"}
                  component={FormSelectionField}
                  options={TourBookingDataListDetails?.RoomTypeData?.map(
                    (roomtype: any) => {
                      return {
                        value: roomtype?.RoomTypeID,
                        label: roomtype?.RoomType,
                      };
                    }
                  )}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"VehicleSharingPaxID"}
                  label={"Vehicle Sharing for Tour Cost"}
                  component={FormSelectionField}
                  options={VehicleSharingPaxList?.map(
                    (vehiclepax: IVehicleSharingPax) => {
                      return {
                        value: vehiclepax?.ID,
                        label: vehiclepax?.Title,
                      };
                    }
                  )}
                  // options={TourBookingDataListDetails?.VehicleSharingData?.map(
                  //   (vehiclepax: any) => {
                  //     return {
                  //       value: vehiclepax?.VehicleSharingPaxID,
                  //       label: vehiclepax?.VehicleSharingPax,
                  //     };
                  //   }
                  // )}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  name={"TotalNoOfRooms"}
                  label={"No Of Rooms"}
                  type="number"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
                <Field
                  name={"TotalNoOfSeats"}
                  label={"No Of Seats"}
                  type="number"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"ExtraBed"}
                  label={"Extra Bed"}
                  type="number"
                  component={FormTextField}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"VehicleSharing"}
                  label={"Vehicle Sharing"}
                  component={FormSelectionField}
                  options={VEHICLESHARING?.map((vehiclepax: any) => {
                    return {
                      value: vehiclepax?.value,
                      label: vehiclepax?.label,
                    };
                  })}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tourist Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="FirstName"
                  label="First Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="LastName"
                  label="Last Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="MobileNo"
                  label="Mobile No / Whatsapp No"
                  type="number"
                  max="10"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="EmailID"
                  label="Email ID"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={2}>
                <Field
                  name="Address"
                  label="Address"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"CityName"}
                  name={"CityName"}
                  label={"City"}
                  component={FormTextField}
                  // options={CityList?.map((city: ICity) => {
                  //   return {
                  //     value: city?.ID,
                  //     label: city?.CityName,
                  //   };
                  // })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"StateID"}
                  name={"StateID"}
                  label={"State"}
                  component={FormSelectionField}
                  options={StateList?.map((state: IState) => {
                    return {
                      value: state?.ID,
                      label: state?.StateName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"CountryID"}
                  name={"CountryID"}
                  label={"Country"}
                  component={FormSelectionField}
                  options={CountryList?.map((country: ICountry) => {
                    return {
                      value: country?.ID,
                      label: country?.CountryName,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="MobileNoDuringTravelling"
                  label="Travelling Mobile No"
                  type="number"
                  max="10"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="ResidentPhoneNo"
                  label="Resident Phone No"
                  type="number"
                  max="10"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="EmergencyNo"
                  label="Emergency No"
                  type="number"
                  max="10"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="PANCardNo"
                  label="PAN Card No"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="PassportNo"
                  label="Passport No"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="AadharNo"
                  label="Aadhar No"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"FoodOptionID"}
                  name={"FoodOptionID"}
                  label={"Food Preferences"}
                  component={FormSelectionField}
                  options={FoodOptionList?.map((foodoption: IFoodOption) => {
                    return {
                      value: foodoption?.ID,
                      label: foodoption?.Name,
                    };
                  })}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[{ width: "50%" }, { width: "50%" }]}
            >
              <GridLayoutItem>
                <Typography.h4>{"Pax Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <GridLayout
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"PP Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.PPRate}
                      </span>
                    </Typography.h5>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"Extra Adult Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.ExtraAdultRate}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"3 Sharing Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.ThreeSharingRate}
                      </span>
                    </Typography.h5>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"4 Sharing Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.FourSharingRate}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={1}>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"CNB Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.CNBRate}
                      </span>
                    </Typography.h5>
                    <Typography.h5 style={{ marginBottom: 0 }}>
                      {"CWB Rate : "}
                      <span style={{ color: "green" }}>
                        {TourBookingCostDetails?.CWBRate}
                      </span>
                    </Typography.h5>
                  </GridLayoutItem>
                </GridLayout>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={PaxDetailsArray}
                  name="PaxTypeList"
                />
              </GridLayoutItem>
              <PaxChangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem>
                <Typography.h4>{"Amount Calculation"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  width: "100%",
                  fontWeight: 700,
                }}
              >
                <Field
                  name="IsGST"
                  label="Is GST Applicable"
                  maxLength="100"
                  component={FormCheckbox}
                  wrapperStyle={{ marginTop: 8 }}
                  // onChange={() => handleIsMenuCheck(formRenderProps)}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  width: "100%",
                  fontWeight: 700,
                }}
              >
                <Field
                  name="IsTCS"
                  label="Is TCS Applicable"
                  maxLength="100"
                  component={FormCheckbox}
                  wrapperStyle={{ marginTop: 8 }}
                />
              </GridLayoutItem>
              <GridLayoutItem></GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalAmount"
                  label="Total Amount"
                  type="number"
                  disabled={
                    (!TourBookingID && formRenderProps.valueGetter("TravelType") === "PACKAGE")
                      ? false
                      : true
                  }
                  component={FormTextField}
                  validator={requiredValidatorNumber0}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalExtraCost"
                  label="Total Extra Cost"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="DiscountType"
                  label="Discount Type"
                  type="number"
                  component={FormSelectionField}
                  options={DISCOUNTTYPE?.map((discounttype: any) => {
                    return {
                      value: discounttype?.value,
                      label: discounttype?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="DiscountValue"
                  label="Discount Value"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="DiscountAmount"
                  label="Discount Amount"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="AdminDiscountAmount"
                  label="Admin Discount Amount"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalDiscountAmount"
                  label="Total Discount Amount"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="ServiceChargeAmount"
                  label="Service Charge"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalGSTAmount"
                  label="Total GST"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalTCSAmount"
                  label="Total TCS"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="VehicleCost"
                  label="Vehicle Cost"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="Kasar"
                  label="Kasar"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="TotalCost"
                  label="Total Cost"
                  type="number"
                  component={FormTextField}
                  disabled={true}
                />
              </GridLayoutItem>
              <GridLayoutItem colSpan={4}>
                <Field
                  name="Remarks"
                  label="Remarks"
                  component={FormTextArea}
                />
              </GridLayoutItem>
              <TotalAmountChangeWatcher formRenderProps={formRenderProps} />
              <TourDatechangeWatcher formRenderProps={formRenderProps} />
              <PlaceDatechangeWatcher formRenderProps={formRenderProps} />
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"Pickup & Drop Detail"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"PickUpDate"}
                  label={"Pick Up Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"PickUpTime"}
                  label={"Pick Up Time"}
                  component={FormTimePicker}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"PickUpPlace"}
                  label={"Pick Up Place"}
                  component={FormTextField}
                  // options={TourBookingDataListDetails?.PickupPlaceData?.map(
                  //   (tourplace: any) => {
                  //     return {
                  //       value: tourplace?.PickUpPlace,
                  //       label: tourplace?.PickUpPlace,
                  //     };
                  //   }
                  // )}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"PickUpFlightNo"}
                  label={"Pick Up Flight No"}
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"DropDate"}
                  label={"Drop Date"}
                  format="dd/MM/yyyy"
                  component={FormDatePicker}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"DropTime"}
                  label={"Drop Time"}
                  component={FormTimePicker}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"DropPlace"}
                  label={"Drop Place"}
                  component={FormTextField}
                  // options={TourBookingDataListDetails?.DropPlaceData?.map(
                  //   (tourplace: any) => {
                  //     return {
                  //       value: tourplace?.DropPlace,
                  //       label: tourplace?.DropPlace,
                  //     };
                  //   }
                  // )}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={"DropFlightNo"}
                  label={"Drop Flight No"}
                  component={FormTextField}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout gap={{ rows: 0, cols: 10 }} cols={[{ width: "100%" }]}>
              <GridLayoutItem>
                <Typography.h4>{"Place Information"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ marginTop: 10 }}>
                <FieldArray
                  formRenderProps={formRenderProps}
                  component={PlaceInfoArray}
                  name="TourPlaceBooking"
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tourbooking")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          <SectorChangeWatcher formRenderProps={formRenderProps} />
          <StateChangeWatcher
            StateList={StateList}
            formRenderProps={formRenderProps}
          />
        </FormElement>
      )}
    />
  );
};

export default TourBookingDetails;
