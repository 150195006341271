import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { setDeleteIndex } from "../tourSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import FormMultiSelectionFiled from "../../../components/formFields/FormMultiSelectionFiled";
import FormTextField from "../../../components/formFields/FormTextField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { ISectorCity } from "../../city/cityModel";
import { ITourDate } from "../tourModel";

const TourPlaceArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const TourPlaceDeleteIndex = useAppSelector(
    (state) => state.tour.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const TourDateDetails = useAppSelector((state) => state.tour.TourDateDetails);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("tourPlaceDeleteDialog"));
    dispatch(setDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        TourID: null,
        PlaceID: null,
        NoOfNights: null,
        Position: null,
        HotelID: null,
        TourDateCode: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const result = HotelList.filter((hotel: any) => {
        return hotel.CityID === CityID;
      });
      return result.map((hotel) => {
        return {
          value: hotel.ID,
          label: hotel.HotelName,
        };
      });
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Tour Place
        </Button>
      </GridLayoutItem>
      {dialogName === "tourPlaceDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Place"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourPlaceDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((TourPlace: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "20%" },
                  { width: "30%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem>
                  <Field
                    name={`TourPlaces.${index}.PlaceID`}
                    label="Place"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorCityList.map((city: ISectorCity) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourPlaces.${index}.TourDateCode`}
                    label="Tour Date Code"
                    component={FormMultiSelectionFiled}
                    isSelectAll={true}
                    options={TourDateDetails?.map((datecode: ITourDate) => {
                      return {
                        value: datecode.TourDateCode,
                        label: datecode.TourDateCode,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourPlaces.${index}.HotelID`}
                    label="Hotel"
                    component={FormSelectionField}
                    options={HotelList && filterHotels(TourPlace.PlaceID)}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ display: "flex", width: "100%", gap: 10 }}
                >
                  <Field
                    name={`TourPlaces.${index}.NoOfNights`}
                    label="No Of Nights"
                    type="number"
                    component={FormTextField}
                  />
                  <Field
                    name={`TourPlaces.${index}.Position`}
                    label="Position"
                    type="number"
                    max={3}
                    component={FormTextField}
                  />
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24, marginTop: 50 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default TourPlaceArray;
