import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITour, ITourInitialState } from "./tourModel";
import {
  ActiveInactiveTour,
  createTour,
  deleteTour,
  deleteTourRate,
  getAllActiveTours,
  getAllTours,
  getTourByID,
  getTourDatesByTourID,
  getTourPlacesByTourID,
  getTourRatesByTourID,
  insertTourRate,
  updateTour,
  updateTourRate,
} from "./services/tour.services";

const initialState: ITourInitialState = {
  loading: false,
  error: "",
  TourList: [],
  TourCostList: [],
  TourID: null,
  TourCostID: null,
  ActiveStep: 0,
  TourDateDetails: [],
  TourPlaceDetails: [],
  TourCostDetails: {
    ID: null,
    TourID: null,
    StartDate: "",
    EndDate: "",
    CurrencyID: null,
    RoomTypeID: null,
    VehicleSharingPaxID: null,
    RateType: null,
    RateNoOfNights: 0,
    PPRate: 0,
    ExtraAdultRate: 0,
    CWBRate: 0,
    CNBRate: 0,
    InfantRate: 0,
    FourSharingRate: 0,
    ThreeSharingRate: 0,
  },
  deleteIndex: -1,
  TourDetail: {
    SectorType: "",
    SectorID: null,
    SubSectorID: null,
    GSTID: "",
    TravelType: "",
    TourName: "",
    SpecialityTypeID: [],
    TourCode: "",
    CompanyID: null,
    NoOfNights: null,
    NoOfDays: null,
    SpecialRemarks: "",
    PickupPlace: "",
    PickupTime: "",
    PickupRemarks: "",
    DropPlace: "",
    DropTime: "",
    DropRemarks: "",
    PickupFlightPlace: "",
    DropFlightPlace: "",
    IsActive: 1,
  },
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    clearTourInformation: (state) => {
      state.TourDetail = initialState.TourDetail;
    },
    clearTourDateDetails: (state) => {
      state.TourDateDetails = initialState.TourDateDetails;
    },
    clearTourPlaceDetails: (state) => {
      state.TourPlaceDetails = initialState.TourPlaceDetails;
    },
    setTourID: (state, action) => {
      state.TourID = action.payload;
    },
    clearTourID: (state) => {
      state.TourID = initialState.TourID;
    },
    setTourCostID: (state, action) => {
      state.TourCostID = action.payload;
    },
    clearTourCostID: (state) => {
      state.TourCostID = initialState.TourCostID;
    },
    setTourCostDetails: (state, action) => {
      state.TourCostDetails = action.payload;
    },
    clearTourCostDetails: (state) => {
      state.TourCostDetails = initialState.TourCostDetails;
    },
    setActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(createTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDetail = action.payload;
    });
    builder.addCase(getTourByID.rejected, (state, action) => {
      state.loading = false;
      state.TourDetail = initialState.TourDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourDatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourDatesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDateDetails = action.payload;
    });
    builder.addCase(getTourDatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourDateDetails = initialState.TourDateDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourPlacesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourPlacesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourPlaceDetails = action.payload;
    });
    builder.addCase(getTourPlacesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourPlaceDetails = initialState.TourPlaceDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(ActiveInactiveTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourRatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourRatesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourCostList = action.payload;
    });
    builder.addCase(getTourRatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourCostList = initialState.TourCostList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTourInformation,
  clearTourDateDetails,
  clearTourPlaceDetails,
  clearTourID,
  setTourID,
  setTourCostID,
  clearTourCostID,
  setTourCostDetails,
  clearTourCostDetails,
  setDeleteIndex,
  setActiveStep,
  clearActiveStep,
  increaseActiveStep,
  decreaseActiveStep,
} = tourSlice.actions;
export default tourSlice.reducer;
