import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IMealType } from "../mealtypeModel";

export const getAllMealType = createAsyncThunk(
  "MealType/FindAllMealType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/FindAllMealType`,
        {
          MealType: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching mealtypes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveMealTypes = createAsyncThunk(
  "mealType/findallactiveitems",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/MealType/FindAllActiveMealType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching mealtypes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createMealType = createAsyncThunk(
  "mealType/insert",
  async (MealTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/InsertMealType`,
        MealTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating mealType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMealType = createAsyncThunk(
  "mealType/update",
  async (MealTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/UpdateMealType`,
        MealTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating mealType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMealTypeByID = createAsyncThunk(
  "mealtype/findbyid",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/FindByIDMealType`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        MealType: result.MealType,
        IsActive: result.IsActive,
      } as IMealType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching mealType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const mealTypeActiveInactive = createAsyncThunk(
  "mealType/ActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/ActiveInActiveMealType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive mealType:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMealType = createAsyncThunk(
  "mealType/delete",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/MealType/DeleteMealType`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting country:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
