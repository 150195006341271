import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IAirlineClass } from "../airlineclassModel";

export const getAllAirlineClasses = createAsyncThunk(
  "AirlineClass/FindAllAirlineClass",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/FindAllAirlineClass`,
        {
          ClassName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airline Classes:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAirlineClass = createAsyncThunk(
  "AirlineClass/InsertAirlineClass",
  async (AirlineClassData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/InsertAirlineClass`,
        AirlineClassData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airline class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAirlineClass = createAsyncThunk(
  "AirlineClass/UpdateAirlineClass",
  async (AirlineClassData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/UpdateAirlineClass`,
        AirlineClassData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating airline class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAirlineClass = createAsyncThunk(
  "AirlineClass/DeleteAirlineClass",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/DeleteAirlineClass`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting airline class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineClassByID = createAsyncThunk(
  "AirlineClass/FindByIDAirlineClass",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/FindByIDAirlineClass`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        ClassName: result.ClassName,
        IsActive: result.IsActive,
      } as IAirlineClass;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airline class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveAirlineClass = createAsyncThunk(
  "AirlineClass/FindAllActiveAirlineClass",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/AirlineClass/FindAllActiveAirlineClass`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Airline Class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveAirlineClass = createAsyncThunk(
  "AirlineClass/ActiveInActiveAirlineClass",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineClass/ActiveInActiveAirlineClass`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive airline class:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
