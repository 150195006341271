import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { findIndex } from "lodash";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createVendor,
  getVendorByID,
  updateVendor,
} from "./services/vendor.services";
import { clearVendorDetails } from "./vendorSlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveVendorTypes } from "../vendortype/services/vendorType.services";
import { ICity } from "../city/cityModel";
import { getAllActiveCities } from "../city/services/city.services";
import { getAllActiveCountries } from "../country/services/country.services";
import { getAllActiveStates } from "../state/services/state.services";

interface CityChangeWatcherProps {
  formRenderProps: FormRenderProps;
  CityList: ICity[];
}

const CityChangeWatcher: React.FC<CityChangeWatcherProps> = ({
  CityList,
  formRenderProps,
}) => {
  const cityID = formRenderProps.valueGetter("CityID");

  useEffect(() => {
    const CityID = cityID ? cityID : undefined;
    const cityIndex = findIndex(CityList, { ID: CityID });
    if (cityIndex > -1) {
      const city = CityList[cityIndex];
      formRenderProps.onChange("StateID", { value: city.StateID });
      formRenderProps.onChange("CountryID", { value: city.CountryID });
    } else {
      formRenderProps.onChange("CityID", { value: "" });
      formRenderProps.onChange("StateID", { value: "" });
      formRenderProps.onChange("CountryID", { value: "" });
    }
  }, [cityID]);
  return null;
};
interface GSTChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const HandleGstNoChange: React.FC<GSTChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const GSTNo = formRenderProps.valueGetter("GSTNo");
  const PANNo = formRenderProps.valueGetter("PANNo");
  const handleGstNoChange = () => {
    formRenderProps.onChange("GSTNo", { value: GSTNo.toUpperCase() });
  };
  const handlePanNoChange = () => {
    formRenderProps.onChange("PANNo", { value: PANNo.toUpperCase() });
  };

  useEffect(() => {
    handleGstNoChange();
  }, [GSTNo]);

  useEffect(() => {
    handlePanNoChange();
  }, [PANNo]);

  return null;
};

const CreateVendor: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const VendorID = location.state?.VendorID;

  useEffect(() => {
    if (VendorID) {
      dispatch(getVendorByID(VendorID));
    }
    return () => {
      dispatch(clearVendorDetails());
    };
  }, [VendorID]);

  useEffect(() => {
    dispatch(getAllActiveVendorTypes());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveCountries());
  }, []);

  const VendorDetail = useAppSelector((state) => state.vendor.VendorDetail);
  const loading = useAppSelector((state) => state.vendor.loading);

  const VendorTypeList = useAppSelector(
    (state) => state.vendorType.VendorTypeList
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);
  const CountryList = useAppSelector((state) => state.country.CountryList);

  const handleSubmit = async (values: any) => {
    try {
      if (VendorID) {
        const editPayload = {
          ID: VendorID,
          VendorTypeID: values.VendorTypeID,
          VendorName: values.VendorName,
          Address: values.Address,
          CityID: values.CityID,
          StateID: values.StateID,
          CountryID: values.CountryID,
          PinCode: values.PinCode,
          PANNo: values.PANNo,
          GSTNo: values.GSTNo,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateVendor(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } else {
        const insertPayload = {
          VendorTypeID: values.VendorTypeID,
          VendorName: values.VendorName,
          Address: values.Address,
          CityID: values.CityID,
          StateID: values.StateID,
          CountryID: values.CountryID,
          PinCode: values.PinCode,
          PANNo: values.PANNo,
          GSTNo: values.GSTNo,
          IsActive: true,
        };
        const response = await dispatch(createVendor(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/vendor");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={VendorDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {VendorID ? "Update Vendor" : "Create Vendor"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="VendorName"
                      label="Vendor"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"VendorTypeID"}
                      name={"VendorTypeID"}
                      label={"Vendor Type"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={VendorTypeList.map((vendorType: any) => {
                        return {
                          value: vendorType.ID,
                          label: vendorType.VendorType,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Address"
                      label="Address"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CityID"}
                      name={"CityID"}
                      label={"City"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={CityList.map((city: any) => {
                        return {
                          value: city.ID,
                          label: city.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"StateID"}
                      name={"StateID"}
                      label={"State"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      // disabled={true}
                      options={StateList.map((state: any) => {
                        return {
                          value: state.ID,
                          label: state.StateName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      id={"CountryID"}
                      name={"CountryID"}
                      label={"Country"}
                      component={FormSelectionField}
                      validator={requiredValidator}
                      // disabled={true}
                      options={CountryList.map((country: any) => {
                        return {
                          value: country.ID,
                          label: country.CountryName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="PinCode"
                      label="Pin Code"
                      type="number"
                      component={FormTextField}
                      // validator={pincodeValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="PANNo"
                      label="PAN No"
                      component={FormTextField}
                      // validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="GSTNo"
                      label="GST No"
                      component={FormTextField}
                      // validator={gstValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      textAlign: "end",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate("/vendor")}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label={VendorID ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
              <HandleGstNoChange formRenderProps={formRenderProps} />
              <CityChangeWatcher
                CityList={CityList}
                formRenderProps={formRenderProps}
              />
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateVendor;
