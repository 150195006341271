import * as React from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Hint, Error, Label } from "@progress/kendo-react-labels";
import { TextArea } from "@progress/kendo-react-inputs";

const FormTextArea = (props: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    wrapperClassName,
    ...others
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper className={wrapperClassName}>
      <Label
        // label={label}
        editorId={id}
        // editorValue={others.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
        // className="field-label"
      >
        {label}
      </Label>
      <TextArea
        valid={valid}
        id={id}
        size={"large"}
        disabled={disabled}
        rows={4}
        ariaDescribedBy={`${hindId} ${errorId}`}
        {...others}
      />
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormTextArea;
