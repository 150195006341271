import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import { getHotelBlockRoomStatus } from "../services/reports.services";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";

const HotelBlockRoomStatusReportView = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/hotelblockroomstatusform",
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights(
    "/hotelblockroomstatusform",
    PRINT_ACCESS
  );

  const TourID = params?.TourID;
  const TourDate = params?.TourDate;
  const loading = useAppSelector((state) => state.reports.loading);
  const HotelBlockRoomStatusReport = useAppSelector(
    (state) => state.reports.HotelBlockRoomStatusReport
  );

  useEffect(() => {
    if (TourID) {
      const payload = {
        TourID: TourID ? TourID : null,
        TourDate: TourDate === "0" ? "" : TourDate,
      };
      dispatch(getHotelBlockRoomStatus(payload));
    }
  }, []);

  let totalRoom = 0;
  let totalOccupiedRoom = 0;
  let totalBalanceRoom = 0;
  let totalPax = 0;
  HotelBlockRoomStatusReport &&
    HotelBlockRoomStatusReport.length > 0 &&
    HotelBlockRoomStatusReport?.forEach((booking: any) => {
      totalRoom += booking?.NoOfRoom || 0;
      totalOccupiedRoom += booking?.OccupiedRoom || 0;
      totalBalanceRoom += booking?.BalanceRoom || 0;
      totalPax += booking?.NoOfPax || 0;
    });

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "HotelBlockRoomStatusReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "hotelblockroomstatus.xlsx");
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    let report_container = document.querySelector(".report-container") as any;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    printbuttons.style.display = "none";
    report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    report_container.style.padding = "20px 10px";
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        className="report-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 10px",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Hotel Block Room Status{" "}
          <span style={{ fontSize: 15 }}>
            {HotelBlockRoomStatusReport &&
              HotelBlockRoomStatusReport.length > 0 &&
              `(
              ${HotelBlockRoomStatusReport[0]?.TourName} - ${
                TourDate !== "0" &&
                moment(TourDate, "YYYY-MM-DD").format("DD/MM/YYYY")
              }
              )`}
          </span>
        </Typography.h4>
        <div className="printbuttons">
          {isPrintAccess && (
            <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={printTable}
            >
              Print
            </Button>
          )}

          {isExcelExportAccess && (
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={handleExportExcel}
            >
              Excel Export
            </Button>
          )}
        </div>
      </div>
      <div className="table-responsive" style={{ margin: "0 10px" }}>
        <table className="table table-bordered reportScreens">
          <thead>
            <tr>
              <th style={{ background: "black", color: "white" }}>No</th>
              <th style={{ background: "black", color: "white" }}>
                Tour Date Code
              </th>
              <th style={{ background: "black", color: "white" }}>Tour Date</th>
              <th style={{ background: "black", color: "white" }}>
                No Of Room
              </th>
              <th style={{ background: "black", color: "white" }}>
                Occupied Room
              </th>
              <th style={{ background: "black", color: "white" }}>
                Balance Room
              </th>
              <th style={{ background: "black", color: "white" }}>No Of Pax</th>
              <th style={{ background: "black", color: "white" }}>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {HotelBlockRoomStatusReport &&
              HotelBlockRoomStatusReport.length > 0 &&
              HotelBlockRoomStatusReport?.map(
                (hotelblock: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{hotelblock?.TourDateCode}</td>
                    <td>
                      {hotelblock?.TourDate
                        ? moment(hotelblock?.TourDate, "DD/MM/YYYY").format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </td>
                    <td>{hotelblock?.NoOfRoom || 0}</td>
                    <td>{hotelblock?.OccupiedRoom || 0}</td>
                    <td>{hotelblock?.BalanceRoom || 0}</td>
                    <td>{hotelblock?.NoOfPax || 0}</td>
                    <td>{hotelblock?.Remarks}</td>
                  </tr>
                )
              )}
            <tr className="fw-bold">
              <td
                colSpan={3}
                className="text-end pe-5"
                style={{ background: "lightgray", color: "black" }}
              >
                Total
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalRoom}{" "}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalOccupiedRoom}{" "}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalBalanceRoom}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalPax}
              </td>
              <td style={{ background: "lightgray", color: "black" }}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HotelBlockRoomStatusReportView;
