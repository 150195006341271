import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IVendor } from "../vendorModel";

export const getAllVendors = createAsyncThunk(
  "Vendors/FindAllVendors",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/FindAllVendors`,
        {
          VendorName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Vendors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createVendor = createAsyncThunk(
  "Vendors/InsertVendors",
  async (VendorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/InsertVendors`,
        VendorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating vendor :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateVendor = createAsyncThunk(
  "Vendors/UpdateVendors",
  async (VendorData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/UpdateVendors`,
        VendorData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating vendor :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteVendor = createAsyncThunk(
  "Vendors/DeleteVendors",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/DeleteVendors`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting vendor :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getVendorByID = createAsyncThunk(
  "Vendors/FindByIDVendors",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/FindByIDVendors`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        VendorTypeID: result.VendorTypeID,
        VendorName: result.VendorName,
        Address: result.Address,
        CityID: result.CityID,
        StateID: result.StateID,
        CountryID: result.CountryID,
        PinCode: result.PinCode,
        PANNo: result.PANNo,
        GSTNo: result.GSTNo,
        IsActive: result.IsActive,
      } as IVendor;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching vendor :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveVendors = createAsyncThunk(
  "Vendors/FindAllActiveVendors",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Vendors/FindAllActiveVendors`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching vendors:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveVendor = createAsyncThunk(
  "Vendors/ActiveInActiveVendors",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Vendors/ActiveInActiveVendors`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive vendor :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
