import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import { getCompanyWiseBookingReport } from "../services/reports.services";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";

const CompanyWiseBookingReportView = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/companywisebookingreportform",
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights(
    "/companywisebookingreportform",
    PRINT_ACCESS
  );

  const CompanyID = params?.CompanyID;
  const DateType = params?.DateType;
  const Type = params?.Type;
  const TourID = params?.TourID;
  const FromDate = params?.FromDate;
  const ToDate = params?.ToDate;
  const TourDate = params?.TourDate;
  const loading = useAppSelector((state) => state.reports.loading);
  const CompanyWiseBookingReport = useAppSelector(
    (state) => state.reports.CompanyWiseBookingReport
  );

  useEffect(() => {
    if (CompanyID && FromDate && ToDate && DateType && Type) {
      const payload = {
        CompanyID: CompanyID ? CompanyID : null,
        FromDate: FromDate !== "0" ? FromDate : "",
        ToDate: ToDate !== "0" ? ToDate : "",
        TourDate: TourDate !== "0" ? TourDate : "",
        DateType: DateType ? DateType : null,
        Type: Type ? Type : null,
        TourID: TourID !== "0" ? TourID : null,
      };
      dispatch(getCompanyWiseBookingReport(payload));
    }
  }, []);

  let totalPassengers = 0;
  let totalRooms = 0;
  let totalCost = 0;
  let totalVehicleCost = 0;
  let totalTicketCost = 0;
  let totalPaidCost = 0;
  let totalRemainingCost = 0;
  let totalNights = 0;
  CompanyWiseBookingReport &&
    CompanyWiseBookingReport.length > 0 &&
    CompanyWiseBookingReport?.forEach((booking: any) => {
      totalPassengers += booking.TotalNoOfPax || 0;
      totalRooms += +booking.TotalNoOfRooms || 0;
      totalCost += booking.TourCost || 0;
      totalVehicleCost += booking.VehicleCost || 0;
      totalTicketCost += booking.TicketAmount || 0;
      totalPaidCost += booking.PaidAmount || 0;
      totalRemainingCost += booking.RemainingAmount || 0;
      totalNights += +booking.NoOfNights || 0;
    });

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "CompanyWiseBookingReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "companywisebookingreport.xlsx");
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    let report_container = document.querySelector(".report-container") as any;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    printbuttons.style.display = "none";
    report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    report_container.style.padding = "20px 10px";
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        className="report-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 10px",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Company Wise Booking Report{" "}
          <span style={{ fontSize: 15 }}>
            {CompanyWiseBookingReport &&
              CompanyWiseBookingReport.length > 0 &&
              `(
              ${CompanyWiseBookingReport[0]?.Tour} - ${
                FromDate !== "0"
                  ? moment(FromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }${FromDate !== "0" && ToDate !== "0" ? " to " : ""}${
                ToDate !== "0"
                  ? moment(ToDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : " "
              }
              ${
                TourDate !== "0"
                  ? moment(TourDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }
              )`}
          </span>
        </Typography.h4>
        <div className="printbuttons">
          {isPrintAccess && (
            <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={printTable}
            >
              Print
            </Button>
          )}
          {isExcelExportAccess && (
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={handleExportExcel}
            >
              Excel Export
            </Button>
          )}
        </div>
      </div>
      <div className="table-responsive" style={{ margin: "0 10px" }}>
        <table className="table table-bordered reportScreens">
          <thead>
            <tr>
              <th style={{ background: "black", color: "white" }}>No</th>
              <th style={{ background: "black", color: "white" }}>Tour Date</th>
              <th style={{ background: "black", color: "white" }}>
                Tour Booking No
              </th>
              <th style={{ background: "black", color: "white" }}>Name</th>
              <th style={{ background: "black", color: "white" }}>Mobile No</th>
              <th style={{ background: "black", color: "white" }}>
                No. Of Passenger
              </th>
              <th style={{ background: "black", color: "white" }}>
                No. Of Room
              </th>
              <th style={{ background: "black", color: "white" }}>
                No. Of Nights
              </th>
              <th style={{ background: "black", color: "white" }}>
                Tour Date Code
              </th>
              {CompanyID === "1" && (
                <th style={{ background: "black", color: "white" }}>
                  Tour Cost
                </th>
              )}
              <th style={{ background: "black", color: "white" }}>
                Vehicle Cost
              </th>
              {CompanyID === "2" && (
                <th style={{ background: "black", color: "white" }}>
                  Ticket Cost
                </th>
              )}
              <th style={{ background: "black", color: "white" }}>Paid Cost</th>
              <th style={{ background: "black", color: "white" }}>
                Remaining Cost
              </th>
              <th style={{ background: "black", color: "white" }}>
                Vehicle Sharing Pax
              </th>
              <th style={{ background: "black", color: "white" }}>Book By</th>
              <th style={{ background: "black", color: "white" }}>Branch</th>
            </tr>
          </thead>
          <tbody>
            {CompanyWiseBookingReport &&
              CompanyWiseBookingReport.length > 0 &&
              CompanyWiseBookingReport?.map((company: any, index: number) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {company?.TourDate
                      ? moment(company?.TourDate, "DD/MM/YYYY").format(
                          "DD MMM YYYY"
                        )
                      : ""}
                  </td>
                  <td>{company?.TourBookingNo}</td>
                  <td>{company?.Name}</td>
                  <td>{company?.MobileNo}</td>
                  <td>{company?.TotalNoOfPax}</td>
                  <td>{company?.TotalNoOfRooms}</td>
                  <td>{company?.NoOfNights}</td>
                  <td>{company?.TourDateCode}</td>
                  {CompanyID === "1" && (
                    <td>{company?.TourCost - company?.VehicleCost || 0}</td>
                  )}
                  <td>{company?.VehicleCost || 0}</td>
                  {CompanyID === "2" && <td>{company?.TicketAmount || 0}</td>}

                  <td>{company?.PaidAmount || 0}</td>
                  <td>{company?.RemainingAmount || 0}</td>
                  <td>{company?.VehicleSharingPax}</td>
                  <td>{company?.BookByName}</td>
                  <td>{company?.Branch}</td>
                </tr>
              ))}
            <tr className="fw-bold">
              <td
                colSpan={5}
                className="text-end pe-5"
                style={{ background: "lightgray", color: "black" }}
              >
                Total
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalPassengers}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalRooms}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalNights}
              </td>
              <td style={{ background: "lightgray", color: "black" }}></td>
              {CompanyID === "1" && (
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalCost - totalVehicleCost}
                </td>
              )}
              <td style={{ background: "lightgray", color: "black" }}>
                {totalVehicleCost}
              </td>
              {CompanyID === "2" && (
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalTicketCost}
                </td>
              )}
              <td style={{ background: "lightgray", color: "black" }}>
                {totalPaidCost}
              </td>
              <td style={{ background: "lightgray", color: "black" }}>
                {totalRemainingCost}
              </td>
              <td style={{ background: "lightgray", color: "black" }}></td>
              <td style={{ background: "lightgray", color: "black" }}></td>
              <td style={{ background: "lightgray", color: "black" }}></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CompanyWiseBookingReportView;
