import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IBranch } from "../branchModel";

export const getAllBranches = createAsyncThunk(
  "Branch/FindAllBranch",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/FindAllBranch`,
        {
          BranchName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Branches:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createBranch = createAsyncThunk(
  "Branch/InsertBranch",
  async (BranchData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/InsertBranch`,
        BranchData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating branch:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateBranch = createAsyncThunk(
  "Branch/UpdateBranch",
  async (BranchData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/UpdateBranch`,
        BranchData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating branch :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "Branch/DeleteBranch",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/DeleteBranch`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting branch :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBranchByID = createAsyncThunk(
  "Branch/FindByIDBranch",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/FindByIDBranch`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CompanyID: result.CompanyID,
        BranchName: result.BranchName,
        Address: result.Address,
        CityID: result.CityID,
        StateID: result.StateID,
        CountryID: result.CountryID,
        Pincode: result.Pincode,
        BranchHead: result.BranchHead,
        MobileNo: result.MobileNo,
        EmailID: result.EmailID,
        IsActive: result.IsActive ? 1 : 2,
      } as IBranch;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching branch :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveBranches = createAsyncThunk(
  "Branch/FindAllActiveBranch",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Branch/FindAllActiveBranch`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Branches:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveBranch = createAsyncThunk(
  "Branch/ActiveInActiveBranch",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Branch/ActiveInActiveBranch`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive branch :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
