import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFoodOption, IFoodOptionInitialState } from "./foodOptionModel";
import {
  ActiveInactiveFoodOption,
  createFoodOption,
  deleteFoodOption,
  getAllActiveFoodOptions,
  getAllFoodOptions,
  getFoodOptionByID,
  updateFoodOption,
} from "./services/foodOption.services";

const initialState: IFoodOptionInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  FoodOptionList: [],
  FoodOptionID: null,
  FoodOptionDetail: {
    Name: "",
    IsActive: 1,
  },
};

const foodOptionSlice = createSlice({
  name: "foodOption",
  initialState,
  reducers: {
    clearFoodOptionDetails: (state) => {
      state.FoodOptionDetail = initialState.FoodOptionDetail;
    },
    setFoodOptionID: (state, action) => {
      state.FoodOptionID = action.payload;
    },
    clearFoodOptionID: (state) => {
      state.FoodOptionID = initialState.FoodOptionID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFoodOptions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllFoodOptions.fulfilled,
      (state, action: PayloadAction<IFoodOption[]>) => {
        state.loading = false;
        state.FoodOptionList = action.payload || [];
      }
    );
    builder.addCase(getAllFoodOptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FoodOptionList = [];
    });
    builder.addCase(createFoodOption.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createFoodOption.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createFoodOption.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateFoodOption.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateFoodOption.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateFoodOption.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteFoodOption.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteFoodOption.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteFoodOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getFoodOptionByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getFoodOptionByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.FoodOptionDetail = action.payload;
    });
    builder.addCase(getFoodOptionByID.rejected, (state, action) => {
      state.formLoading = false;
      state.FoodOptionDetail = initialState.FoodOptionDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveFoodOptions.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveFoodOptions.fulfilled,
      (state, action: PayloadAction<IFoodOption[]>) => {
        state.loading = false;
        state.FoodOptionList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveFoodOptions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FoodOptionList = [];
    });
    builder.addCase(ActiveInactiveFoodOption.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveFoodOption.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveFoodOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearFoodOptionDetails, clearFoodOptionID, setFoodOptionID } =
  foodOptionSlice.actions;
export default foodOptionSlice.reducer;
