import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IFoodOption } from "../foodOptionModel";

export const getAllFoodOptions = createAsyncThunk(
  "FoodOption/FindAllFoodOption",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/FindAllFoodOption`,
        {
          Name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Food Options:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createFoodOption = createAsyncThunk(
  "FoodOption/InsertFoodOption",
  async (FoodOptionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/InsertFoodOption`,
        FoodOptionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating food option:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateFoodOption = createAsyncThunk(
  "FoodOption/UpdateFoodOption",
  async (FoodOptionData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/UpdateFoodOption`,
        FoodOptionData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating food option:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteFoodOption = createAsyncThunk(
  "FoodOption/DeleteFoodOption",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/DeleteFoodOption`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting food option:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getFoodOptionByID = createAsyncThunk(
  "FoodOption/FindByIDFoodOption",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/FindByIDFoodOption`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        Name: result.Name,
        IsActive: result.IsActive ? 1 : 2,
      } as IFoodOption;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching food option:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveFoodOptions = createAsyncThunk(
  "FoodOption/FindAllActiveFoodOption",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/FoodOption/FindAllActiveFoodOption`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Food Options:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveFoodOption = createAsyncThunk(
  "FoodOption/ActiveInActiveFoodOption",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FoodOption/ActiveInActiveFoodOption`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive food option:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
