import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllRouteVouchers = createAsyncThunk(
  "RouteVoucher/FindAllRouteVoucher",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/FindAllRouteVoucher`,
        {
          Limit: 100,
          Skip: 0,
          RouteVoucher: "",
          TourBookingNo: "",
          Name: "",
          Tour: "",
          TourDateCode: "",
          BookBy: "",
          Branch: "",
        }
      );
      return response.data?.Data as any;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Route Vouchers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRouteVoucher = createAsyncThunk(
  "RouteVoucher/InsertRouteVoucher",
  async (RouteVoucherData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/InsertRouteVoucher`,
        RouteVoucherData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating route voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRouteVoucher = createAsyncThunk(
  "RouteVoucher/UpdateRouteVoucher",
  async (RouteVoucherData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/UpdateRouteVoucher`,
        RouteVoucherData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating route voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRouteVoucher = createAsyncThunk(
  "RouteVoucher/DeleteRouteVoucher",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/DeleteRouteVoucher`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Route Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRouteVoucherByID = createAsyncThunk(
  "RouteVoucher/FindByIDRouteVoucher",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/FindByIDRouteVoucher`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        TotalPax: result.TotalPax,
        VehicleSharing: result.VehicleSharing,
        TourBookingNo: result.TourBookingNo,
        RouteVoucher: result.RouteVoucher,
        SectorID: result.SectorID,
        Sector: result.Sector,
        TourID: result.TourID,
        Tour: result.Tour,
        TourDateCode: result.TourDateCode,
        CompanyID: result.CompanyID,
        Company: result.Company,
        VehicleTypeID: result.VehicleTypeID,
        VehicleType: result.VehicleType,
        TransporterID: result.TransporterID,
        TransporterCityID: result.TransporterCityID,
        GroupRouteID: result.GroupRouteID,
        GroupRoute: result.GroupRoute,
        PickUpDate: result.PickUpDate
          ? moment(result.PickUpDate, "YYYY-MM-DD").toDate()
          : "",
        DropDate: result.DropDate
          ? moment(result.DropDate, "YYYY-MM-DD").toDate()
          : "",
        PickUpFrom: result.PickUpFrom,
        DropAt: result.DropAt,
        TotalNoOfDays: result.TotalNoOfDays,
        TourDateID: result.TourDateID,
        Remarks: result.Remarks,
        BookBy: +result.BookBy,
        OldRouteVoucherNo: result.OldRouteVoucherNo,
        Branch: result.Branch,
        Name: result.Name,
        PickUpFlightNo: result.PickUpFlightNo,
        DropFlightNo: result.DropFlightNo,
        IsActive: result.IsActive,
        TouristPaxInfo: result.TouristPaxInfo
          ? result.TouristPaxInfo.map((touristpax: any) => {
              return {
                RouteBookingID: touristpax.RouteBookingID,
                FirstName: touristpax.FirstName,
                LastName: touristpax.LastName,
                MobileNo: touristpax.MobileNo,
                Age: touristpax.Age,
                Gender: touristpax.Gender,
              };
            })
          : [],
        RouteInfo: result.RouteInfo
          ? result.RouteInfo.map((routeinfo: any) => {
              return {
                RouteBookingID: routeinfo.RouteBookingID,
                DayNo: routeinfo.DayNo,
                RouteName: routeinfo.RouteName,
                Description: routeinfo.Description,
                CityID: routeinfo.CityID,
                HotelID: routeinfo.HotelID,
                City: routeinfo.City,
                HotelName: routeinfo.HotelName,
                RouteID: routeinfo.RouteID,
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Route Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMultiTourBookingData = createAsyncThunk(
  "RouteVoucher/GetMultiTourBookingDataForRouteVoucher",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RouteVoucher/GetMultiTourBookingDataForRouteVoucher`,
        data
      );
      const result = response.data?.Data;
      return {
        ID: result.ID,
        TotalPax: result.TotalPax,
        VehicleSharing: result.VehicleSharing,
        TourBookingNo: result.TourBookingNo,
        RouteVoucher: result.RouteVoucher,
        SectorID: result.SectorID,
        Sector: result.Sector,
        TourID: result.TourID,
        Tour: result.Tour,
        TourDateCode: result.TourDateCode,
        CompanyID: result.CompanyID,
        Company: result.Company,
        VehicleType: result.VehicleType,
        TransporterID: result.TransporterID,
        Transporter: result.Transporter,
        TransporterCityID: result.TransporterCityID,
        GroupRouteID: result.GroupRouteID,
        GroupRoute: result.GroupRoute,
        TourDate: result.TourDate
          ? moment(result.TourDate, "YYYY-MM-DD").toDate()
          : "",
        NoOfNights: result.NoOfNights,
        TotalNoOfDays: result.TotalNoOfDays,
        PickUpDate: result.PickUpDate
          ? moment(result.PickUpDate, "YYYY-MM-DD").toDate()
          : "",
        DropDate: result.DropDate
          ? moment(result.DropDate, "YYYY-MM-DD").toDate()
          : "",
        PickUpFrom: result.PickUpFrom,
        DropAt: result.DropAt,
        PickUpFlightNo: result.PickUpFlightNo,
        DropFlightNo: result.DropFlightNo,
        TourDateID: result.TourDateID,
        Remarks: result.Remarks,
        BookBy: result.BookBy,
        OldRouteVoucherNo: result.OldRouteVoucherNo,
        TouristPaxInfo: result.TouristPaxInfo
          ? result.TouristPaxInfo.map((touristpax: any) => {
              return {
                ID: touristpax.ID,
                FirstName: touristpax.FirstName,
                LastName: touristpax.LastName,
                MobileNo: touristpax.MobileNo,
                Age: touristpax.Age,
                Gender: touristpax.Gender,
              };
            })
          : [],
        CityList: result.CityList
          ? result.CityList.map((city: any) => {
              return {
                CityID: city.CityID,
                City: city.City,
              };
            })
          : [],
        RouteInfo: result.RouteInfo ? result.RouteInfo : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Booking View:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getGroupRouteInfo = createAsyncThunk(
  "GroupRoute/GroupRouteInfo",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/GroupRoute/GroupRouteInfo`,
        {
          GroupRouteID: ID,
        }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data as any[];
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Group Route Info:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);