import React, { useState } from "react";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import {
  InputSuffix,
  TextBox,
  TextBoxProps,
} from "@progress/kendo-react-inputs";
import { Hint, Error, Label } from "@progress/kendo-react-labels";
import { eyeIcon, eyeSlashIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";

const FormPasswordField = (props: TextBoxProps & FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    placeholder,
    ...other
  } = props;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const [inputType, setType] = useState("password");

  return (
    <FieldWrapper>
      <Label
        // label={label}
        editorId={id}
        // editorValue={props.value}
        editorValid={valid}
        editorDisabled={disabled}
        optional={optional}
      >
        {label}
      </Label>
      <TextBox
        {...other}
        valid={valid}
        size={"large"}
        type={inputType}
        placeholder={placeholder}
        suffix={() => (
          <InputSuffix>
            {inputType === "password" ? (
              <Button
                themeColor={showValidationMessage ? "error" : "success"}
                fillMode={"flat"}
                style={{ height: "100%" }}
                svgIcon={eyeSlashIcon}
                onClick={() => setType("text")}
              />
            ) : (
              <Button
                themeColor={showValidationMessage ? "error" : "success"}
                fillMode={"flat"}
                style={{ height: "100%" }}
                svgIcon={eyeIcon}
                onClick={() => setType("password")}
              />
            )}
          </InputSuffix>
        )}
        id={id}
        disabled={disabled}
        aria-describedby={`${hintId} ${errorId}`}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormPasswordField;
