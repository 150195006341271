import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import AppDialog from "../../components/dialog/Dialog";
import { closeDialog } from "../../components/dialog/dialogSlice";
import {
  createSectorCity,
  getAllSectorCities,
  updateSectorCity,
} from "./services/sectorCity.services";
import { clearSectorCityDetails } from "./sectorCitySlice";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveSectors } from "../sector/services/sector.services";
import { getAllActiveCities } from "../city/services/city.services";
import { ISector } from "../sector/sectorModel";
import { ICity } from "../city/cityModel";

const CreateSectorCity: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  useEffect(() => {
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveCities());
  }, []);

  const SectorCityDetail = useAppSelector(
    (state) => state.sectorCity.SectorCityDetail
  );
  const SectorCityID = useAppSelector((state) => state.sectorCity.SectorCityID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const loading = useAppSelector((state) => state.sectorCity.loading);

  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const CityList = useAppSelector((state) => state.city.CityList);

  const handleSubmit = async (values: any) => {
    try {
      if (SectorCityID === 0) {
        const insertPayload = {
          SectorID: values.SectorID,
          CityID: values.CityID,
          IsActive: true,
        };
        const response = await dispatch(createSectorCity(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          dispatch(clearSectorCityDetails());
          dispatch(getAllSectorCities());
        }
      } else {
        const editPayload = {
          ID: SectorCityID,
          SectorID: values.SectorID,
          CityID: values.CityID,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateSectorCity(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closeDialog());
          dispatch(clearSectorCityDetails());
          dispatch(getAllSectorCities());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const dialogCloseHandle = () => {
    dispatch(closeDialog());
    dispatch(clearSectorCityDetails());
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {dialogName === "sectorCityForm" && (
        <AppDialog>
          <div>
            <Typography.h4 style={{ margin: "0px" }}>
              {SectorCityID === 0 ? "Create Sector City" : "Update Sector City"}
            </Typography.h4>
            <Form
              onSubmit={handleSubmit}
              initialValues={SectorCityDetail}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement style={{ width: "600px" }}>
                  <GridLayout
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                    gap={{ rows: 10 }}
                    cols={[{ width: "100%" }]}
                  >
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name="SectorID"
                        label="Sector"
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={SectorList.map((sector: ISector) => {
                          return {
                            value: sector.ID,
                            label: sector.SectorName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        id={"CityID"}
                        name={"CityID"}
                        label={"City Name"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={CityList.map((city: ICity) => {
                          return {
                            value: city.ID,
                            label: city.CityName,
                          };
                        })}
                      />
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{
                        textAlign: "end",
                        marginTop: "20px",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={dialogCloseHandle}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={SectorCityID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </GridLayoutItem>
                  </GridLayout>
                </FormElement>
              )}
            />
          </div>
        </AppDialog>
      )}
    </>
  );
};

export default CreateSectorCity;
