import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ShadowCard from "../../components/common/ShadowCard";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import FormTextField from "../../components/formFields/FormTextField";
import {
  createAirline,
  getAirlineByID,
  updateAirline,
} from "./services/airline.services";
import { clearAirlineDetails } from "./airlineSlice";
import FormRichTextField from "../../components/formFields/FormRichTextField";

const CreateAirline: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const AirlineID = location.state?.AirlineID;

  useEffect(() => {
    if (AirlineID) {
      dispatch(getAirlineByID(AirlineID));
    }
    return () => {
      dispatch(clearAirlineDetails());
    };
  }, [AirlineID]);

  const AirlineDetail = useAppSelector((state) => state.airline.AirlineDetail);
  const loading = useAppSelector((state) => state.airline.loading);

  const handleSubmit = async (values: any) => {
    try {
      if (AirlineID) {
        const editPayload = {
          ID: AirlineID,
          AirlineName: values.AirlineName ? values.AirlineName : "",
          AirlinePrefix: values.AirlinePrefix ? values.AirlinePrefix : "",
          AirlineCode: values.AirlineCode ? values.AirlineCode : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TermsConditions: values.TermsConditions ? values.TermsConditions : "",
          CancellationPolicy: values.CancellationPolicy
            ? values.CancellationPolicy
            : "",
          Note: values.Note ? values.Note : "",
          ImportantInformation: values.ImportantInformation
            ? values.ImportantInformation
            : "",
          ContactDetails: values.ContactDetails ? values.ContactDetails : "",
          IsActive: values.IsActive ? values.IsActive : false,
        };
        const response = await dispatch(updateAirline(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } else {
        const insertPayload = {
          AirlineName: values.AirlineName ? values.AirlineName : "",
          AirlinePrefix: values.AirlinePrefix ? values.AirlinePrefix : "",
          AirlineCode: values.AirlineCode ? values.AirlineCode : "",
          Remarks: values.Remarks ? values.Remarks : "",
          TermsConditions: values.TermsConditions ? values.TermsConditions : "",
          CancellationPolicy: values.CancellationPolicy
            ? values.CancellationPolicy
            : "",
          Note: values.Note ? values.Note : "",
          ImportantInformation: values.ImportantInformation
            ? values.ImportantInformation
            : "",
          ContactDetails: values.ContactDetails ? values.ContactDetails : "",
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(createAirline(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/airline");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <div>
        <Form
          onSubmit={handleSubmit}
          initialValues={AirlineDetail}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <ShadowCard style={{ padding: 12 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 5, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {AirlineID ? "Update Airline" : "Create Airline"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="AirlineName"
                      label="Airline"
                      component={FormTextField}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="AirlinePrefix"
                      label="Airline Prefix"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="AirlineCode"
                      label="Airline Code"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="ImportantInformation"
                      label="Important Information"
                      component={FormRichTextField}
                      rows={6}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Note"
                      label="Note"
                      component={FormRichTextField}
                      rows={6}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CancellationPolicy"
                      label="Cancellation Policy"
                      component={FormRichTextField}
                      rows={6}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem rowSpan={2}>
                    <Field
                      name="TermsConditions"
                      label="Terms & Conditions"
                      component={FormRichTextField}
                      rows={6}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem rowSpan={2}>
                    <Field
                      name="Remarks"
                      label="Remarks"
                      component={FormRichTextField}
                      rows={6}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="ContactDetails"
                      label="Contact Details"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                    }}
                  >
                    <div>
                      <Button
                        type="button"
                        fillMode={"outline"}
                        themeColor={"primary"}
                        style={{ marginRight: 4 }}
                        onClick={() => navigate("/airline")}
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoading
                        label={AirlineID ? "Update" : "Create"}
                        type="submit"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateAirline;
