import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
import { IHotelBlock, IHotelBlockDetails } from "../hotelBlockModel";

export const getAllHotelBlocks = createAsyncThunk(
  "HotelBlock/FindAllHotelBlock",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/FindAllHotelBlock`,
        {
          Limit: 10,
          Skip: 0,
          Tour: "",
          TourDateCode: "",
          TourDate: "",
        }
      );
      const result = response.data?.Data;
      return result.map((item: IHotelBlock) => {
        return {
          ID: item.ID,
          SectorID: item.SectorID,
          TourID: item.TourID,
          SectorName: item.SectorName,
          TourName: item.TourName,
          TourDateID: item.TourDateID,
          TourDate: item.TourDate
            ? moment(item.TourDate)?.format("DD/MM/YYYY")
            : "",
          TourDateCode: item.TourDateCode,
          TotalNoOfRooms: item.TotalNoOfRooms,
          TotalAvailableRooms: item.TotalAvailableRooms,
          TotalAmount: item.TotalAmount,
          AdvanceAmount: item.AdvanceAmount,
          RemainingAmount: item.RemainingAmount,
          ChequeNo: item.ChequeNo,
          BankName: item.BankName,
          ChequeDate: item.ChequeDate
            ? moment(item.TourDate)?.format("DD/MM/YYYY")
            : "",
          BookBy: item.BookBy,
          BookByName: item.BookByName,
          IsDelete: item.IsDelete,
          ItemCount: item.ItemCount,
          TotalPages: item.TotalPages,
          IsActive: item.IsActive,
        } as any;
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Blocks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveHotelBlocks = createAsyncThunk(
  "HotelBlock/FindAllActiveHotelBlock",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/HotelBlock/FindAllActiveHotelBlock`
      );
      return response.data?.Data as IHotelBlock[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Blocks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createHotelBlock = createAsyncThunk(
  "HotelBlock/InsertHotelBlock",
  async (HotelBlockData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/InsertHotelBlock`,
        HotelBlockData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Hotel block:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateHotelBlock = createAsyncThunk(
  "HotelBlock/UpdateHotelBlock",
  async (HotelBlockData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/UpdateHotelBlock`,
        HotelBlockData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Hotel block:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteHotelBlock = createAsyncThunk(
  "HotelBlock/DeleteHotelBlock",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/DeleteHotelBlock`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting Hotel block:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelBlockByID = createAsyncThunk(
  "HotelBlock/FindByIDHotelBlock",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/FindByIDHotelBlock`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        TourID: result.TourID,
        TotalNoOfRooms: result.TotalNoOfRooms,
        TourDateID: result.TourDateID,
        TotalAmount: result.TotalAmount,
        AdvanceAmount: result.AdvanceAmount,
        RemainingAmount: result.RemainingAmount,
        ChequeNo: result.ChequeNo,
        BankName: result.BankName,
        ChequeDate: result.ChequeDate
          ? moment(result.ChequeDate, "YYYY-MM-DD").toDate()
          : "",
        BookBy: result.BookBy,
        Remarks: result.Remarks,
        IsActive: result.IsActive,
        RoomData: result.RoomData
          ? result.RoomData.map((data: any) => {
              return {
                ID: data.ID,
                CityID: data.CityID,
                City: data.City,
                HotelList: data.HotelList,
                HotelID: data.HotelID,
                Hotel: data.Hotel,
                HotelBlockID: data.HotelBlockID,
                SupplierID: data.SupplierID,
                Supplier: data.Supplier,
                AvailableRooms: data.AvailableRooms,
                CheckInDate: data.CheckInDate
                  ? moment(data.CheckInDate, "YYYY-MM-DD").toDate()
                  : "",
                CheckOutDate: data.CheckOutDate
                  ? moment(data.CheckOutDate, "YYYY-MM-DD").toDate()
                  : "",
                NoOfNights: data.NoOfNights,
                RoomTypeID: data.RoomTypeID,
                MealPlanID: data.MealPlanID,
                MealPlan: data.MealPlan,
                NoOfRooms: data.NoOfRooms,
              };
            })
          : [],
      } as IHotelBlockDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Hotel block:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveHotelBlock = createAsyncThunk(
  "HotelBlock/ActiveInActiveHotelBlock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelBlock/ActiveInActiveHotelBlock`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Hotel block:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBySectorID = createAsyncThunk(
  "Tour/TourBySectorID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourBySectorID`,
        {
          SectorID: ID,
        }
      );
      return response.data?.Data as any[];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour by Sector:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelListByTour = createAsyncThunk(
  "TourHotels/HotelListByTour",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/HotelListByTour`,
        {
          TourID: ID,
        }
      );
      return response.data?.Data?.map((hotel: any) => {
        return {
          CityID: hotel.CityID,
          HotelID: hotel.HotelID,
          SupplierID: hotel.SupplierID,
          CheckInDate: hotel.CheckInDate
            ? moment(hotel.CheckInDate, "YYYY-MM-DD").toDate()
            : "",
          NoOfNights: hotel.NoOfNights,
          CheckOutDate: hotel.CheckOutDate
            ? moment(hotel.CheckOutDate, "YYYY-MM-DD").toDate()
            : "",
          RoomTypeID: hotel.RoomTypeID,
          MealPlanID: hotel.MealPlanID,
          NoOfRooms: hotel.NoOfRooms,
          HotelList: hotel.HotelList,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel by Tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourDates = createAsyncThunk(
  "TourDates/GetTourDateData",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourDates/GetTourDateData`,
        {
          TourID: ID,
        }
      );
      return response.data?.Data?.map((item: any) => {
        return {
          ID: item.ID,
          TourDate: item.TourDate
            ? moment(item.TourDate, "YYYY-MM-DD").format("DD/MM/YYYY")
            : "",
          TourDateCode: item.TourDateCode,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tourdates by Tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
