import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICompanyWiseBookingReport,
  ICompanyWisePendingPaymentReport,
  ICustomerList,
  IHotelBlockRoomStatusReport,
  IPaymentReceiptLedger,
  IReportInitialState,
  ITourBookingInvoice,
  ITourReport,
  IUserWiseTourBookingReport,
  IVehicleAllotmentReport,
} from "./reportModel";
import {
  getAirlineBlockBookingExcelSheetData,
  getAirlineBlockFlights,
  getCompanyWiseBookingReport,
  getCompanyWisePendingPaymentReport,
  getCustomerListReport,
  getHotelBlockRoomStatus,
  getLedgerReport,
  getPaymentReceiptCustomerView,
  getTourBookingInvoice,
  getTourBookingReport,
  getTourReport,
  getUserWiseTourBookingReport,
  getVehicleAllotmentReport,
} from "./services/reports.services";

const initialState: IReportInitialState = {
  loading: false,
  listLoading: false,
  error: "",
  CompanyWiseBookingReport: [],
  CompanyWisePendingPaymentReport: [],
  VehicleAllotmentReport: [],
  CompanyWiseBookingReportDetails: {
    CompanyID: null,
    FromDate: "",
    ToDate: "",
    DateType: null,
    Type: null,
  },
  UserWiseTourBookingReport: [],
  UserWiseTourBookingReportDetails: {
    FromDate: "",
    ToDate: "",
    BranchID: null,
    UserID: null,
  },
  HotelBlockRoomStatusReport: [],
  CustomerList: [],
  CustomerDetails: {
    DateType: null,
    FromDate: "",
    ToDate: "",
  },
  TourBookingReportDetails: {
    TourBookingNo: null,
  },
  PaymentReceiptCustomerDetails: {
    PaymentReceiptNo: "",
  },
  TourReport: [],
  TourBookingInvoice: {
    TourBookingNo: "",
  },
  AirlineBlockFlightList: [],
  AirlineBlockViewDetails: {
    DepartureAirportID: null,
    ArrivalAirportID: null,
    DepartureDate: "string",
  },
  AirlineBlockExcelData: {},
  PaymentReceiptLedger: [],
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setCompanyWiseBookingReport: (state, action) => {
      state.CompanyWiseBookingReportDetails = action.payload;
    },
    clearCompanyWiseBookingReportList: (state) => {
      state.CompanyWiseBookingReport = [];
    },
    setUserWiseTourBookingReport: (state, action) => {
      state.UserWiseTourBookingReportDetails = action.payload;
    },
    clearUserWiseTourBookingReportList: (state) => {
      state.UserWiseTourBookingReport = [];
    },
    clearHotelBlockRoomStatusReportList: (state) => {
      state.HotelBlockRoomStatusReport = [];
    },
    setCustomerDetails: (state, action) => {
      state.CustomerDetails = action.payload;
    },
    clearCustomerList: (state) => {
      state.CustomerList = [];
    },
    clearTourReportList: (state) => {
      state.TourReport = [];
    },
    setAirlineBlockViewDetails: (state, action) => {
      state.AirlineBlockViewDetails = action.payload;
    },
    clearAirlineBlockFlightList: (state) => {
      state.AirlineBlockFlightList = [];
    },
    clearPaymentReceiptLedgerList: (state) => {
      state.PaymentReceiptLedger = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyWiseBookingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCompanyWiseBookingReport.fulfilled,
      (state, action: PayloadAction<ICompanyWiseBookingReport[]>) => {
        state.loading = false;
        state.CompanyWiseBookingReport = action.payload || [];
      }
    );
    builder.addCase(getCompanyWiseBookingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyWiseBookingReport = [];
    });
    // COMPANY WISE PENDING PAYMENT REPORT
    builder.addCase(getCompanyWisePendingPaymentReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCompanyWisePendingPaymentReport.fulfilled,
      (state, action: PayloadAction<ICompanyWisePendingPaymentReport[]>) => {
        state.loading = false;
        state.CompanyWisePendingPaymentReport = action.payload || [];
      }
    );
    builder.addCase(
      getCompanyWisePendingPaymentReport.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.CompanyWisePendingPaymentReport = [];
      }
    );
    // VEHICLE ALLOTMENT REPORT
    builder.addCase(getVehicleAllotmentReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getVehicleAllotmentReport.fulfilled,
      (state, action: PayloadAction<IVehicleAllotmentReport[]>) => {
        state.loading = false;
        state.VehicleAllotmentReport = action.payload || [];
      }
    );
    builder.addCase(getVehicleAllotmentReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VehicleAllotmentReport = [];
    });

    builder.addCase(getUserWiseTourBookingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getUserWiseTourBookingReport.fulfilled,
      (state, action: PayloadAction<IUserWiseTourBookingReport[]>) => {
        state.loading = false;
        state.UserWiseTourBookingReport = action.payload || [];
      }
    );
    builder.addCase(getUserWiseTourBookingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserWiseTourBookingReport = [];
    });
    builder.addCase(getHotelBlockRoomStatus.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getHotelBlockRoomStatus.fulfilled,
      (state, action: PayloadAction<IHotelBlockRoomStatusReport[]>) => {
        state.loading = false;
        state.HotelBlockRoomStatusReport = action.payload || [];
      }
    );
    builder.addCase(getHotelBlockRoomStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelBlockRoomStatusReport = [];
    });
    builder.addCase(getCustomerListReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getCustomerListReport.fulfilled,
      (state, action: PayloadAction<ICustomerList[]>) => {
        state.loading = false;
        state.CustomerList = action.payload || [];
      }
    );
    builder.addCase(getCustomerListReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CustomerList = [];
    });
    builder.addCase(getTourBookingReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBookingReport.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.TourBookingReportDetails = action.payload;
      }
    );
    builder.addCase(getTourBookingReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingReportDetails = initialState.TourBookingReportDetails;
    });
    builder.addCase(getPaymentReceiptCustomerView.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getPaymentReceiptCustomerView.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.PaymentReceiptCustomerDetails = action.payload;
      }
    );
    builder.addCase(getPaymentReceiptCustomerView.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaymentReceiptCustomerDetails =
        initialState.PaymentReceiptCustomerDetails;
    });
    builder.addCase(getTourReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourReport.fulfilled,
      (state, action: PayloadAction<ITourReport[]>) => {
        state.loading = false;
        state.TourReport = action.payload || [];
      }
    );
    builder.addCase(getTourReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourReport = [];
    });
    builder.addCase(getTourBookingInvoice.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBookingInvoice.fulfilled,
      (state, action: PayloadAction<ITourBookingInvoice>) => {
        state.loading = false;
        state.TourBookingInvoice = action.payload;
      }
    );
    builder.addCase(getTourBookingInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingInvoice = initialState.TourBookingInvoice;
    });
    builder.addCase(getAirlineBlockFlights.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAirlineBlockFlights.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.AirlineBlockFlightList = action.payload || [];
      }
    );
    builder.addCase(getAirlineBlockFlights.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineBlockFlightList = [];
    });
    builder.addCase(getAirlineBlockBookingExcelSheetData.pending, (state) => {
      state.listLoading = true;
      state.error = "";
    });
    builder.addCase(
      getAirlineBlockBookingExcelSheetData.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.AirlineBlockExcelData = action.payload;
      }
    );
    builder.addCase(
      getAirlineBlockBookingExcelSheetData.rejected,
      (state, action) => {
        state.listLoading = false;
        state.error = action.error.message || "Something went wrong";
        state.AirlineBlockExcelData = initialState.AirlineBlockExcelData;
      }
    );
    builder.addCase(getLedgerReport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getLedgerReport.fulfilled,
      (state, action: PayloadAction<IPaymentReceiptLedger[]>) => {
        state.loading = false;
        state.PaymentReceiptLedger = action.payload || [];
      }
    );
    builder.addCase(getLedgerReport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.PaymentReceiptLedger = [];
    });
  },
});

export const {
  setCompanyWiseBookingReport,
  clearCompanyWiseBookingReportList,
  setUserWiseTourBookingReport,
  clearUserWiseTourBookingReportList,
  clearHotelBlockRoomStatusReportList,
  setCustomerDetails,
  clearCustomerList,
  clearTourReportList,
  setAirlineBlockViewDetails,
  clearAirlineBlockFlightList,
  clearPaymentReceiptLedgerList,
} = reportSlice.actions;
export default reportSlice.reducer;
