import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setHotelDetailsDeleteIndex } from "./hotelVoucherSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import IconButton from "../../components/common/IconButton";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { ISectorCity } from "../city/cityModel";
import { getAllActiveHotels } from "../hotel/services/hotel.services";
import { getAllActiveMealTypes } from "../mealtype/services/mealtype.services";
import { IMealType } from "../mealtype/mealtypeModel";
import { getAllActiveRoomCategories } from "../roomcategory/services/roomCategory.services";
import { IRoomCategory } from "../roomcategory/roomCategoryModel";

const HotelDetailsArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const HotelDetailsDeleteIndex = useAppSelector(
    (state) => state.hotelVoucher.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);
  const MealTypeList = useAppSelector((state) => state.mealType.MealTypeList);
  const RoomCategoryList = useAppSelector(
    (state) => state.roomCategory.RoomCategoryList
  );

  useEffect(() => {
    dispatch(getAllActiveHotels());
    dispatch(getAllActiveMealTypes());
    dispatch(getAllActiveRoomCategories());

    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("hotelDetailsDeleteDialog"));
    dispatch(setHotelDetailsDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setHotelDetailsDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        CityID: null,
        HotelID: null,
        CheckInDate: "",
        NoOfNights: null,
        CheckOutDate: "",
        RoomCategoryID: null,
        TotalNoOfRooms: null,
        MealPlanID: null,
        ExtraBed: null,
      },
    });
  }, [fieldArrayRenderProps]);

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const result = HotelList.filter((hotel: any) => {
        return hotel.CityID === CityID;
      });
      return result.map((hotel) => {
        return {
          value: hotel.ID,
          label: hotel.HotelName,
        };
      });
      // const filteredRoom = HotelBookingDetail?.HotelDetails?.filter(
      //   (city: any) => +city?.CityID === +CityID
      // );
      // const filteredHotelList =
      //   (filteredRoom &&
      //     filteredRoom[0]?.HotelList?.map((hotel: any) => {
      //       return {
      //         value: hotel.ID,
      //         label: hotel.HotelName,
      //       };
      //     })) ||
      //   [];
      // return filteredHotelList;
    }
  };

  return (
    <GridLayout
      style={{ marginRight: 10 }}
      gap={{ rows: 5, cols: 10 }}
      cols={[{ width: "50%" }, { width: "50%" }]}
    >
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }} colSpan={2}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Hotel
        </Button>
      </GridLayoutItem>
      {dialogName === "hotelDetailsDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Hotel Details"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(HotelDetailsDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((HotelDetails: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card
              className="fieldarray-card"
              style={{
                padding: "20px",
              }}
            >
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "42%" }, { width: "16%" }, { width: "42%" }]}
              >
                <GridLayoutItem colSpan={2}>
                  <div
                    style={{
                      display: "inline-block",
                      color: "white",
                      background: "red",
                      padding: "8px 15px",
                      borderRadius: "20px",
                    }}
                  >
                    Place - {index + 1}
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name={`HotelDetails.${index}.CityID`}
                    label="Place"
                    component={FormSelectionField}
                    options={SectorCityList?.map((city: ISectorCity) => {
                      return {
                        value: city?.ID,
                        label: city?.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}>
                  <Field
                    name={`HotelDetails.${index}.HotelID`}
                    label="Hotel"
                    component={FormSelectionField}
                    options={HotelList && filterHotels(HotelDetails.CityID)}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelDetails.${index}.CheckInDate`}
                    label="Check In Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelDetails.${index}.NoOfNights`}
                    label="Nights"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`HotelDetails.${index}.CheckOutDate`}
                    label="Check Out Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    name={`HotelDetails.${index}.RoomCategoryID`}
                    label="Room Category"
                    component={FormSelectionField}
                    options={RoomCategoryList?.map(
                      (roomcategory: IRoomCategory) => {
                        return {
                          value: roomcategory.ID,
                          label: roomcategory.RoomCategory,
                        };
                      }
                    )}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name={`HotelDetails.${index}.MealPlanID`}
                    label="Plan"
                    component={FormSelectionField}
                    options={MealTypeList?.map((mealtype: IMealType) => {
                      return {
                        value: mealtype.ID,
                        label: mealtype.MealType,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                  colSpan={3}
                >
                  <Field
                    wrapperClassName="w-100"
                    name={`HotelDetails.${index}.TotalNoOfRooms`}
                    label="Rooms"
                    type="number"
                    component={FormTextField}
                  />
                  <Field
                    wrapperClassName="w-100"
                    name={`HotelDetails.${index}.ExtraBed`}
                    label="Extra Bed"
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                    <Field
                      name={`hoteldetail.${index}.PickupFrom`}
                      label="Pickup From"
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`hoteldetail.${index}.PickupTime`}
                      label="Pickup Time"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem> */}
                {/* <GridLayoutItem>
                  <Field
                    name={`hoteldetail.${index}.TravelBy`}
                    label="By"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
                {/* <GridLayoutItem>
                  <Field
                    name={`hoteldetail.${index}.SupplierID`}
                    label="Supplier"
                    component={FormSelectionField}
                    options={VendorList?.map((vendor: IVendor) => {
                      return {
                        value: vendor?.ID,
                        label: vendor?.VendorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name={`hoteldetail.${index}.Remarks`}
                    label="Remarks"
                    component={FormTextField}
                  />
                </GridLayoutItem> */}
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default HotelDetailsArray;
