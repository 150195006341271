import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";

export const getAllHotelBookings = createAsyncThunk(
  "HotelVoucher/FindAllHotelVoucher",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelVoucher/FindAllHotelVoucher`,
        {
          Limit: 10,
          Skip: 0,
          Name: "",
          Branch: "",
          HotelBookingNo: "",
          TourBookingNo: "",
          TourDateCode: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Bookings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createHotelBooking = createAsyncThunk(
  "HotelVoucher/InsertHotelVoucher",
  async (HotelBookingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelVoucher/InsertHotelVoucher`,
        HotelBookingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Hotel booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateHotelBooking = createAsyncThunk(
  "HotelVoucher/UpdateHotelVoucher",
  async (HotelBookingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelVoucher/UpdateHotelVoucher`,
        HotelBookingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Hotel booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelBookingByID = createAsyncThunk(
  "HotelVoucher/FindByIDHotelVoucher",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelVoucher/FindByIDHotelVoucher`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        HotelBookingNo: result.HotelBookingNo,
        TourBookingNo: result.TourBookingNo,
        SectorID: result.SectorID,
        SectorName: result.SectorName,
        CompanyID: result.CompanyID,
        CompanyName: result.CompanyName,
        TourID: result.TourID,
        TourName: result.TourName,
        TourDateCode: result.TourDateCode,
        TotalNoOfRooms: result.TotalNoOfRooms,
        NoOfNights: result.NoOfNights,
        NoOfDays: result.NoOfDays,
        TourDate: result.TourDate ? moment(result.TourDate).toDate() : "",
        TotalAdults: result.TotalAdults,
        TotalExtraAdults: result.TotalExtraAdults,
        TotalCWB: result.TotalCWB,
        TotalCNB: result.TotalCNB,
        TotalInfants: result.TotalInfants,
        Total: result.Total,
        VehicleSharingPaxID: result.VehicleSharingPaxID,
        VehicleSharingPax: result.VehicleSharingPax,
        BookByName: result.BookByName,
        BookBy: result.BookBy,
        Remarks: result.Remarks,
        RoomTypeID: result.RoomTypeID,
        OldHotelVoucherNo: result.OldHotelVoucherNo,
        Branch: result.Branch,
        Name: result.Name,
        PickupPlace: result.PickupPlace,
        DropPlace: result.DropPlace,
        VendorAmount: result.VendorAmount,
        HotelDetails: result.HotelDetails
          ? result.HotelDetails.map((places: any) => {
              return {
                ID: places.ID,
                HotelPNRNo: places.HotelPNRNo,
                HotelBookingID: places.HotelBookingID,
                CityID: places.CityID,
                HotelID: places.HotelID,
                City: places.City,
                Hotel: places.Hotel,
                SupplierID: places.SupplierID,
                CheckInDate: places.CheckInDate
                  ? moment(places.CheckInDate).toDate()
                  : "",
                NoOfNights: places.NoOfNights,
                Supplier: places.Supplier,
                MealPlan: places.MealPlan,
                CheckOutDate: places.CheckOutDate
                  ? moment(places.CheckOutDate).toDate()
                  : "",
                MealPlanID: places.MealPlanID,
                ExtraBed: places.ExtraBed || 0,
                PickupFrom: places.PickupFrom,
                PickupTime: places.PickupTime,
                TravelBy: places.TravelBy,
                Remarks: places.Remarks,
                RoomCategoryID: places.RoomCategoryID,
                RoomCategory: places.RoomCategory,
                TotalNoOfRooms: places.TotalNoOfRooms,
                HotelVoucherImage: places.HotelVoucherImage,
              };
            })
          : [],
        RoomPaxes: result.RoomPaxes
          ? result.RoomPaxes.map((roompax: any) => {
              return {
                RoomNo: roompax.RoomNo,
                PaxTypeID: roompax.PaxTypeID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID,
                        TourBookingID: touristpax.TourBookingID,
                        TourID: touristpax.TourID,
                        FirstName: touristpax.FirstName,
                        LastName: touristpax.LastName,
                        MobileNo: touristpax.MobileNo,
                        RoomNo: touristpax.RoomNo,
                        PaxType: touristpax.PaxType,
                        MultiBookingRoomNo: touristpax.MultiBookingRoomNo,
                        FoodOptionID: touristpax.FoodOptionID,
                        FoodOption: touristpax.FoodOption,
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Hotel booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingView = createAsyncThunk(
  "HotelVoucher/TourBookingView",
  async (data: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/HotelVoucher/TourBookingView`,
        data
      );
      const result = response.data?.Data;
      return {
        ID: result.ID,
        HotelBookingNo: result.HotelBookingNo,
        TourBookingNo: result.TourBookingNo,
        SectorID: result.SectorID,
        SectorName: result.SectorName,
        CompanyID: result.CompanyID,
        CompanyName: result.CompanyName,
        TourID: result.TourID,
        Tour: result.Tour,
        TourName: result.TourName,
        TourDateCode: result.TourDateCode,
        TotalNoOfRooms: result.TotalNoOfRooms,
        NoOfNights: result.NoOfNights,
        NoOfDays: result.NoOfDays,
        TourDate: result.TourDate ? moment(result.TourDate).toDate() : "",
        TotalAdults: result.TotalAdults,
        TotalExtraAdults: result.TotalExtraAdults,
        TotalCWB: result.TotalCWB,
        TotalCNB: result.TotalCNB,
        TotalInfants: result.TotalInfants,
        Total: result.Total,
        VehicleSharingPaxID: result.VehicleSharingPaxID,
        VehicleSharingPax: result.VehicleSharingPax,
        BookByName: result.BookByName,
        BookBy: result.BookBy,
        Remarks: result.Remarks,
        RoomTypeID: result.RoomTypeID,
        RoomType: result.RoomType,
        OldHotelVoucherNo: result.OldHotelVoucherNo,
        Branch: result.Branch,
        Name: result.Name,
        PickupPlace: result.PickupPlace,
        DropPlace: result.DropPlace,
        VendorAmount: result.VendorAmount,
        HotelDetails: result.HotelDetails
          ? result.HotelDetails.map((places: any) => {
              return {
                ID: places.ID,
                HotelPNRNo: places.HotelPNRNo,
                HotelBookingID: places.HotelBookingID,
                CityID: places.CityID,
                HotelID: places.HotelID,
                City: places.City,
                Hotel: places.Hotel,
                SupplierID: places.SupplierID,
                CheckInDate: places.CheckInDate
                  ? moment(places.CheckInDate).toDate()
                  : "",
                NoOfNights: places.NoOfNights,
                Supplier: places.Supplier,
                MealPlan: places.MealPlan,
                CheckOutDate: places.CheckOutDate
                  ? moment(places.CheckOutDate).toDate()
                  : "",
                MealPlanID: places.MealPlanID,
                ExtraBed: places.ExtraBed || 0,
                PickupFrom: places.PickupFrom,
                PickupTime: places.PickupTime,
                TravelBy: places.TravelBy,
                Remarks: places.Remarks,
                RoomCategoryID: places.RoomCategoryID,
                RoomCategory: places.RoomCategory,
                TotalNoOfRooms: places.TotalNoOfRooms,
                HotelVoucherImage: places.HotelVoucherImage,
                HotelList: places.HotelList
                  ? places.HotelList.map((hotel: any) => {
                      return {
                        ID: hotel.ID,
                        HotelName: hotel.HotelName,
                        CityID: hotel.CityID,
                        IsDefault: hotel.IsDefault,
                        VendorID: hotel.VendorID,
                      };
                    })
                  : [],
              };
            })
          : [],
        RoomPaxes: result.RoomPaxes
          ? result.RoomPaxes.map((roompax: any) => {
              return {
                RoomNo: roompax.RoomNo,
                PaxTypeID: roompax.PaxTypeID,
                TouristPaxInfo: roompax.TouristPaxInfo
                  ? roompax.TouristPaxInfo.map((touristpax: any) => {
                      return {
                        ID: touristpax.ID,
                        TourBookingID: touristpax.TourBookingID,
                        TourID: touristpax.TourID,
                        FirstName: touristpax.FirstName,
                        LastName: touristpax.LastName,
                        MobileNo: touristpax.MobileNo,
                        RoomNo: touristpax.RoomNo,
                        PaxType: touristpax.PaxType,
                        MultiBookingRoomNo: touristpax.MultiBookingRoomNo,
                        FoodOptionID: touristpax.FoodOptionID,
                        FoodOption: touristpax.FoodOption,
                      };
                    })
                  : [],
              };
            })
          : [],
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Booking View:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
