import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveBranch,
  createBranch,
  deleteBranch,
  getAllActiveBranches,
  getAllBranches,
  getBranchByID,
  updateBranch,
} from "./services/branch.services";
import { IBranch, IBranchState } from "./branchModel";

const initialState: IBranchState = {
  loading: false,
  error: "",
  BranchID: null,
  BranchList: [],
  BranchDetail: {
    CompanyID: null,
    BranchName: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    Pincode: "",
    BranchHead: "",
    MobileNo: "",
    EmailID: "",
    IsActive: 1,
  },
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    clearBranchDetails: (state) => {
      state.BranchDetail = initialState.BranchDetail;
    },
    setBranchID: (state, action) => {
      state.BranchID = action.payload;
    },
    clearBranchID: (state) => {
      state.BranchID = initialState.BranchID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBranches.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllBranches.fulfilled,
      (state, action: PayloadAction<IBranch[]>) => {
        state.loading = false;
        state.BranchList = action.payload || [];
      }
    );
    builder.addCase(getAllBranches.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BranchList = [];
    });
    builder.addCase(createBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createBranch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateBranch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteBranch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getBranchByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getBranchByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.BranchDetail = action.payload;
    });
    builder.addCase(getBranchByID.rejected, (state, action) => {
      state.loading = false;
      state.BranchDetail = initialState.BranchDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveBranches.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveBranches.fulfilled,
      (state, action: PayloadAction<IBranch[]>) => {
        state.loading = false;
        state.BranchList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveBranches.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.BranchList = [];
    });
    builder.addCase(ActiveInactiveBranch.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveBranch.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveBranch.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearBranchDetails, clearBranchID, setBranchID } =
  branchSlice.actions;
export default branchSlice.reducer;
