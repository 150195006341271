import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAirport, IAirportInitialState } from "./airportModel";
import {
  ActiveInactiveAirport,
  createAirport,
  deleteAirport,
  getAirportByID,
  getAllActiveAirports,
  getAllAirports,
  updateAirport,
} from "./services/airport.services";

const initialState: IAirportInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  AirportList: [],
  AirportID: null,
  AirportDetail: {
    AirportName: "",
    AirportCode: "",
    IsActive: false,
  },
};

const airportSlice = createSlice({
  name: "airport",
  initialState,
  reducers: {
    clearAirportDetails: (state) => {
      state.AirportDetail = initialState.AirportDetail;
    },
    setAirportID: (state, action) => {
      state.AirportID = action.payload;
    },
    clearAirportID: (state) => {
      state.AirportID = initialState.AirportID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAirports.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllAirports.fulfilled,
      (state, action: PayloadAction<IAirport[]>) => {
        state.loading = false;
        state.AirportList = action.payload || [];
      }
    );
    builder.addCase(getAllAirports.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirportList = [];
    });
    builder.addCase(createAirport.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createAirport.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createAirport.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAirport.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateAirport.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateAirport.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAirport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteAirport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAirport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirportByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getAirportByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.AirportDetail = action.payload;
    });
    builder.addCase(getAirportByID.rejected, (state, action) => {
      state.formLoading = false;
      state.AirportDetail = initialState.AirportDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveAirports.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveAirports.fulfilled,
      (state, action: PayloadAction<IAirport[]>) => {
        state.loading = false;
        state.AirportList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveAirports.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirportList = [];
    });
    builder.addCase(ActiveInactiveAirport.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveAirport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveAirport.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearAirportDetails, clearAirportID, setAirportID } =
  airportSlice.actions;
export default airportSlice.reducer;
