import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useParams } from "react-router-dom";
import { getPaymentReceiptCustomerView } from "../services/reports.services";
import { MdCurrencyRupee } from "react-icons/md";
import Logo from "../../../assets/Images/logo.png";

const PaymentReceiptCustomerReport: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const ReceiptNo = params?.ReceiptNo;
  const PaymentReceiptCustomerDetails = useAppSelector(
    (state) => state.reports.PaymentReceiptCustomerDetails
  );

  useEffect(() => {
    if (ReceiptNo) {
      dispatch(getPaymentReceiptCustomerView(ReceiptNo.toString()));
    }
  }, [ReceiptNo]);

  function convertToIndianWords(number: any) {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];
    let words = "";
    if (number === 0) {
      return "Zero";
    }
    if (Math.floor(number / 10000000) > 0) {
      words += convertToIndianWords(Math.floor(number / 10000000)) + " Crore ";
      number %= 10000000;
    }
    if (Math.floor(number / 100000) > 0) {
      words += convertToIndianWords(Math.floor(number / 100000)) + " Lakh ";
      number %= 100000;
    }
    if (Math.floor(number / 1000) > 0) {
      words += convertToIndianWords(Math.floor(number / 1000)) + " Thousand ";
      number %= 1000;
    }
    if (Math.floor(number / 100) > 0) {
      words += convertToIndianWords(Math.floor(number / 100)) + " Hundred ";
      number %= 100;
    }
    if (number > 0) {
      if (words !== "") {
        words += "and ";
      }
      if (number < 10) {
        words += ones[number];
      } else if (number < 20) {
        words += teens[number - 10];
      } else {
        words += tens[Math.floor(number / 10)];
        if (number % 10 > 0) {
          words += " " + ones[number % 10];
        }
      }
    }
    return words;
  }

  return (
    <>
      {PaymentReceiptCustomerDetails && (
        <div className="container-fluid p-5" style={{ fontSize: 12 }}>
          <div className="row">
            <div className="col-2 d-flex align-items-center">
              <img src={Logo} alt="" width="100%" />
            </div>
            <div className="col-7" style={{ borderLeft: "1px solid black" }}>
              <h5 className="fw-600">RADHE KRISHNA TOURISM</h5>
              <p>
                11 PODIUM FLOOR, CITY CENTER, NR,SWASTIK CROSS ROAD, CG ROAD,
                NAVRANGPURA AHMEDABAD-300009, Ahmedabad (380009)
                <br />
                State: Gujarat (State Code: 24), Country: India.
              </p>
              <div className="d-flex" style={{ gap: 100 }}>
                <span>
                  <span className="fw-600">(P)</span> +91 94262 55522
                </span>
                <span>
                  <span className="fw-600">(M)</span> +91 78785 55656
                </span>
              </div>
              <div>
                <span className="fw-600">(Email)</span>{" "}
                radhekrishnatourism@yahoo.com
              </div>
            </div>
            <div
              className="col-3"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <span>
                <span className="fw-600">(PAN)</span> AAHCR8625E
              </span>
              <span>
                <span className="fw-600">(GSTIn)</span> 24AAHCR8625E1ZD
              </span>
            </div>
          </div>
          <div
            className="row mt-2 pt-2 pb-2"
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="col-8 p-3 ps-0">
              <p>Received with thanks,</p>
              <span style={{ fontSize: 13, fontWeight: 700 }}>
                {PaymentReceiptCustomerDetails?.GuestName}
                {PaymentReceiptCustomerDetails?.TourName && " + "}
                {PaymentReceiptCustomerDetails?.TourName}
              </span>
            </div>
            <div className="col-4 p-3 " style={{ background: "lightgray" }}>
              <p className="text-center fw-600">
                {PaymentReceiptCustomerDetails?.PaymentType === "CASH"
                  ? "Cash Receipt"
                  : "Bank Receipt"}
              </p>
              <div className="d-flex justify-content-between">
                <span>
                  {PaymentReceiptCustomerDetails?.TransactionNo ||
                    PaymentReceiptCustomerDetails?.ChequeNo ||
                    ""}
                </span>
                <span>{PaymentReceiptCustomerDetails?.PaymentDate}</span>
              </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-12 ps-0">
              {PaymentReceiptCustomerDetails?.PaymentType === "CREDIT CARD" ||
              PaymentReceiptCustomerDetails?.PaymentType === "NEFT / IMPS" ||
              PaymentReceiptCustomerDetails?.PaymentType === "CHEQUE" ? (
                <p>
                  Bank Name : {PaymentReceiptCustomerDetails?.BankName}
                  {PaymentReceiptCustomerDetails?.ChequeNo && " - "}
                  {PaymentReceiptCustomerDetails?.ChequeNo}
                </p>
              ) : (
                ""
              )}
              <p>
                The sum of{" "}
                {convertToIndianWords(
                  PaymentReceiptCustomerDetails?.Amount
                    ? PaymentReceiptCustomerDetails?.Amount
                    : 0
                )}{" "}
                Rupees
              </p>
              <p>By Reference Number:</p>
              <span style={{ paddingLeft: 100 }}>
                {PaymentReceiptCustomerDetails?.PaymentDate}
              </span>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-5 p-2 fw-600 text-start"
              style={{
                background: "lightgray",
                borderRight: "1px solid white",
              }}
            >
              Particular
            </div>
            <div
              className="col-2 p-2 fw-600"
              style={{
                background: "lightgray",
                borderRight: "1px solid white",
              }}
            >
              PAN
            </div>
            <div
              className="col-2 p-2 fw-600"
              style={{
                background: "lightgray",
                borderRight: "1px solid white",
              }}
            >
              Reference
            </div>
            <div
              className="col-2 p-2 fw-600"
              style={{
                background: "lightgray",
                borderRight: "1px solid white",
              }}
            >
              Amount
            </div>
            <div
              className="col-1 p-2"
              style={{ background: "lightgray" }}
            ></div>
          </div>
          <div
            className="row text-center"
            style={{ borderBottom: "1px dashed black" }}
          >
            <div className="col-5 p-2 fw-600 text-start">
              {" "}
              {PaymentReceiptCustomerDetails?.GuestName}
              {PaymentReceiptCustomerDetails?.TourName && " + "}
              {PaymentReceiptCustomerDetails?.TourName}
            </div>
            <div className="col-2 p-2 fw-600">
              {" "}
              {PaymentReceiptCustomerDetails?.PANNo}
            </div>
            <div className="col-2 p-2 fw-600">-</div>
            <div className="col-2 p-2 fw-600">
              {" "}
              {PaymentReceiptCustomerDetails?.Amount}
            </div>
            <div className="col-1 p-2 fw-600">Credit</div>
          </div>
          <div
            className="row mt-5 pt-2 pb-2"
            style={{
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <div className="col-8 p-2 ps-0">
              <span className="fw-600">
                {PaymentReceiptCustomerDetails?.PaymentType}
                {PaymentReceiptCustomerDetails?.TransactionNo && "/"}
                {PaymentReceiptCustomerDetails?.TransactionNo}
                {PaymentReceiptCustomerDetails?.ChequeNo && "/"}
                {PaymentReceiptCustomerDetails?.ChequeNo}
                {PaymentReceiptCustomerDetails?.GuestName && "/"}
                {PaymentReceiptCustomerDetails?.GuestName}
              </span>
            </div>
            <div className="col-4 p-0 ">
              <p
                className="d-flex justify-content-center align-items-center mb-3 p-2"
                style={{ background: "lightgray" }}
              >
                Amount in <MdCurrencyRupee />
              </p>
              <p
                className="d-flex justify-content-center align-items-center mb-0 p-2 fw-bold"
                style={{ background: "lightgray" }}
              >
                <MdCurrencyRupee /> {PaymentReceiptCustomerDetails?.Amount} /-
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-8 p-0">
              <p className="fw-600">Terms :</p>
              <p>
                ◙ Subject to Ahmedabad jurisdiction. <br />
                ◙ Subject to realisation of Cheque. <br />
                ◙ Without original receipt no refund is permissible. <br />◙
                Kindly check all details carefully to avoid unneccessary
                complications.
              </p>
            </div>
            <div className="col-4 p-0 text-end">
              <span className="fw-600">for RADHE KRISHNA TOURISM</span>
            </div>
          </div>
          <div className="row mt-5" style={{ borderBottom: "1px solid black" }}>
            <div className="col-4 p-0">Receiver's Signature</div>
            <div className="col-4 text-center">
              (Prepared By : {PaymentReceiptCustomerDetails?.BookByName})
            </div>
            <div className="col-4 p-0 text-end">Authorized Signatory</div>
          </div>
          <div className="row ">
            <div className="col-3 p-0">
              (Prepared By : {PaymentReceiptCustomerDetails?.BookByName})
            </div>
            <div className="col-6 text-center" style={{ fontSize: 10 }}>
              This is a Computer generated document and does not require any
              signature
            </div>
            <div className="col-3 p-0 text-end"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentReceiptCustomerReport;
