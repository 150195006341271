import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import { getAllTotalPendingPayments } from "../services/dashboard.services";
import { useLocation } from "react-router-dom";

const TotalPendingPayment: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const filter = location?.state?.filter;
  useEffect(() => {
    dispatch(getAllTotalPendingPayments(filter));
  }, []);

  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Total Pending Payment List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <TotalPendingPaymentGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TotalPendingPaymentGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const TotalPendingPaymentList = useAppSelector(
    (state) => state.dashboard.TotalPendingPaymentList
  );
  const loading = useAppSelector((state) => state.dashboard.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All"
        ? TotalPendingPaymentList.length
        : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TotalPendingPaymentList && TotalPendingPaymentList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(TotalPendingPaymentList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : TotalPendingPaymentList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={TotalPendingPaymentList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="TourBookingNo" title="Tour Booking No" />
          <Column field="MobileNo" title="Mobile No" />
          <Column field="Sector" title="Sector" />
          <Column field="TravelType" title="Travel Type" />
          <Column field="Tour" title="Tour" />
          <Column field="TourDateCode" title="Tour Date Code" />
          <Column field="TourStartDate" title="Tour Start Date" />
          <Column field="Name" title="Name" />
          <Column field="NoOfNights" title="No Of Nights" />
          <Column field="TotalAmount" title="Total Amount" />
          <Column field="PendingAmount" title="Pending Amount" />
          <Column field="BookBy" title="Book By" />
          {/* <Column field="Branch" title="Branch" /> */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};
export default TotalPendingPayment;
