import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import "./Layout.scss";
import MainHeader from "./Header";
import { Layout as AntLayout, theme } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import IconButton from "../common/IconButton";
import UserProfile from "./UserProfile";

const Layout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { Header, Content } = AntLayout;

  const [collapsed, setCollapsed] = useState(false);

  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      <MainHeader collapsed={collapsed} setCollapsed={setCollapsed} />
      <AntLayout
        style={{
          overflow: "auto",
          height: "100vh",
        }}
      >
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "sticky",
            top: 0,
            right: 0,
            zIndex: 10,
          }}
        >
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            style={{
              fontSize: "26px",
              width: 64,
              height: 64,
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </IconButton>
          <UserProfile />
        </Header>
        <Content
          style={{
            margin: "16px",
          }}
        >
          <Outlet />
        </Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
