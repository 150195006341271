import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompany, ICompanyInitialState } from "./companyModel";
import {
  ActiveInactiveCompany,
  createCompany,
  deleteCompany,
  getAllActiveCompanies,
  getAllCompanies,
  getCompanyByID,
  updateCompany,
} from "./services/company.services";

const initialState: ICompanyInitialState = {
  loading: false,
  error: "",
  CompanyList: [],
  CompanyID: null,
  CompanyDetail: {
    CompanyName: "",
    Address: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    Pincode: "",
    OwnerName: "",
    MobileNo: "",
    EmailID: "",
    PANNo: "",
    GSTNo: "",
    AadharNo: "",
    IsActive: false,
  },
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    clearCompanyDetails: (state) => {
      state.CompanyDetail = initialState.CompanyDetail;
    },
    setCompanyID: (state, action) => {
      state.CompanyID = action.payload;
    },
    clearCompanyID: (state) => {
      state.CompanyID = initialState.CompanyID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCompanies.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllCompanies.fulfilled,
      (state, action: PayloadAction<ICompany[]>) => {
        state.loading = false;
        state.CompanyList = action.payload || [];
      }
    );
    builder.addCase(getAllCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyList = [];
    });
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createCompany.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(createCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateCompany.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCompanyByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCompanyByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.CompanyDetail = action.payload;
    });
    builder.addCase(getCompanyByID.rejected, (state, action) => {
      state.loading = false;
      state.CompanyDetail = initialState.CompanyDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveCompanies.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveCompanies.fulfilled,
      (state, action: PayloadAction<ICompany[]>) => {
        state.loading = false;
        state.CompanyList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveCompanies.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.CompanyList = [];
    });
    builder.addCase(ActiveInactiveCompany.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveCompany.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearCompanyDetails, clearCompanyID, setCompanyID } =
  companySlice.actions;
export default companySlice.reducer;
