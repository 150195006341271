import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IHotelBooking, IHotelBookingState } from "./hotelVoucherModel";
import {
  createHotelBooking,
  getAllHotelBookings,
  getHotelBookingByID,
  getTourBookingView,
  updateHotelBooking,
} from "./services/hotelVoucher.services";

const initialState: IHotelBookingState = {
  loading: false,
  error: "",
  deleteIndex: -1,
  innerArrayDeleteIndex: -1,
  HotelBookingID: null,
  HotelBookingList: [],
  HotelBookingDetail: {
    ID: null,
    SectorID: null,
    Sector: "",
    AlongWith: null,
    TourID: null,
    TourDateCode: "",
    TourBookingNo: "",
    HotelBookingNo: null,
    Tour: "",
    CompanyID: null,
    Company: "",
    TourDate: "",
    NoOfNights: null,
    TotalAdults: null,
    TotalExtraAdults: null,
    TotalCWB: null,
    TotalCNB: null,
    TotalInfants: null,
    TotalNoOfPax: null,
    RoomTypeID: null,
    TotalNoOfRooms: null,
    PickupPlace: "",
    DropPlace: "",
    ExtraBed: null,
    RoomPaxes: [],
    HotelDetails: [],
  },
  // HotelVoucherTourBookingDetails: {},
};

const hotelBookingSlice = createSlice({
  name: "hotelVoucher",
  initialState,
  reducers: {
    clearHotelBookingDetails: (state) => {
      state.HotelBookingDetail = initialState.HotelBookingDetail;
    },
    setHotelBookingID: (state, action) => {
      state.HotelBookingID = action.payload;
    },
    clearHotelBookingID: (state) => {
      state.HotelBookingID = initialState.HotelBookingID;
    },
    setHotelDetailsDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
    setInnerArrayDeleteIndex: (state, action) => {
      state.innerArrayDeleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHotelBookings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllHotelBookings.fulfilled,
      (state, action: PayloadAction<IHotelBooking[]>) => {
        state.loading = false;
        state.HotelBookingList = action.payload || [];
      }
    );
    builder.addCase(getAllHotelBookings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelBookingList = [];
    });
    builder.addCase(createHotelBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createHotelBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createHotelBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateHotelBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateHotelBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateHotelBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    // builder.addCase(deleteHotelBooking.pending, (state) => {
    //   state.loading = true;
    //   state.error = "";
    // });
    // builder.addCase(deleteHotelBooking.fulfilled, (state) => {
    //   state.loading = false;
    // });
    // builder.addCase(deleteHotelBooking.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message || "Something went wrong";
    // });
    //     builder.addCase(ActiveInactiveHotelBooking.pending, (state) => {
    //       state.loading = true;
    //       state.error = "";
    //     });
    //     builder.addCase(ActiveInactiveHotelBooking.fulfilled, (state) => {
    //       state.loading = false;
    //     });
    //     builder.addCase(ActiveInactiveHotelBooking.rejected, (state, action) => {
    //       state.loading = false;
    //       state.error = action.error.message || "Something went wrong";
    //     });
    builder.addCase(getHotelBookingByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getHotelBookingByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.HotelBookingDetail = action.payload;
    });
    builder.addCase(getHotelBookingByID.rejected, (state, action) => {
      state.loading = false;
      state.HotelBookingDetail = initialState.HotelBookingDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getTourBookingView.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourBookingView.fulfilled, (state, action: any) => {
      state.loading = false;
      state.HotelBookingDetail = action.payload;
    });
    builder.addCase(getTourBookingView.rejected, (state, action) => {
      state.loading = false;
      state.HotelBookingDetail = initialState.HotelBookingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearHotelBookingID,
  setHotelBookingID,
  clearHotelBookingDetails,
  setHotelDetailsDeleteIndex,
  setInnerArrayDeleteIndex,
} = hotelBookingSlice.actions;
export default hotelBookingSlice.reducer;
