import React, { useCallback, useEffect } from "react";
import {
  GridLayout,
  GridLayoutItem,
  Stepper,
  StepperChangeEvent,
} from "@progress/kendo-react-layout";
import TourInformation from "./tourforms/TourInformation";
import TourDates from "./tourforms/TourDates";
import TourPlaces from "./tourforms/TourPlaces";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearActiveStep, setActiveStep } from "./tourSlice";
import TourRates from "./tourforms/TourRates";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

type ActiveStepProps = {
  step: number;
};

const steps = [
  { label: "Tour Information", isValid: undefined },
  { label: "Tour Date", isValid: undefined },
  { label: "Tour Places", isValid: undefined },
  { label: "Tour Rates", isValid: undefined },
];

const CreateTour: React.FC = () => {
  const dispatch = useAppDispatch();
  const ActiveStep = useAppSelector((state) => state.tour.ActiveStep);
  const location = useLocation();

  useEffect(() => {
    return () => {
      dispatch(clearActiveStep());
    };
  }, []);

  const renderActiveStep: React.FC<ActiveStepProps> = useCallback(
    ({ step }) => {
      switch (step) {
        case 0:
          return <TourInformation />;
        case 1:
          return <TourDates />;
        case 2:
          return <TourPlaces />;
        case 3:
          return <TourRates />;
        default:
          return <TourInformation />;
      }
    },
    [ActiveStep]
  );

  const handleChange = (e: StepperChangeEvent) => {
    if (location.pathname !== "/tour/create" || e?.value === 0) {
      dispatch(setActiveStep(e.value));
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      <GridLayoutItem
        style={{
          padding: "15px 0",
        }}
      >
        <Stepper value={ActiveStep} onChange={handleChange} items={steps} />
      </GridLayoutItem>
      <GridLayoutItem>{renderActiveStep({ step: ActiveStep })}</GridLayoutItem>
    </GridLayout>
  );
};

export default CreateTour;
