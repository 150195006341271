import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { MdDelete } from "react-icons/md";
import IconButton from "../../components/common/IconButton";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { setGroupRouteInfoDeleteIndex } from "./groupRouteSlice";
import {
  getAllActiveRoutes,
  getRouteByID,
} from "../route/services/route.services";
import { IRoute } from "../route/routeModel";
import { ISectorCity } from "../city/cityModel";
import FormTextArea from "../../components/formFields/FormTextArea";

const GroupRouteInfoArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();

  const name = fieldArrayRenderProps.name || "";

  const InfoDeleteIndex = useAppSelector(
    (state) => state.groupRoute.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);
  const RouteList = useAppSelector((state) => state.route.RouteList);

  useEffect(() => {
    dispatch(getAllActiveRoutes());

    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("grouprouteinfoDeleteDialog"));
    dispatch(setGroupRouteInfoDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setGroupRouteInfoDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        DayNo: null,
        RouteID: null,
        CityID: null,
        Description: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleRouteChange = async (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e?.value) {
      const res = await dispatch(getRouteByID(e?.value));
      const RouteDetail = res?.payload as any;
      fieldArrayRenderProps?.formRenderProps?.onChange(
        `${name}.${index}.Description`,
        {
          value: RouteDetail?.Description || "",
        }
      );
    }
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {fieldArrayRenderProps.value && fieldArrayRenderProps.value.length === 0
        ? pushElementInarray()
        : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Group Route Info
        </Button>
      </GridLayoutItem>
      {dialogName === "grouprouteinfoDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Group Route Info"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(InfoDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (GroupRouteInfo: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "10%" },
                    { width: "42.495%" },
                    { width: "42.495%" },
                    { width: "5%" },
                  ]}
                >
                  <GridLayoutItem>
                    <div className="grouprouteinfo-label">Day {index + 1}</div>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${index}.RouteID`}
                      label="Route"
                      component={FormSelectionField}
                      onChange={(e) =>
                        handleRouteChange(e, index, fieldArrayRenderProps)
                      }
                      options={RouteList?.map((route: IRoute) => {
                        return {
                          value: route?.ID,
                          label: route?.RouteName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${index}.CityID`}
                      label="City"
                      component={FormSelectionField}
                      options={SectorCityList?.map((city: ISectorCity) => {
                        return {
                          value: city?.ID,
                          label: city?.CityName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "right",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      size={"small"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        position: "absolute",
                        top: 52,
                        right: 0,
                        height: 24,
                        width: 24,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "16px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={3}>
                    <Field
                      name={`${name}.${index}.Description`}
                      label="Description"
                      component={FormTextArea}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default GroupRouteInfoArray;
