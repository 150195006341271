import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  deleteAirlineBlock,
  getAllAirlineBlocks,
} from "./services/airlineblock.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
// import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { checkAcessRights } from "../../_helper/helper";
import {
  DELETE_ACCESS,
  EDIT_ACCESS,
  INSERT_ACCESS,
} from "../../_contstants/common";
import { clearAirlineBlockID, setAirlineBlockID } from "./airlineblockSlice";
// import { Tooltip } from "@progress/kendo-react-tooltip";

const AirlineBlock = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  const handleAirlineBlockCreate = () => {
    navigate("/airlineblock/create");
  };
  useEffect(() => {
    dispatch(getAllAirlineBlocks());
    dispatch(closeDialog());
  }, []);
  return (
    <>
      <DeleteAirlineBlockDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Airline Block List
          </Typography.h4>
          {isAddAccess && (
            <Button
              onClick={handleAirlineBlockCreate}
              fillMode={"solid"}
              themeColor={"primary"}
            >
              Create Airline Block
            </Button>
          )}
        </GridLayoutItem>
        <GridLayoutItem>
          <AirlineBlockGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const AirlineBlockGridComponent: React.FC = () => {
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const AirlineBlockList = useAppSelector(
    (state) => state.airlineblock.AirlineBlockList
  );
  const loading = useAppSelector((state) => state.airlineblock.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? AirlineBlockList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
    const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

    const handleEditAirlineBlock = (ID: number) => {
      navigate("/airlineblock/edit", { state: { AirlineBlockID: ID } });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteAirlineBlock"));
      dispatch(setAirlineBlockID(ID));
    };
    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() => handleEditAirlineBlock(props.dataItem.ID)}
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );
  // const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
  //   const [IsActive, setIsActive] = useState<boolean>(props.dataItem.IsActive);

  //   const handleActiveInActive = () => {
  //     setIsActive(!IsActive);
  //     const data = {
  //       ID: props.dataItem.ID,
  //       IsActive: !IsActive,
  //     };
  //     dispatch(activeInActiveUsers(data)).then((response: any) => {
  //       if (response.payload?.data?.Status === 200) {
  //         dispatch(getAllAirlineBlocks());
  //       } else {
  //         dispatch(getAllAirlineBlocks());
  //       }
  //     });
  //   };

  //   return (
  //     <td style={{ textAlign: "center" }}>
  //       <Tooltip anchorElement="target" position="top" parentTitle={true}>
  //         <div title={`Set ${IsActive ? "Inactive" : "Active"}.`}>
  //           <Switch
  //             name="IsActive"
  //             checked={IsActive}
  //             onChange={handleActiveInActive}
  //             size="small"
  //           />
  //         </div>
  //       </Tooltip>
  //     </td>
  //   );
  // };

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {AirlineBlockList && AirlineBlockList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(AirlineBlockList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : AirlineBlockList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={AirlineBlockList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="DateOfBooking" title="Booking Date" />
          <Column field="Title" title="Airline Block" />
          <Column field="FlightNo" title="Flight No" />
          <Column field="DepartureAirport" title="Departure Airport" />
          <Column field="VendorName" title="Vendor Name" />
          <Column field="TotalNoOfSeats" title="Total Seats" />
          {/* <Column
            field="ID"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          /> */}
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteAirlineBlockDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const AirlineBlockID = useAppSelector(
    (state) => state.airlineblock.AirlineBlockID
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteAirlineBlock(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllAirlineBlocks());
        dispatch(closeDialog());
        dispatch(clearAirlineBlockID());
      } else {
        dispatch(getAllAirlineBlocks());
        dispatch(closeDialog());
        dispatch(clearAirlineBlockID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteAirlineBlock" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Airline Block"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Airline Block?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(AirlineBlockID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default AirlineBlock;
