import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ICompany } from "../companyModel";

export const getAllCompanies = createAsyncThunk(
  "Company/FindAllCompany",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/FindAllCompany`,
        {
          CompanyName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Companies:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createCompany = createAsyncThunk(
  "Company/InsertCompany",
  async (CompanyData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/InsertCompany`,
        CompanyData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateCompany = createAsyncThunk(
  "Company/UpdateCompany",
  async (CompanyData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/UpdateCompany`,
        CompanyData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "Company/DeleteCompany",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/DeleteCompany`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getCompanyByID = createAsyncThunk(
  "Company/FindByIDCompany",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/FindByIDCompany`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        CompanyName: result.CompanyName,
        Address: result.Address,
        CityID: result.CityID,
        CityName: result.CityName,
        StateID: result.StateID,
        StateName: result.StateName,
        CountryID: result.CountryID,
        CountryName: result.CountryName,
        Pincode: result.Pincode,
        OwnerName: result.OwnerName,
        MobileNo: result.MobileNo,
        EmailID: result.EmailID,
        PANNo: result.PANNo,
        GSTNo: result.GSTNo,
        AadharNo: result.AadharNo,
        IsActive: result.IsActive,
      } as ICompany;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveCompanies = createAsyncThunk(
  "Company/FindAllActiveCompany",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Company/FindAllActiveCompany`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Companies:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveCompany = createAsyncThunk(
  "Company/ActiveInActiveCompany",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Company/ActiveInActiveCompany`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive company:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
