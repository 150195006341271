import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import FormTextField from "../../../components/formFields/FormTextField";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { GENDER, NAMEPREFIX } from "../../../_contstants/common";
import { getAllActiveFoodOptions } from "../../foodoption/services/foodOption.services";
import { IFoodOption } from "../../foodoption/foodOptionModel";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { getAllActiveNationalities } from "../../nationality/services/nationality.services";
import { INationality } from "../../nationality/nationalityModel";
import moment from "moment";

const TouristPaxRoomArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const FoodOptionList = useAppSelector(
    (state) => state.foodOption.FoodOptionList
  );
  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );

  const name = fieldArrayRenderProps.name || "";

  useEffect(() => {
    dispatch(getAllActiveFoodOptions());
    dispatch(getAllActiveNationalities());
  }, []);

  const handleChangeInital = (e: any, name: string) => {
    if (e?.value === "MR.") {
      fieldArrayRenderProps?.formRenderProps.onChange(name, { value: "Male" });
    } else if (e.value === "MRS.") {
      fieldArrayRenderProps?.formRenderProps.onChange(name, {
        value: "Female",
      });
    }
  };

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        FirstName: "",
        LastName: "",
        Initial: "",
        Gender: "",
        Age: null,
        FoodOptionID: null,
        Amount: null,
        ExtraCost: "",
        DiscountAmount: "",
        MobileNo: "",
        DOB: "",
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (TouristPaxInfo: any, innerIndex: number) => (
            <GridLayoutItem key={innerIndex}>
              <Card className="fieldarray-card">
                <GridLayout
                  style={{ marginRight: 30 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                    { width: "25%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.Initial`}
                      label="Initial"
                      component={FormSelectionField}
                      options={NAMEPREFIX.map((item: any) => {
                        return {
                          value: item.value,
                          label: item.label,
                        };
                      })}
                      onChange={(e) =>
                        handleChangeInital(e, `${name}.${innerIndex}.Gender`)
                      }
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.FirstName`}
                      label="First Name"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.LastName`}
                      label="Last Name"
                      component={FormTextField}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.Gender`}
                      label="Gender"
                      component={FormSelectionField}
                      options={GENDER.map((item: any) => {
                        return {
                          value: item.value,
                          label: item.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.Age`}
                      label="Age"
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.FoodOptionID`}
                      label="Food Option"
                      component={FormSelectionField}
                      options={FoodOptionList?.map((food: IFoodOption) => {
                        return {
                          value: food?.ID,
                          label: food?.Name,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.Amount`}
                      label="Amount"
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.ExtraCost`}
                      label="Extra Cost"
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.DiscountAmount`}
                      label="Discount Amount"
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.MobileNo`}
                      label="Mobile No"
                      type="number"
                      max="10"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.DOB`}
                      label="DOB"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.NationalityID`}
                      label="Nationality"
                      component={FormSelectionField}
                      options={NationalityList?.map(
                        (nationality: INationality) => {
                          return {
                            value: nationality?.ID,
                            label: nationality?.Nationality,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.PassportNo`}
                      label="Passport No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.IssueDate`}
                      label="Issue Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${innerIndex}.ExpiryDate`}
                      label="Expiry Date"
                      format="dd/MM/yyyy"
                      minDate={moment(
                        fieldArrayRenderProps.formRenderProps.valueGetter(
                          `${name}.${innerIndex}.IssueDate`
                        )
                      ).toDate()}
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default TouristPaxRoomArray;
