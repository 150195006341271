import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import { getAllActiveVendors } from "../vendor/services/vendor.services";
import { getAllActiveUsers } from "../user/services/user.services";
import { getAllActiveHotels } from "../hotel/services/hotel.services";
import { IVendor } from "../vendor/vendorModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  Grid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import RouteArray from "./RouteArray";
import FormTextArea from "../../components/formFields/FormTextArea";
import { IUser } from "../user/userModel";
import {
  createRouteVoucher,
  getGroupRouteInfo,
  getMultiTourBookingData,
  getRouteVoucherByID,
  updateRouteVoucher,
} from "./services/routeVoucher.services";
import { ICity } from "../city/cityModel";
import {
  getAllActiveCities,
  getCityBySectorID,
} from "../city/services/city.services";
import { getGroupRouteBySectorID } from "../grouproute/services/groupRoute.services";
import { clearRouteVoucherDetails } from "./routeVoucherSlice";
import moment from "moment";
import { IGroupRoute } from "../grouproute/groupRouteModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getGroupRouteBySectorID(SectorID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("GroupRouteID", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [SectorID]);

  useEffect(() => {
    if (SectorID) {
      dispatch(getCityBySectorID(SectorID));
    }
  }, [SectorID]);

  return null;
};

const CreateRouteVoucher: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const RouteVoucherID = location.state?.RouteVoucherID;
  const RouteBookingID = location.state?.RouteBookingID;
  const TourBookingID = location.state?.TourBookingID;
  const gridRef = useRef<any>(null);

  const [formKey, setFormKey] = useState(1);

  const loading = useAppSelector((state) => state.hotelVoucher.loading);
  const VendorList = useAppSelector((state) => state.vendor.VendorList);
  const UserList = useAppSelector((state) => state.user.UserList);
  const GroupRouteList = useAppSelector(
    (state) => state.groupRoute.GroupRouteList
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const RouteVoucherDetail = useAppSelector(
    (state) => state.routeVoucher.RouteVoucherDetail
  );

  useEffect(() => {
    dispatch(getAllActiveVendors());
    dispatch(getAllActiveUsers());
    dispatch(getAllActiveHotels());
    dispatch(getAllActiveCities());

    return () => {
      dispatch(clearRouteVoucherDetails());
    };
  }, []);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [RouteVoucherDetail]);

  useEffect(() => {
    if (TourBookingID && !RouteBookingID) {
      const payload = {
        RouteBookingID: RouteBookingID || 0,
        TourBookingNo: TourBookingID.toString() || 0,
      };
      dispatch(getMultiTourBookingData(payload));
    }
  }, [TourBookingID]);

  useEffect(() => {
    if (RouteVoucherID) {
      dispatch(getRouteVoucherByID(RouteVoucherID));
    }
  }, [RouteVoucherID]);

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleChangeGroupRoute = async (
    e: any,
    formRenderProps: FormRenderProps
  ) => {
    const res = await dispatch(getGroupRouteInfo(e?.value));
    if (res.meta.requestStatus === "fulfilled") {
      const RouteInfoarray = JSON.parse(JSON.stringify(res.payload));
      formRenderProps.onChange(`RouteInfo`, {
        value: RouteInfoarray,
      });
    }
  };

  const handleSubmit = async (values: any) => {
    if (RouteVoucherID) {
      const editPayload = {
        ID: RouteVoucherID,
        TourBookingNo: RouteVoucherDetail.TourBookingNo
          ? RouteVoucherDetail.TourBookingNo
          : "",
        SectorID: RouteVoucherDetail.SectorID
          ? RouteVoucherDetail.SectorID
          : null,
        TourID: RouteVoucherDetail.TourID ? RouteVoucherDetail.TourID : null,
        TourDateID: RouteVoucherDetail.TourDateID
          ? RouteVoucherDetail.TourDateID
          : null,
        TourDateCode: RouteVoucherDetail.TourDateCode
          ? RouteVoucherDetail.TourDateCode
          : "",
        CompanyID: RouteVoucherDetail.CompanyID
          ? RouteVoucherDetail.CompanyID
          : null,
        VehicleTypeID: RouteVoucherDetail.VehicleTypeID
          ? RouteVoucherDetail.VehicleTypeID
          : null,
        TransporterID: values.TransporterID ? values.TransporterID : null,
        TransporterCityID: values.TransporterCityID
          ? values.TransporterCityID
          : null,
        GroupRouteID: values.GroupRouteID ? values.GroupRouteID : null,
        PickUpDate: values.PickUpDate
          ? moment(values.PickUpDate).format("YYYY-MM-DD")
          : "",
        DropDate: values.DropDate
          ? moment(values.DropDate).format("YYYY-MM-DD")
          : "",
        PickUpFrom: values.PickUpFrom ? values.PickUpFrom : "",
        DropAt: values.DropAt ? values.DropAt : "",
        TotalNoOfDays: values.TotalNoOfDays ? values.TotalNoOfDays : null,
        Remarks: values.Remarks ? values.Remarks : "",
        BookBy: values.BookBy ? values.BookBy : null,
        PickUpFlightNo: values.PickUpFlightNo ? values.PickUpFlightNo : "",
        DropFlightNo: values.DropFlightNo ? values.DropFlightNo : "",
        IsActive: RouteVoucherDetail.IsActive
          ? RouteVoucherDetail.IsActive
          : true,
        RouteInfo: values.RouteInfo
          ? values.RouteInfo.map((routeinfo: any) => {
              return {
                RouteBookingID: routeinfo.RouteBookingID
                  ? routeinfo.RouteBookingID
                  : null,
                DayNo: routeinfo.DayNo ? routeinfo.DayNo : null,
                RouteName: routeinfo.RouteName ? routeinfo.RouteName : "",
                Description: routeinfo.Description ? routeinfo.Description : "",
                CityID: routeinfo.CityID ? routeinfo.CityID : null,
                HotelID: routeinfo.HotelID ? routeinfo.HotelID : null,
                City: routeinfo.City ? routeinfo.City : "",
                HotelName: routeinfo.HotelName ? routeinfo.HotelName : "",
                RouteID: routeinfo.RouteID ? routeinfo.RouteID : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateRouteVoucher(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/routevoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        TourBookingNo: RouteVoucherDetail.TourBookingNo
          ? RouteVoucherDetail.TourBookingNo
          : "",
        SectorID: RouteVoucherDetail.SectorID
          ? RouteVoucherDetail.SectorID
          : null,
        TourID: RouteVoucherDetail.TourID ? RouteVoucherDetail.TourID : null,
        TourDateID: RouteVoucherDetail.TourDateID
          ? RouteVoucherDetail.TourDateID
          : null,
        TourDateCode: RouteVoucherDetail.TourDateCode
          ? RouteVoucherDetail.TourDateCode
          : "",
        CompanyID: RouteVoucherDetail.CompanyID
          ? RouteVoucherDetail.CompanyID
          : null,
        VehicleTypeID: RouteVoucherDetail.VehicleTypeID
          ? RouteVoucherDetail.VehicleTypeID
          : null,
        TransporterID: values.TransporterID ? values.TransporterID : null,
        TransporterCityID: values.TransporterCityID
          ? values.TransporterCityID
          : null,
        GroupRouteID: values.GroupRouteID ? values.GroupRouteID : null,
        PickUpDate: values.PickUpDate
          ? moment(values.PickUpDate).format("YYYY-MM-DD")
          : "",
        DropDate: values.DropDate
          ? moment(values.DropDate).format("YYYY-MM-DD")
          : "",
        PickUpFrom: values.PickUpFrom ? values.PickUpFrom : "",
        DropAt: values.DropAt ? values.DropAt : "",
        TotalNoOfDays: values.TotalNoOfDays ? values.TotalNoOfDays : null,
        Remarks: values.Remarks ? values.Remarks : "",
        BookBy: values.BookBy ? values.BookBy : null,
        PickUpFlightNo: values.PickUpFlightNo ? values.PickUpFlightNo : "",
        DropFlightNo: values.DropFlightNo ? values.DropFlightNo : "",
        IsActive: RouteVoucherDetail.IsActive
          ? RouteVoucherDetail.IsActive
          : true,
        RouteInfo: values.RouteInfo
          ? values.RouteInfo.map((routeinfo: any) => {
              return {
                RouteBookingID: routeinfo.RouteBookingID
                  ? routeinfo.RouteBookingID
                  : null,
                DayNo: routeinfo.DayNo ? routeinfo.DayNo : null,
                RouteName: routeinfo.RouteName ? routeinfo.RouteName : "",
                Description: routeinfo.Description ? routeinfo.Description : "",
                CityID: routeinfo.CityID ? routeinfo.CityID : null,
                HotelID: routeinfo.HotelID ? routeinfo.HotelID : null,
                City: routeinfo.City ? routeinfo.City : "",
                HotelName: routeinfo.HotelName ? routeinfo.HotelName : "",
                RouteID: routeinfo.RouteID ? routeinfo.RouteID : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createRouteVoucher(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={RouteVoucherDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 70 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "16.66%" },
                  { width: "6%" },

                  { width: "10.66%" },
                  { width: "16.66%" },

                  { width: "16.66%" },
                  { width: "6%" },

                  { width: "10.66%" },
                  { width: "16.66%" },
                ]}
              >
                {TourBookingID && !RouteBookingID ? (
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4>Tour Details</Typography.h4>
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem colSpan={8}>
                    <Typography.h4>Tour Details</Typography.h4>
                  </GridLayoutItem>
                )}
                {RouteVoucherDetail?.RouteVoucher && (
                  <>
                    <GridLayoutItem className="voucher-label voucher-label-main">
                      <b>Route Voucher No.</b>
                    </GridLayoutItem>
                    <GridLayoutItem className="voucher-details voucher-label-main">
                      :
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      className="voucher-details voucher-label-main"
                    >
                      <b>{RouteVoucherDetail?.RouteVoucher}</b>
                    </GridLayoutItem>
                  </>
                )}
                {RouteVoucherDetail?.TourBookingNo && (
                  <>
                    <GridLayoutItem className="voucher-label voucher-label-main">
                      <b>Tour Booking No.</b>
                    </GridLayoutItem>
                    <GridLayoutItem className="voucher-details voucher-label-main">
                      :
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      className="voucher-details voucher-label-main"
                    >
                      <b>{RouteVoucherDetail?.TourBookingNo}</b>
                    </GridLayoutItem>
                  </>
                )}

                <GridLayoutItem className="voucher-label">
                  Sector
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {RouteVoucherDetail?.Sector}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">Tour</GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {RouteVoucherDetail?.Tour}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Tour Date Code
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {RouteVoucherDetail?.TourDateCode}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Company
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {RouteVoucherDetail?.Company}
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    name={"TotalNoOfDays"}
                    label={"No Of Days"}
                    type="number"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    id={"GroupRouteID"}
                    name={"GroupRouteID"}
                    label={"Group Route"}
                    component={FormSelectionField}
                    onChange={(e) => handleChangeGroupRoute(e, formRenderProps)}
                    options={GroupRouteList?.map((route: IGroupRoute) => {
                      return {
                        value: route?.ID,
                        label: route?.RouteName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    id={"TransporterCityID"}
                    name={"TransporterCityID"}
                    label={"City"}
                    component={FormSelectionField}
                    options={CityList?.map((city: ICity) => {
                      return {
                        value: city?.ID,
                        label: city?.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    id={"TransporterID"}
                    name={"TransporterID"}
                    label={"Transporter"}
                    component={FormSelectionField}
                    options={VendorList?.map((roomtype: IVendor) => {
                      return {
                        value: roomtype?.ID,
                        label: roomtype?.VendorName,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
              <SectorChangeWatcher formRenderProps={formRenderProps} />
            </ShadowCard>
            <ShadowCard style={{ padding: 10, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>{"Pickup & Drop Detail"}</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"PickUpDate"}
                    label={"Pick Up Date"}
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"PickUpFrom"}
                    label={"PickUp From"}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"PickUpFlightNo"}
                    label={"Pickup Flight No / Train No"}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"DropDate"}
                    label={"Drop Date"}
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"DropAt"}
                    label={"Drop At"}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"DropFlightNo"}
                    label={"Drop Flight No / Train No"}
                    component={FormTextField}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 10, marginTop: 10 }}>
              <GridLayoutItem>
                <Typography.h4>{"Traveller Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Grid
                  style={{ maxHeight: "fit-content" }}
                  data={RouteVoucherDetail?.TouristPaxInfo}
                  cells={{
                    headerCell: HeaderCustomCell,
                    data: MyDataCustomCell,
                  }}
                >
                  <Column field="FirstName" title="First Name" />
                  <Column field="LastName" title="Last Name" />
                  <Column field="MobileNo" title="Mobile" />
                  <Column field="Age" title="Age" />
                  <Column field="Gender" title="Gender" />
                </Grid>
              </GridLayoutItem>
              <GridLayoutItem style={{ margin: "15px 0" }}>
                <b>Total Pax :</b>{" "}
                {RouteVoucherDetail?.TouristPaxInfo?.length || 0}
              </GridLayoutItem>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <FieldArray
                formRenderProps={formRenderProps}
                component={RouteArray}
                name="RouteInfo"
              />
            </ShadowCard>
            {/* <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <FieldArray
                formRenderProps={formRenderProps}
                component={VehicleRateArray}
                name="RouteBookingVehicleRateInfo"
              />
            </ShadowCard> */}
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem rowSpan={2}>
                  <Field
                    name={"Remarks"}
                    label={"Remarks"}
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"BookBy"}
                    label={"BookBy"}
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <RippleButton
                      fillMode="outline"
                      themeColor="primary"
                      style={{ marginRight: 4 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </RippleButton>
                    <ButtonWithLoading
                      label={RouteVoucherID ? "Update" : "Add"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateRouteVoucher;
