import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardDetails, IDashboardInitialState } from "./dashboardModel";
import {
  getAllTotalAirlineVouchers,
  getAllTotalDeparture,
  getAllTotalPendingPayments,
  getDashboard,
} from "./services/dashboard.services";
// const last15Days = new Date(new Date().setDate(new Date().getDate() - 15));
const initialState: IDashboardInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  dateFilter: {
    SelectedDate: null,
    FromDate: null,
    ToDate: null,
  },
  TotalAirlineVoucherList: [],
  TotalPendingPaymentList: [],
  TotalDepartureList: [],
  DashboardDetails: {
    TotalAirlineVoucher: 0,
    PendingPayment: 0,
    TotalDeparture: 0,
  },
  DashboardInnerList: [],
  ClientsProspectLeadList: [],
};

export const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboardDetails: (state) => {
      state.DashboardDetails = initialState.DashboardDetails;
    },
    clearDateFilter: (state) => {
      state.dateFilter = initialState.dateFilter;
    },
    setDateFilter: (state, action) => {
      state.dateFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getDashboard.fulfilled,
      (state, action: PayloadAction<IDashboardDetails>) => {
        state.loading = false;
        state.DashboardDetails = action.payload;
      }
    );
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DashboardDetails = initialState.DashboardDetails;
    });

    builder.addCase(getAllTotalAirlineVouchers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTotalAirlineVouchers.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.TotalAirlineVoucherList = action.payload;
      }
    );
    builder.addCase(getAllTotalAirlineVouchers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TotalAirlineVoucherList = initialState.TotalAirlineVoucherList;
    });

    builder.addCase(getAllTotalPendingPayments.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTotalPendingPayments.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.TotalPendingPaymentList = action.payload;
      }
    );
    builder.addCase(getAllTotalPendingPayments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TotalPendingPaymentList = initialState.TotalPendingPaymentList;
    });

    builder.addCase(getAllTotalDeparture.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTotalDeparture.fulfilled,
      (state, action: PayloadAction<[]>) => {
        state.loading = false;
        state.TotalDepartureList = action.payload;
      }
    );
    builder.addCase(getAllTotalDeparture.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TotalDepartureList = initialState.TotalDepartureList;
    });
  },
});

export const { clearDashboardDetails, clearDateFilter, setDateFilter } =
  DashboardSlice.actions;
export default DashboardSlice.reducer;
