import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHotelMapping, IHotelMappingInitialState } from "./hotelMappingModel";
import {
  ActiveInactiveHotelMapping,
  createHotelMapping,
  deleteHotelMapping,
  getAllActiveHotelMappings,
  getAllHotelMappings,
  getHotelMappingByID,
  updateHotelMapping,
} from "./services/hotelMapping.services";

const initialState: IHotelMappingInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  HotelMappingID: null,
  HotelMappingList: [],
  HotelMappingDetail: {
    TourID: null,
    CityID: null,
    HotelID: null,
    NoOfNights: null,
    PositionNo: null,
    IsActive: 1,
  },
};

const hotelMappingSlice = createSlice({
  name: "hotelMapping",
  initialState,
  reducers: {
    clearHotelMappingDetails: (state) => {
      state.HotelMappingDetail = initialState.HotelMappingDetail;
    },
    setHotelMappingID: (state, action) => {
      state.HotelMappingID = action.payload;
    },
    clearHotelMappingID: (state) => {
      state.HotelMappingID = initialState.HotelMappingID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHotelMappings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllHotelMappings.fulfilled,
      (state, action: PayloadAction<IHotelMapping[]>) => {
        state.loading = false;
        state.HotelMappingList = action.payload || [];
      }
    );
    builder.addCase(getAllHotelMappings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelMappingList = [];
    });
    builder.addCase(createHotelMapping.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createHotelMapping.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createHotelMapping.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateHotelMapping.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateHotelMapping.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateHotelMapping.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteHotelMapping.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHotelMapping.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteHotelMapping.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getHotelMappingByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getHotelMappingByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.HotelMappingDetail = action.payload;
    });
    builder.addCase(getHotelMappingByID.rejected, (state, action) => {
      state.formLoading = false;
      state.HotelMappingDetail = initialState.HotelMappingDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveHotelMappings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveHotelMappings.fulfilled,
      (state, action: PayloadAction<IHotelMapping[]>) => {
        state.loading = false;
        state.HotelMappingList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveHotelMappings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelMappingList = [];
    });
    builder.addCase(ActiveInactiveHotelMapping.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveHotelMapping.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveHotelMapping.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearHotelMappingDetails,
  clearHotelMappingID,
  setHotelMappingID,
} = hotelMappingSlice.actions;
export default hotelMappingSlice.reducer;
