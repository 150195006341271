import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import React from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import IconButton from "../../components/common/IconButton";
import FormDatePicker from "../../components/formFields/FormDateField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { setDeleteIndex } from "./airlineblockSlice";
import moment from "moment";
import { API_URL } from "../../app/config";
import { axiosApiInstance } from "../../app/axios";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

const AirlineBlockDateArray = (
  fieldArrayRenderProps: FieldArrayRenderProps
) => {
  const dispatch = useAppDispatch();
  const AirlineBlockDateDeleteIndex = useAppSelector(
    (state) => state.airlineblock.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("airlineBlockDateDeleteDialog"));
    dispatch(setDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.ID) {
        try {
          const response = await axiosApiInstance.post(
            `${API_URL}/AirlineBlock/DeleteAirlineBlockDates`,
            {
              ID: fieldArrayRenderProps?.value[index]?.ID,
            }
          );
          fieldArrayRenderProps.onRemove({ index: index });
          dispatch(closeDialog());
          dispatch(setDeleteIndex(-1));
          SuccessToast(response.data?.Details || "Success");
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting tourdate:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    const DepartureTerminal =
      fieldArrayRenderProps.formRenderProps.valueGetter("DepartureTerminal");
    const StopageTerminal =
      fieldArrayRenderProps.formRenderProps.valueGetter("StopageTerminal");
    const ArrivalTerminal =
      fieldArrayRenderProps.formRenderProps.valueGetter("ArrivalTerminal");
    fieldArrayRenderProps.onPush({
      value: {
        ID: null,
        ArrivalDate: "",
        DepartureDate: "",
        StopageArrivalDate: "",
        StopageDepartureDate: "",
        PNRNo: "",
        TotalSeats: null,
        SalesAmount: null,
        DepartureTerminal: DepartureTerminal || "",
        StopageTerminal: StopageTerminal || "",
        ArrivalTerminal: ArrivalTerminal || "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleDepartureDateChange = (e: any, index: number) => {
    fieldArrayRenderProps?.formRenderProps?.onChange(
      `BlockDatesDetails.${index}.ArrivalDate`,
      {
        value: moment(e.value)?.toDate(),
      }
    );
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Date
        </Button>
      </GridLayoutItem>
      {dialogName === "airlineBlockDateDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Date"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() =>
                    handleDeleteAction(AirlineBlockDateDeleteIndex)
                  }
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((Date: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                  { width: "20%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={5}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.DepartureDate`}
                    label="Departure Date"
                    format="dd/MM/yyyy"
                    onChange={(e) => handleDepartureDateChange(e, index)}
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.ArrivalDate`}
                    label="Arrival Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.StopageDepartureDate`}
                    label="Stopage Departure Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.StopageArrivalDate`}
                    label="Stopage Arrival Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.PNRNo`}
                    label="PNR No"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.TotalSeats`}
                    label="Total Seats"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.SalesAmount`}
                    label="Sales Amount"
                    type="number"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.DepartureTerminal`}
                    label="Departure Terminal"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.StopageTerminal`}
                    label="Stopage Terminal"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`BlockDatesDetails.${index}.ArrivalTerminal`}
                    label="Arrival Terminal"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default AirlineBlockDateArray;
