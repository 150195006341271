import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { CUSTOMERREPORTDATETYPE } from "../../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { clearCustomerList } from "../reportSlice";
import { ITour } from "../../tour/tourModel";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { getTourDates } from "../../hotelblock/services/hotelBlock.services";
import { ITourDateList } from "../../hotelblock/hotelBlockModel";
import { clearTourDateList } from "../../hotelblock/hotelBlockSlice";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = React.useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");
  const DateType = formRenderProps.valueGetter("DateType");

  useEffect(() => {
    if (TourID) {
      dispatch(getTourDates(TourID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [TourID]);

  useEffect(() => {
    if (DateType !== 3) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    }
  }, [DateType]);

  useEffect(() => {
    if (DateType === 3) {
      formRenderProps.onChange("FromDate", {
        value: null,
      });
      formRenderProps.onChange("ToDate", {
        value: null,
      });
    }
  }, [DateType]);

  return null;
};

const CustomerReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const TourList = useAppSelector((state) => state.tour.TourList);
  const TourDateList = useAppSelector((state) => state.hotelBlock.TourDateList);

  useEffect(() => {
    dispatch(getAllActiveTours());
    return () => {
      dispatch(clearCustomerList());
      dispatch(clearTourDateList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      DateType: values?.DateType ? values?.DateType : "0",
      FromDate: values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : "0",
      ToDate: values?.ToDate
        ? moment(values?.ToDate).format("YYYY-MM-DD")
        : "0",
      TourID: values?.TourID ? values?.TourID : "0",
      TourDate: values?.TourDate ? values?.TourDate : "0",
    };
    window.open(
      `customerreportview/${payload?.DateType}/${payload?.TourID}/${payload.TourDate}/${payload?.FromDate}/${payload?.ToDate}`
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Customer Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourID"
                      label="Tour"
                      component={FormSelectionField}
                      options={TourList?.map((tour: ITour) => {
                        return {
                          value: tour?.ID,
                          label: tour?.TourName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DateType"
                      label="Date Type"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={CUSTOMERREPORTDATETYPE?.map((datetype: any) => {
                        return {
                          value: datetype?.value,
                          label: datetype?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={2}></GridLayoutItem>
                  {formRenderProps.valueGetter("DateType") !== 3 && (
                    <GridLayoutItem style={{ display: "flex", gap: 10 }}>
                      <Field
                        name="FromDate"
                        wrapperStyle={{ width: "100%" }}
                        label="From Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                      <Field
                        name="ToDate"
                        wrapperStyle={{ width: "100%" }}
                        label="To Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                      />
                    </GridLayoutItem>
                  )}
                  {formRenderProps.valueGetter("DateType") === 3 && (
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name={"TourDate"}
                        label={"Tour Date"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={TourDateList?.map(
                          (tourDates: ITourDateList) => {
                            return {
                              value: `${moment(
                                tourDates?.TourDate,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD")}`,
                              label: tourDates?.TourDate,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                  )}
                  <GridLayoutItem
                    style={{
                      position: "relative",
                      minHeight: 90,
                    }}
                  >
                    <div style={{ position: "absolute", top: 42, left: 0 }}>
                      <ButtonWithLoading
                        label={"Get Details"}
                        type="submit"
                        Size="large"
                        disabled={!formRenderProps.allowSubmit || loading}
                        loading={loading}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
                <TourChangeWatcher formRenderProps={formRenderProps} />
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default CustomerReportForm;
