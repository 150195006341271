import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
// import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import HotelDetailsArray from "./HotelDetailsArray";
// import PaxDetailsArray from "./PaxDetailsArray";
import {
  createHotelBooking,
  getHotelBookingByID,
  getTourBookingView,
  updateHotelBooking,
} from "./services/hotelVoucher.services";
import { getTourBySectorID } from "../hotelblock/services/hotelBlock.services";
import FormTextArea from "../../components/formFields/FormTextArea";
import { IUser } from "../user/userModel";
import moment from "moment";
import {
  Grid,
  GridColumn as Column,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { getAllActiveUsers } from "../user/services/user.services";
import { clearHotelBookingDetails } from "./hotelVoucherSlice";
import { getCityBySectorID } from "../city/services/city.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  const HotelDetails = formRenderProps.valueGetter("HotelDetails");
  useEffect(() => {
    if (SectorID) {
      dispatch(getTourBySectorID(SectorID));
      dispatch(getCityBySectorID(SectorID));
    }
    if (!isTourDateRef.current) {
      HotelDetails?.map((item: any, index: number) => {
        formRenderProps.onChange(`HotelDetails.${index}.CityID`, {
          value: null,
        });
      });
      formRenderProps.onChange("TourID", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const HoteldetailsDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const HotelDetails = formRenderProps.valueGetter("HotelDetails");

  useEffect(() => {
    if (HotelDetails && HotelDetails.length > 0) {
      HotelDetails.map((item: any, index: number) => {
        const CheckOutDate = moment(item.CheckInDate)
          .add(+item?.NoOfNights || 0, "days")
          .toDate();
        formRenderProps.onChange(`HotelDetails.${index}.CheckOutDate`, {
          value: CheckOutDate,
        });
      });
    }
  }, [
    HotelDetails.map((item: any) => item.NoOfNights).join("-"),
    HotelDetails.map((item: any) => item.CheckInDate).join("-"),
  ]);
  return null;
};

const CreateHotelBooking: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const HotelBookingID = location.state?.HotelBookingID;
  const TourBookingID = location.state?.TourBookingID;
  // const gridRef = useRef<any>(null);
  const [formKey, setFormKey] = useState(1);

  const loading = useAppSelector((state) => state.hotelVoucher.loading);
  const UserList = useAppSelector((state) => state.user.UserList);
  const HotelBookingDetail = useAppSelector(
    (state) => state.hotelVoucher.HotelBookingDetail
  );

  useEffect(() => {
    dispatch(getAllActiveUsers());

    return () => {
      dispatch(clearHotelBookingDetails());
    };
  }, []);

  useEffect(() => {
    if (HotelBookingID) {
      dispatch(getHotelBookingByID(HotelBookingID));
    }
  }, [HotelBookingID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [HotelBookingDetail]);

  useEffect(() => {
    if (TourBookingID) {
      const payload = {
        HotelBookingID: 0,
        TourBookingNo: +TourBookingID || 0,
      };
      dispatch(getTourBookingView(payload));
    }
  }, [TourBookingID]);

  const HotelRoomPaxData =
    HotelBookingDetail?.RoomPaxes &&
    HotelBookingDetail.RoomPaxes.flatMap((roompax: any) =>
      roompax?.TouristPaxInfo.map((touristpax: any) => ({
        RoomNo: touristpax.RoomNo ? touristpax.RoomNo : "",
        PaxType: touristpax.PaxType ? touristpax.PaxType : "",
        FirstName: touristpax.FirstName ? touristpax.FirstName : "",
        LastName: touristpax.LastName ? touristpax.LastName : "",
        MobileNo: touristpax.MobileNo ? touristpax.MobileNo : "",
        FoodOption: touristpax.FoodOption ? touristpax.FoodOption : "",
      }))
    );

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const handleSubmit = async (values: any) => {
    if (HotelBookingID) {
      const editPayload = {
        ID: HotelBookingID,
        TourBookingNo: HotelBookingDetail.TourBookingNo
          ? HotelBookingDetail.TourBookingNo
          : TourBookingID,
        SectorID: HotelBookingDetail.SectorID
          ? HotelBookingDetail.SectorID
          : null,
        TourID: HotelBookingDetail.TourID ? HotelBookingDetail.TourID : null,
        CompanyID: HotelBookingDetail.CompanyID
          ? HotelBookingDetail.CompanyID
          : null,
        TourDateCode: HotelBookingDetail.TourDateCode
          ? HotelBookingDetail.TourDateCode
          : "",
        NoOfNights: HotelBookingDetail.NoOfNights
          ? HotelBookingDetail.NoOfNights
          : null,
        NoOfDays: HotelBookingDetail.NoOfDays
          ? HotelBookingDetail.NoOfDays
          : null,
        Remarks: values.Remarks ? values.Remarks : "",
        PickupPlace: values.PickupPlace ? values.PickupPlace : "",
        DropPlace: values.DropPlace ? values.DropPlace : "",
        TourDate: HotelBookingDetail.TourDate
          ? moment(HotelBookingDetail.TourDate).format("YYYY-MM-DD")
          : "",
        TotalAdults: HotelBookingDetail.TotalAdults
          ? HotelBookingDetail.TotalAdults
          : null,
        TotalExtraAdults: HotelBookingDetail.TotalExtraAdults
          ? HotelBookingDetail.TotalExtraAdults
          : null,
        TotalCWB: HotelBookingDetail.TotalCWB
          ? HotelBookingDetail.TotalCWB
          : null,
        TotalCNB: HotelBookingDetail.TotalCNB
          ? HotelBookingDetail.TotalCNB
          : null,
        TotalInfants: HotelBookingDetail.TotalInfants
          ? HotelBookingDetail.TotalInfants
          : null,
        BookBy: values.BookBy ? values.BookBy : null,
        IsActive: values.IsActive ? values.IsActive : true,
        HotelDetails: values.HotelDetails
          ? values.HotelDetails.map((places: any) => {
              return {
                HotelBookingID: HotelBookingID,
                CityID: places.CityID ? places.CityID : null,
                HotelID: places.HotelID ? places.HotelID : null,
                CheckInDate: places.CheckInDate
                  ? moment(places.CheckInDate).format("YYYY-MM-DD")
                  : "",
                NoOfNights: places.NoOfNights ? places.NoOfNights : null,
                CheckOutDate: places.CheckOutDate
                  ? moment(places.CheckOutDate).format("YYYY-MM-DD")
                  : "",
                MealPlanID: places.MealPlanID ? places.MealPlanID : null,
                ExtraBed: places.ExtraBed ? places.ExtraBed : null,
                RoomCategoryID: places.RoomCategoryID
                  ? places.RoomCategoryID
                  : null,
                TotalNoOfRooms: places.TotalNoOfRooms
                  ? places.TotalNoOfRooms
                  : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateHotelBooking(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/hotelvoucher");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        TourBookingNo: HotelBookingDetail.TourBookingNo
          ? HotelBookingDetail.TourBookingNo
          : TourBookingID,
        SectorID: HotelBookingDetail.SectorID
          ? HotelBookingDetail.SectorID
          : null,
        TourID: HotelBookingDetail.TourID ? HotelBookingDetail.TourID : null,
        CompanyID: HotelBookingDetail.CompanyID
          ? HotelBookingDetail.CompanyID
          : null,
        TourDateCode: HotelBookingDetail.TourDateCode
          ? HotelBookingDetail.TourDateCode
          : "",
        NoOfNights: HotelBookingDetail.NoOfNights
          ? HotelBookingDetail.NoOfNights
          : null,
        NoOfDays: HotelBookingDetail.NoOfDays
          ? HotelBookingDetail.NoOfDays
          : null,
        Remarks: values.Remarks ? values.Remarks : "",
        PickupPlace: values.PickupPlace ? values.PickupPlace : "",
        DropPlace: values.DropPlace ? values.DropPlace : "",
        TourDate: HotelBookingDetail.TourDate
          ? moment(HotelBookingDetail.TourDate).format("YYYY-MM-DD")
          : "",
        TotalAdults: HotelBookingDetail.TotalAdults
          ? HotelBookingDetail.TotalAdults
          : null,
        TotalExtraAdults: HotelBookingDetail.TotalExtraAdults
          ? HotelBookingDetail.TotalExtraAdults
          : null,
        TotalCWB: HotelBookingDetail.TotalCWB
          ? HotelBookingDetail.TotalCWB
          : null,
        TotalCNB: HotelBookingDetail.TotalCNB ? HotelBookingDetail.TotalCNB : 0,
        TotalInfants: HotelBookingDetail.TotalInfants
          ? HotelBookingDetail.TotalInfants
          : null,
        BookBy: values.BookBy ? values.BookBy : null,
        IsActive: values.IsActive ? values.IsActive : true,
        HotelDetails: values.HotelDetails
          ? values.HotelDetails.map((places: any) => {
              return {
                HotelBookingID: places.HotelBookingID
                  ? places.HotelBookingID
                  : 0,
                CityID: places.CityID ? places.CityID : null,
                HotelID: places.HotelID ? places.HotelID : null,
                CheckInDate: places.CheckInDate
                  ? moment(places.CheckInDate).format("YYYY-MM-DD")
                  : "",
                NoOfNights: places.NoOfNights ? places.NoOfNights : null,
                CheckOutDate: places.CheckOutDate
                  ? moment(places.CheckOutDate).format("YYYY-MM-DD")
                  : "",
                MealPlanID: places.MealPlanID ? places.MealPlanID : null,
                ExtraBed: places.ExtraBed ? places.ExtraBed : null,
                RoomCategoryID: places.RoomCategoryID
                  ? places.RoomCategoryID
                  : null,
                TotalNoOfRooms: places.TotalNoOfRooms
                  ? places.TotalNoOfRooms
                  : null,
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createHotelBooking(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={HotelBookingDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 70 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "16.66%" },
                  { width: "6%" },

                  { width: "10.66%" },
                  { width: "16.66%" },

                  { width: "16.66%" },
                  { width: "6%" },

                  { width: "10.66%" },
                  { width: "16.66%" },
                ]}
              >
                {TourBookingID ? (
                  <GridLayoutItem colSpan={4}>
                    <Typography.h4>Tour Details</Typography.h4>
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem colSpan={8}>
                    <Typography.h4>Tour Details</Typography.h4>
                  </GridLayoutItem>
                )}

                {HotelBookingDetail?.HotelBookingNo && (
                  <>
                    <GridLayoutItem className="voucher-label voucher-label-main">
                      <b>Hotel Booking No.</b>
                    </GridLayoutItem>
                    <GridLayoutItem className="voucher-details voucher-label-main">
                      :
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      className="voucher-details voucher-label-main"
                    >
                      <b>{HotelBookingDetail?.HotelBookingNo}</b>
                    </GridLayoutItem>
                  </>
                )}
                {HotelBookingDetail?.TourBookingNo && (
                  <>
                    <GridLayoutItem className="voucher-label voucher-label-main">
                      <b>Tour Booking No.</b>
                    </GridLayoutItem>
                    <GridLayoutItem className="voucher-details voucher-label-main">
                      :
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={2}
                      className="voucher-details voucher-label-main"
                    >
                      <b>{HotelBookingDetail?.TourBookingNo}</b>
                    </GridLayoutItem>
                  </>
                )}

                <GridLayoutItem className="voucher-label">
                  Sector
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.SectorName}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">Tour</GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TourName}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Tour Date Code
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TourDateCode}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Company
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.CompanyName}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  TourDate
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TourDate
                    ? moment(HotelBookingDetail?.TourDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )
                    : ""}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  No Of Nights
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.NoOfNights || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  No Of Days
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.NoOfDays || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Total Adults
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TotalAdults || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Total Extra Adults
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TotalExtraAdults || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Total Child WB
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TotalCWB || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Total Child NB
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TotalCNB || 0}
                </GridLayoutItem>
                <GridLayoutItem className="voucher-label">
                  Total Infants
                </GridLayoutItem>
                <GridLayoutItem className="voucher-details">:</GridLayoutItem>
                <GridLayoutItem colSpan={2} className="voucher-details">
                  {HotelBookingDetail?.TotalInfants || 0}
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    name="PickupPlace"
                    label="Pick up Place"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={4}>
                  <Field
                    name="DropPlace"
                    label="Drop Place"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <SectorChangeWatcher formRenderProps={formRenderProps} />
                <HoteldetailsDatechangeWatcher
                  formRenderProps={formRenderProps}
                />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>Hotel Details</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={HotelDetailsArray}
                name="HotelDetails"
              />
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayoutItem colSpan={3}>
                <Typography.h4>Pax Details</Typography.h4>
              </GridLayoutItem>
              {/* <FieldArray
                formRenderProps={formRenderProps}
                component={PaxDetailsArray}
                name="roomPaxes"
              /> */}
              <GridLayoutItem>
                <Grid
                  style={{ maxHeight: "fit-content" }}
                  data={HotelRoomPaxData}
                  cells={{
                    headerCell: HeaderCustomCell,
                    data: MyDataCustomCell,
                  }}
                >
                  <Column field="RoomNo" title="Room No" />
                  <Column field="PaxType" title="Pax Type" />
                  <Column field="FirstName" title="First Name" />
                  <Column field="LastName" title="Last Name" />
                  <Column field="MobileNo" title="Mobile" />
                  <Column field="FoodOption" title="Food Option" />
                </Grid>
              </GridLayoutItem>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem rowSpan={2}>
                  <Field
                    name={"Remarks"}
                    label={"Remarks"}
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={"BookBy"}
                    label={"BookBy"}
                    component={FormSelectionField}
                    options={UserList?.map((user: IUser) => {
                      return {
                        value: user?.ID,
                        label: `${user?.FirstName} ${user?.LastName}`,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    marginTop: 10,
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <div>
                    <RippleButton
                      fillMode="outline"
                      themeColor="primary"
                      style={{ marginRight: 4 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </RippleButton>
                    <ButtonWithLoading
                      label={HotelBookingID ? "Update" : "Add"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateHotelBooking;
