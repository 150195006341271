import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IVehicleSharingPax,
  IVehicleSharingPaxState,
} from "./vehicleSharingPaxModel";
import {
  ActiveInactiveVehicleSharingPax,
  createVehicleSharingPax,
  deleteVehicleSharingPax,
  getAllActiveVehicleSharingPaxs,
  getAllVehicleSharingPaxs,
  getVehicleSharingPaxByID,
  updateVehicleSharingPax,
} from "./services/vehicleSharingPax.services";

const initialState: IVehicleSharingPaxState = {
  loading: false,
  formLoading: false,
  error: "",
  VehicleSharingPaxID: null,
  VehicleSharingPaxList: [],
  VehicleSharingPaxDetail: {
    Title: "",
    IsActive: true,
  },
};

const vehicleSharingPaxSlice = createSlice({
  name: "vehicleSharingPax",
  initialState,
  reducers: {
    clearVehicleSharingPaxDetails: (state) => {
      state.VehicleSharingPaxDetail = initialState.VehicleSharingPaxDetail;
    },
    setVehicleSharingPaxID: (state, action) => {
      state.VehicleSharingPaxID = action.payload;
    },
    clearVehicleSharingPaxID: (state) => {
      state.VehicleSharingPaxID = initialState.VehicleSharingPaxID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllVehicleSharingPaxs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllVehicleSharingPaxs.fulfilled,
      (state, action: PayloadAction<IVehicleSharingPax[]>) => {
        state.loading = false;
        state.VehicleSharingPaxList = action.payload || [];
      }
    );
    builder.addCase(getAllVehicleSharingPaxs.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.VehicleSharingPaxList = [];
    });
    builder.addCase(createVehicleSharingPax.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createVehicleSharingPax.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createVehicleSharingPax.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateVehicleSharingPax.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateVehicleSharingPax.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateVehicleSharingPax.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteVehicleSharingPax.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteVehicleSharingPax.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteVehicleSharingPax.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getVehicleSharingPaxByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getVehicleSharingPaxByID.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.VehicleSharingPaxDetail = action.payload;
      }
    );
    builder.addCase(getVehicleSharingPaxByID.rejected, (state, action) => {
      state.formLoading = false;
      state.VehicleSharingPaxDetail = initialState.VehicleSharingPaxDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveVehicleSharingPaxs.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveVehicleSharingPaxs.fulfilled,
      (state, action: PayloadAction<IVehicleSharingPax[]>) => {
        state.loading = false;
        state.VehicleSharingPaxList = action.payload || [];
      }
    );
    builder.addCase(
      getAllActiveVehicleSharingPaxs.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.VehicleSharingPaxList = [];
      }
    );
    builder.addCase(ActiveInactiveVehicleSharingPax.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveVehicleSharingPax.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(
      ActiveInactiveVehicleSharingPax.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  clearVehicleSharingPaxDetails,
  clearVehicleSharingPaxID,
  setVehicleSharingPaxID,
} = vehicleSharingPaxSlice.actions;
export default vehicleSharingPaxSlice.reducer;
