import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllHotelMappings = createAsyncThunk(
  "TourHotels/FindAllTourHotel",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/FindAllTourHotel`,
        {
          TourID: null,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Mappings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createHotelMapping = createAsyncThunk(
  "TourHotels/InsertTourHotel",
  async (hotelMappingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/InsertTourHotel`,
        hotelMappingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating hotel mapping:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateHotelMapping = createAsyncThunk(
  "TourHotels/UpdateTourHotel",
  async (hotelMappingData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/UpdateTourHotel`,
        hotelMappingData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating hotel mapping:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteHotelMapping = createAsyncThunk(
  "TourHotels/DeleteTourHotel",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/DeleteTourHotel`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting hotel mapping:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getHotelMappingByID = createAsyncThunk(
  "TourHotels/FindByIDTourHotel",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/FindByIDTourHotel`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        TourID: result.TourID,
        CityID: result.CityID,
        HotelID: result.HotelID,
        NoOfNights: result.NoOfNights,
        PositionNo: result.PositionNo,
        IsActive: result.IsActive ? 1 : 2,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching hotel mapping:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveHotelMappings = createAsyncThunk(
  "TourHotels/FindAllActiveTourHotel",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/TourHotels/FindAllActiveTourHotel`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Hotel Mappings:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveHotelMapping = createAsyncThunk(
  "TourHotels/ActiveInActiveTourHotel",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourHotels/ActiveInActiveTourHotel`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive hotel mapping:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
