import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import moment from "moment";
import { Button } from "@progress/kendo-react-buttons";
import { getCustomerListReport } from "../services/reports.services";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";

const CustomerReportView = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/customerreportform",
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights("/customerreportform", PRINT_ACCESS);

  const DateType = params?.DateType;
  const FromDate = params?.FromDate;
  const ToDate = params?.ToDate;
  const TourID = params?.TourID;
  const TourDate = params?.TourDate;

  const loading = useAppSelector((state) => state.reports.loading);
  const CustomerList = useAppSelector((state) => state.reports.CustomerList);

  const [uiLoading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (DateType && FromDate && ToDate) {
      const payload = {
        DateType: DateType !== "0" ? DateType : null,
        TourDate: TourDate !== "0" ? TourDate : "",
        FromDate: FromDate !== "0" ? FromDate : "",
        ToDate: ToDate !== "0" ? ToDate : "",
        TourID: TourID !== "0" ? TourID : null,
      };
      dispatch(getCustomerListReport(payload));
    }
  }, []);

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "CustomerReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "customerreport.xlsx");
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    let report_container = document.querySelector(".report-container") as any;

    if (!table) {
      console.error("Table element not found.");
      return;
    }

    printbuttons.style.display = "none";
    report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    report_container.style.padding = "20px 10px";
  };

  if (uiLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        className="report-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 10px",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Customer List{" "}
          <span style={{ fontSize: 15 }}>
            {CustomerList &&
              CustomerList.length > 0 &&
              `(
              ${CustomerList[0]?.TourName} - ${
                FromDate !== "0"
                  ? moment(FromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }${FromDate !== "0" && ToDate !== "0" ? " to " : ""}${
                ToDate !== "0"
                  ? moment(ToDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : " "
              }
              ${
                TourDate !== "0"
                  ? moment(TourDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }
              )`}
          </span>
        </Typography.h4>
        <div className="printbuttons">
          {isPrintAccess && (
            <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={printTable}
            >
              Print
            </Button>
          )}

          {isExcelExportAccess && (
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={handleExportExcel}
            >
              Excel Export
            </Button>
          )}
        </div>
      </div>
      <div className="table-responsive" style={{ margin: "0 10px" }}>
        <table className="table table-bordered reportScreens">
          <thead>
            <tr>
              <th style={{ background: "black", color: "white" }}>No</th>
              <th style={{ background: "black", color: "white" }}>
                Guest Name
              </th>
              {/* <th style={{ background: "black", color: "white" }}>Email ID</th> */}
              <th style={{ background: "black", color: "white" }}>Mobile No</th>
              <th style={{ background: "black", color: "white" }}>
                Pickup Place
              </th>
              <th style={{ background: "black", color: "white" }}>
                Drop Place
              </th>
              <th style={{ background: "black", color: "white" }}>
                Vehicle Sharing Pax
              </th>
              <th style={{ background: "black", color: "white" }}>Food</th>
            </tr>
          </thead>
          <tbody>
            {CustomerList &&
              CustomerList.length > 0 &&
              CustomerList?.map((customer: any, index: number) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{customer?.CustomerName}</td>
                  {/* <td>{customer?.EmailID}</td> */}
                  <td>{customer?.MobileNo}</td>
                  <td>{customer?.PickUpPlace}</td>
                  <td>{customer?.DropPlace}</td>
                  <td>{customer?.VehicleSharingPax}</td>
                  <td>{customer?.Foodoption}</td>
                </tr>
              ))}
            {/* <tr className="fw-bold">
                <td
                  colSpan={3}
                  className="text-end pe-5"
                  style={{ background: "lightgray", color: "black" }}
                >
                  Total
                </td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
              </tr> */}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomerReportView;
