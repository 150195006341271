import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import AlertBox from "../../components/common/AlertBox";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { clearRouteVoucherID, setRouteVoucherID } from "./routeVoucherSlice";
import {
  deleteRouteVoucher,
  getAllRouteVouchers,
} from "./services/routeVoucher.services";
import { checkAcessRights } from "../../_helper/helper";
import { DELETE_ACCESS, EDIT_ACCESS } from "../../_contstants/common";

const RouteVoucher: React.FC = () => {
  return (
    <>
      <DeleteRouteVoucherDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Route Voucher List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <RouteVoucherGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const RouteVoucherGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);

  const RouteVoucherList = useAppSelector(
    (state) => state.routeVoucher.RouteVoucherList
  );
  const loading = useAppSelector((state) => state.routeVoucher.loading);

  useEffect(() => {
    dispatch(getAllRouteVouchers());
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? RouteVoucherList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditHotelBooking = (
      ID: number,
      RouteVoucher: any,
      TourBookingNo: any
    ) => {
      navigate("/routevoucher/edit", {
        state: {
          RouteVoucherID: ID,
          RouteBookingID: RouteVoucher,
          TourBookingNo: TourBookingNo,
        },
      });
    };
    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteRouteVoucher"));
      dispatch(setRouteVoucherID(ID));
    };
    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditHotelBooking(
                props.dataItem.ID,
                props.dataItem.RouteVoucher,
                props.dataItem.TourBookingNo
              )
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {isDeleteAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Delete"
            onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
          >
            <MdDelete
              className="absolute-position"
              style={{ fontSize: "24px" }}
            />
          </IconButton>
        )}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 7 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {RouteVoucherList && RouteVoucherList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(RouteVoucherList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : RouteVoucherList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={RouteVoucherList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="RouteVoucher" title="Route Voucher No." width={150} />
          <Column field="TourBookingNo" title="Tour Booking No." width={150} />
          <Column field="Name" title="Name" width={250} />
          <Column field="Sector" title="Sector" width={200} />
          <Column field="Tour" title="Tour" width={350} />
          <Column field="TourDateCode" title="TourDateCode" width={200} />
          <Column field="BookBy" title="BookBy" width={200} />
          {(isEditAccess || isDeleteAccess) && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteRouteVoucherDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const RouteVoucherID = useAppSelector(
    (state) => state.routeVoucher.RouteVoucherID
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      const response = await dispatch(deleteRouteVoucher(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllRouteVouchers());
        dispatch(closeDialog());
        dispatch(clearRouteVoucherID());
      } else {
        dispatch(getAllRouteVouchers());
        dispatch(closeDialog());
        dispatch(clearRouteVoucherID());
      }
    }
  };
  return (
    <>
      {dialogName === "deleteRouteVoucher" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Route Voucher"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Route Voucher?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RouteVoucherID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default RouteVoucher;
