import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISector, ISectorInitialState } from "./sectorModel";
import {
  ActiveInactiveSector,
  createSector,
  deleteSector,
  getAllActiveSectors,
  getAllSectors,
  getSectorByID,
  updateSector,
} from "./services/sector.services";

const initialState: ISectorInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  SectorList: [],
  SectorID: null,
  SectorDetail: {
    SectorName: "",
    Prefix: null,
    SectorType: "",
    RegionID: null,
    CountryID: null,
    CityID: "",
    IsActive: false,
  },
};

const sectorSlice = createSlice({
  name: "sector",
  initialState,
  reducers: {
    clearSectorDetails: (state) => {
      state.SectorDetail = initialState.SectorDetail;
    },
    setSectorID: (state, action) => {
      state.SectorID = action.payload;
    },
    clearSectorID: (state) => {
      state.SectorID = initialState.SectorID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllSectors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllSectors.fulfilled,
      (state, action: PayloadAction<ISector[]>) => {
        state.loading = false;
        state.SectorList = action.payload || [];
      }
    );
    builder.addCase(getAllSectors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorList = [];
    });
    builder.addCase(createSector.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createSector.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createSector.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateSector.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateSector.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateSector.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getSectorByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getSectorByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.SectorDetail = action.payload;
    });
    builder.addCase(getSectorByID.rejected, (state, action) => {
      state.formLoading = false;
      state.SectorDetail = initialState.SectorDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveSectors.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveSectors.fulfilled,
      (state, action: PayloadAction<ISector[]>) => {
        state.loading = false;
        state.SectorList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveSectors.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.SectorList = [];
    });
    builder.addCase(ActiveInactiveSector.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveSector.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveSector.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearSectorDetails, setSectorID, clearSectorID } =
  sectorSlice.actions;
export default sectorSlice.reducer;
