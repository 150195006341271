import React from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ShadowCard from "../../components/common/ShadowCard";
import { Typography } from "@progress/kendo-react-common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { Button } from "@progress/kendo-react-buttons";
import {
  confirmPassValidator,
  passwordValidator,
} from "../../components/formFields/CommonValidator";
import FormPasswordField from "../../components/formFields/FormPasswordField";
import { getLocalStorageItem } from "../../_helper/helper";
import { ErrorToast } from "../../components/toast/Toasts";
import { changeUserPassword } from "../user/services/user.services";
import { useNavigate } from "react-router-dom";

const ChangePassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const UserID = getLocalStorageItem("UserID");
  const userLoading = useAppSelector((state) => state.user.loading);

  const handleSubmit = async (values: any) => {
    const payload = {
      ID: UserID ? +UserID : null,
      OldPassword: values?.OldPassword ? values?.OldPassword : "",
      Password: values?.Password ? values?.Password : "",
    };
    if (values?.confirmPassword === values?.Password) {
      const res = await dispatch(changeUserPassword(payload));
      if (res.meta.requestStatus === "fulfilled") {
        navigate(-1);
      }
    } else {
      ErrorToast("Confirm password is incorrect. Please try again");
    }
  };

  return (
    <GridLayout
      gap={{ rows: 10 }}
      cols={[{ width: "50%" }]}
      style={{ justifyContent: "center" }}
    >
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  gap={{ rows: 20, cols: 10 }}
                  cols={[{ width: "100%" }]}
                >
                  <GridLayoutItem>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Change Password
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={"OldPassword"}
                      type={"password"}
                      label={"Old Password"}
                      component={FormPasswordField}
                      validator={passwordValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={"Password"}
                      type={"password"}
                      label={"New Password"}
                      component={FormPasswordField}
                      validator={passwordValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={"confirmPassword"}
                      type={"password"}
                      label={"Confirm Password"}
                      component={FormPasswordField}
                      validator={confirmPassValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      textAlign: "end",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label="Change"
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || userLoading}
                      loading={userLoading}
                    />
                  </GridLayoutItem>
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
      {/* <GridLayoutItem style={{ marginTop: 20 }}>
      {TourReport && TourReport.length > 0 && (
        <ShadowCard>
          <TourReportGridComponent />
        </ShadowCard>
      )}
    </GridLayoutItem> */}
    </GridLayout>
  );
};

export default ChangePassword;
