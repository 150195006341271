import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ISectorCity } from "../sectorCityModel";

export const getAllSectorCities = createAsyncThunk(
  "SectorCities/FindAllSectorCities",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/FindAllSectorCities`,
        {}
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Sector Cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createSectorCity = createAsyncThunk(
  "SectorCities/InsertSectorCities",
  async (SectorCityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/InsertSectorCities`,
        SectorCityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating sector city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateSectorCity = createAsyncThunk(
  "SectorCities/UpdateSectorCities",
  async (SectorCityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/UpdateSectorCities`,
        SectorCityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating sector city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteSectorCity = createAsyncThunk(
  "SectorCities/DeleteSectorCities",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/DeleteSectorCities`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting sector city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getSectorCityByID = createAsyncThunk(
  "SectorCities/FindByIDSectorCities",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/FindByIDSectorCities`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorID: result.SectorID,
        CityID: result.CityID,
        IsActive: result.IsActive,
      } as ISectorCity;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching sector city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveSectorCities = createAsyncThunk(
  "SectorCities/FindAllActiveSectorCities",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/SectorCities/FindAllActiveSectorCities`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching sector cities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveSectorCity = createAsyncThunk(
  "SectorCities/ActiveInActiveSectorCities",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/SectorCities/ActiveInActiveSectorCities`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive sector city:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
