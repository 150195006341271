import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  createVendorType,
  getAllVendorTypes,
  getVendorTypeByID,
  updateVendorType,
} from "./services/vendorType.services";
import { clearVendorTypeDetails } from "./vendorTypeSlice";
import { closed } from "../../components/drawer/drawerSlice";

const CreateVendorType: React.FC = () => {
  const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);

  const VendorTypeDetail = useAppSelector(
    (state) => state.vendorType.VendorTypeDetail
  );
  const VendorTypeID = useAppSelector((state) => state.drawer.data);
  const formLoading = useAppSelector((state) => state.vendorType.formLoading);

  useEffect(() => {
    return () => {
      dispatch(clearVendorTypeDetails());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (VendorTypeID) {
      dispatch(getVendorTypeByID(VendorTypeID));
    }
  }, [VendorTypeID]);

  const handleSubmit = async (values: any) => {
    try {
      if (!VendorTypeID) {
        const insertPayload = {
          VendorType: values.VendorType,
          IsActive: true,
        };
        const response = await dispatch(createVendorType(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVendorTypeDetails());
          dispatch(getAllVendorTypes());
        }
      } else {
        const editPayload = {
          ID: VendorTypeID,
          VendorType: values.VendorType,
          IsActive: values.IsActive,
        };
        const response = await dispatch(updateVendorType(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(closed());
          dispatch(clearVendorTypeDetails());
          dispatch(getAllVendorTypes());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={VendorTypeDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ width: "100%" }}>
          <GridLayout
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
            gap={{ rows: 10 }}
            cols={[{ width: "100%" }]}
          >
            <GridLayoutItem style={{ width: "100%" }}>
              <Field
                name="VendorType"
                label="Vendor Type"
                component={FormTextField}
                validator={requiredValidator}
              />
            </GridLayoutItem>
            <GridLayoutItem
              colSpan={3}
              style={{
                textAlign: "end",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                type="button"
                fillMode={"outline"}
                themeColor={"primary"}
                style={{ marginRight: 4 }}
                onClick={handleFormClose}
              >
                Cancel
              </Button>
              <ButtonWithLoading
                label={VendorTypeID ? "Update" : "Create"}
                type="submit"
                disabled={!formRenderProps.allowSubmit || formLoading}
                loading={formLoading}
              />
            </GridLayoutItem>
          </GridLayout>
        </FormElement>
      )}
    />
  );
};

export default CreateVendorType;
