import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import moment from "moment";
import { IAirlineVoucherDetails } from "../airlineVoucherModel";

export const getAllAirlineVouchers = createAsyncThunk(
  "AirlineVoucher/FindAllAirlineVoucher",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineVoucher/FindAllAirlineVoucher`,
        {
          CreatedBy: 0,
          Limit: 500,
          Skip: 0,
          AirlineVoucherNo: "",
          TourBookingNo: "",
          Name: "",
          PNR: "",
          BookBy: "",
          Branch: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airline vouchers:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAirlineVoucher = createAsyncThunk(
  "AirlineVoucher/InsertAirlineVoucher",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineVoucher/InsertAirlineVoucher`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airline voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAirlineVoucher = createAsyncThunk(
  "AirlineVoucher/UpdateAirlineVoucher",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineVoucher/UpdateAirlineVoucher`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating airline voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAirlineVoucher = createAsyncThunk(
  "AirlineVoucher/DeleteAirlineVoucher",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineVoucher/DeleteAirlineVoucher`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting airline voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineVoucherByID = createAsyncThunk(
  "AirlineVoucher/FindByIDAirlineVoucher",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineVoucher/FindByIDAirlineVoucher`,
        { ID }
      );
      const result = response.data?.Data as any;
      const departures = result.airlineVoucherSegments
        ? result.airlineVoucherSegments
            .filter((segment: any) => segment.JourneyType === "DEPARTURE")
            .map((segment: any) => {
              return {
                VendorID: segment.VendorID ? +segment.VendorID : null,
                AirlineID: segment.AirlineID ? +segment.AirlineID : null,
                FlightNo: segment.FlightNo ? segment.FlightNo : "",
                JourneyType: segment.JourneyType ? segment.JourneyType : "",
                FromAirportID: segment.FromAirportID
                  ? +segment.FromAirportID
                  : null,
                ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
                PNRNo: segment.PNRNo ? segment.PNRNo : "",
                TotalPrice: segment.TotalPrice ? +segment.TotalPrice : 0,
                DepartureDate: segment.DepartureDate
                  ? moment(segment.DepartureDate, "YYYY-MM-DD").toDate()
                  : "",
                DepartureTime: segment.DepartureTime
                  ? moment(segment.DepartureTime, "HH:mm:ss").toDate()
                  : "",
                ArrivalDate: segment.ArrivalDate
                  ? moment(segment.ArrivalDate, "YYYY-MM-DD").toDate()
                  : "",
                ArrivalTime: segment.ArrivalTime
                  ? moment(segment.ArrivalTime, "HH:mm:ss").toDate()
                  : "",
                Boarding: segment.Boarding ? segment.Boarding : "",
                ClassID: segment.ClassID == 1 ? 1 : 2,
              };
            })
        : [];
      const returns = result.airlineVoucherSegments
        ? result.airlineVoucherSegments
            .filter((segment: any) => segment.JourneyType === "RETURN")
            .map((segment: any) => {
              return {
                VendorID: segment.VendorID ? +segment.VendorID : null,
                AirlineID: segment.AirlineID ? +segment.AirlineID : null,
                FlightNo: segment.FlightNo ? segment.FlightNo : "",
                JourneyType: segment.JourneyType ? segment.JourneyType : "",
                FromAirportID: segment.FromAirportID
                  ? +segment.FromAirportID
                  : null,
                ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
                PNRNo: segment.PNRNo ? segment.PNRNo : "",
                TotalPrice: segment.TotalPrice ? +segment.TotalPrice : 0,
                DepartureDate: segment.DepartureDate
                  ? moment(segment.DepartureDate, "YYYY-MM-DD").toDate()
                  : "",
                DepartureTime: segment.DepartureTime
                  ? moment(segment.DepartureTime, "HH:mm:ss").toDate()
                  : "",
                ArrivalDate: segment.ArrivalDate
                  ? moment(segment.ArrivalDate, "YYYY-MM-DD").toDate()
                  : "",
                ArrivalTime: segment.ArrivalTime
                  ? moment(segment.ArrivalTime, "HH:mm:ss").toDate()
                  : "",
                Boarding: segment.Boarding ? segment.Boarding : "",
                ClassID: segment.ClassID == 1 ? 1 : 2,
              };
            })
        : [];
      return {
        ID: result.ID ? +result.ID : null,
        TourBookingNo: result.TourBookingNo ? result.TourBookingNo : "",
        AirlineBlockID: result.AirlineBlockID ? +result.AirlineBlockID : null,
        CompanyID: result.CompanyID ? +result.CompanyID : null,
        TicketPurchasedDate: result.TicketPurchasedDate
          ? moment(result.TicketPurchasedDate, "YYYY-MM-DD").toDate()
          : "",
        Journey: result.Journey ? result.Journey : "",
        NoOfPax: result.NoOfPax ? +result.NoOfPax : null,
        TotalPrice: result.TotalPrice ? +result.TotalPrice : null,
        OTP: result.OTP ? result.OTP : "",
        BookBy: result.BookBy ? +result.BookBy : null,
        Remarks: result.Remarks ? result.Remarks : "",
        OldAirlineVoucherNo: result.OldAirlineVoucherNo
          ? result.OldAirlineVoucherNo
          : "",
        AirlineBlockDateID: result.AirlineBlockDateID
          ? +result.AirlineBlockDateID
          : null,
        DEPARTURE: departures || [],
        RETURN: returns || [],
        airlineVoucherSegments: result?.airlineVoucherSegments
          ? result?.airlineVoucherSegments.map((segment: any) => {
              return {
                VendorID: segment.VendorID ? +segment.VendorID : null,
                AirlineID: segment.AirlineID ? +segment.AirlineID : null,
                FlightNo: segment.FlightNo ? segment.FlightNo : "",
                JourneyType: segment.JourneyType ? segment.JourneyType : "",
                FromAirportID: segment.FromAirportID
                  ? +segment.FromAirportID
                  : null,
                ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
                PNRNo: segment.PNRNo ? segment.PNRNo : "",
                TotalPrice: segment.TotalPrice ? +segment.TotalPrice : 0,
                DepartureDate: segment.DepartureDate
                  ? moment(segment.DepartureDate, "YYYY-MM-DD").toDate()
                  : "",
                DepartureTime: segment.DepartureTime
                  ? moment(segment.DepartureTime, "HH:mm:ss").toDate()
                  : "",
                ArrivalDate: segment.ArrivalDate
                  ? moment(segment.ArrivalDate, "YYYY-MM-DD").toDate()
                  : "",
                ArrivalTime: segment.ArrivalTime
                  ? moment(segment.ArrivalTime, "HH:mm:ss").toDate()
                  : "",
                Boarding: segment.Boarding ? segment.Boarding : "",
                ClassID: segment.ClassID == 1 ? 1 : 2,
              };
            })
          : [],
        airlineVoucherTravellers: result.airlineVoucherTravellers
          ? result.airlineVoucherTravellers.map((traveller: any) => {
              return {
                FirstName: traveller.FirstName ? traveller.FirstName : "",
                LastName: traveller.LastName ? traveller.LastName : "",
                //   TicketFrom: traveller.TicketFrom ? traveller.TicketFrom : "",
                //   AirlineBlockID: traveller.AirlineBlockID ? +traveller.AirlineBlockID : null,
                //   Price: traveller.Price ? +traveller.Price : null,
                //   FileName: traveller.FileName ? traveller.FileName : "",
                Initial: traveller.Initial ? traveller.Initial : "",
                Gender: traveller.Gender ? traveller.Gender : "",
                PaxType: traveller.PaxType ? traveller.PaxType : "",
                IsSelected: traveller.IsSelected ? traveller.IsSelected : false,

                PassportNo: traveller.PassportNo ? traveller.PassportNo : "",
                IssueDate: traveller.IssueDate
                  ? moment(traveller.IssueDate, "YYYY-MM-DD").toDate()
                  : "",
                ExpiryDate: traveller.ExpiryDate
                  ? moment(traveller.ExpiryDate, "YYYY-MM-DD").toDate()
                  : "",
                NationalityID: traveller.NationalityID
                  ? traveller.NationalityID
                  : null,
              };
            })
          : [],
        IsActive: result.IsActive ? result.IsActive : true,
      } as IAirlineVoucherDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airline Voucher:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourBookingPaxList = createAsyncThunk(
  "TourBooking/Gettourbookingpaxlist",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourBooking/Gettourbookingpaxlist`,
        {
          TourBookingNo: ID,
        }
      );
      const result = response.data?.Data;
      return result.map((pax: any) => {
        return {
          FirstName: pax.FirstName ? pax.FirstName : "",
          LastName: pax.LastName ? pax.LastName : "",
          Initial: pax.Initial ? pax.Initial : "",
          Gender: pax.Gender ? pax.Gender : "",
          PaxType: pax.PaxType ? pax.PaxType : "",
          PassportNo: pax.PassportNo ? pax.PassportNo : "",
          IssueDate: pax.IssueDate
            ? moment(pax.IssueDate, "YYYY-MM-DD").toDate()
            : "",
          ExpiryDate: pax.ExpiryDate
            ? moment(pax.ExpiryDate, "YYYY-MM-DD").toDate()
            : "",
          NationalityID: pax.NationalityID ? pax.NationalityID : null,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Tour Bokings Pax List:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineSegmentView = createAsyncThunk(
  "AirlineBlock/FindAirlineSegmentsView",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/FindAirlineSegmentsView`,
        formData
      );
      return response.data.Data.map((segment: any) => {
        return {
          ID: segment.ID ? +segment.ID : null,
          VendorID: segment.VendorID ? +segment.VendorID : null,
          AirlineID: segment.AirlineID ? +segment.AirlineID : null,
          FlightNo: segment.FlightNo ? segment.FlightNo : "",
          VendorName: segment.VendorName ? segment.VendorName : "",
          JourneyType: segment.JourneyType ? segment.JourneyType : "",
          FromAirportID: segment.FromAirportID ? +segment.FromAirportID : null,
          FromAirportName: segment.FromAirportName
            ? segment.FromAirportName
            : "",
          ToAirportID: segment.ToAirportID ? +segment.ToAirportID : null,
          ToAirportName: segment.ToAirportName ? segment.ToAirportName : "",
          PNRNo: segment.PNRNo ? segment.PNRNo : "",
          DepartureDate: segment.DepartureDate
            ? moment(segment.DepartureDate).toDate()
            : "",
          DepartureTime: segment.DepartureTime
            ? moment(segment.DepartureTime, "HH:mm:ss").toDate()
            : "",
          ArrivalDate: segment.ArrivalDate
            ? moment(segment.ArrivalDate).toDate()
            : "",
          ArrivalTime: segment.ArrivalTime
            ? moment(segment.ArrivalTime, "HH:mm:ss").toDate()
            : "",
          Boarding: segment.Boarding ? segment.Boarding : "",
          ClassID: segment.ClassID == 1 ? 1 : 2,
          ClassName: segment.ClassName ? +segment.ClassName : null,
          TotalPrice: segment.TotalPrice ? +segment.TotalPrice : 0,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching segment details:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineTicketPDFByID = createAsyncThunk(
  "AirlineBlock/AirlineTicketByID",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/AirlineTicketByID`,
        { ID: ID }
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airline tickit PDF:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
