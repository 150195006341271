import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveInactiveHotel,
  createHotel,
  deleteHotel,
  getAllActiveHotels,
  getAllHotels,
  getHotelByID,
  updateHotel,
} from "./services/hotel.services";
import { IHotel, IHotelDetails, IHotelState } from "./hotelModel";

const initialState: IHotelState = {
  loading: false,
  error: "",
  HotelID: null,
  HotelList: [],
  HotelDetail: {
    HotelName: "",
    HotelCode: "",
    HotelDisplayName: "",
    HotelAddress: "",
    LandMark: "",
    CountryID: null,
    StateID: null,
    CityID: null,
    SectorID: null,
    LatitudeOfHotel: "",
    LongitudeOfHotel: "",
    MapURL: "",
    StarRating: null,
    PropertyType: "",
    Inclusions: "",
    Exclusions: "",
    HotelInformation: "",
    VendorID: null,
    IsActive: 1,
    HotelContacts: [],
  },
  deleteIndex: -1,
};

const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    clearHotelDetails: (state) => {
      state.HotelDetail = initialState.HotelDetail;
    },
    setHotelID: (state, action) => {
      state.HotelID = action.payload;
    },
    clearHotelID: (state) => {
      state.HotelID = initialState.HotelID;
    },
    setHotelContactDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHotels.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllHotels.fulfilled,
      (state, action: PayloadAction<IHotel[]>) => {
        state.loading = false;
        state.HotelList = action.payload || [];
      }
    );
    builder.addCase(getAllHotels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelList = [];
    });
    builder.addCase(getAllActiveHotels.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveHotels.fulfilled,
      (state, action: PayloadAction<IHotel[]>) => {
        state.loading = false;
        state.HotelList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveHotels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.HotelList = [];
    });
    builder.addCase(createHotel.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createHotel.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createHotel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(updateHotel.pending, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateHotel.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateHotel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteHotel.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteHotel.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteHotel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getHotelByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getHotelByID.fulfilled,
      (state, action: PayloadAction<IHotelDetails>) => {
        state.loading = false;
        state.HotelDetail = action.payload;
      }
    );
    builder.addCase(getHotelByID.rejected, (state, action) => {
      state.loading = false;
      state.HotelDetail = initialState.HotelDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveHotel.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveHotel.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveHotel.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearHotelDetails,
  setHotelID,
  clearHotelID,
  setHotelContactDeleteIndex,
} = hotelSlice.actions;
export default hotelSlice.reducer;
