import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ITourBooking,
  ITourBookingInitialState,
  ITourBookingPlace,
  ITourList,
} from "./tourBookingModel";
import {
  createTourBooking,
  createTouristInfo,
  deleteTourBooking,
  getAllTourBookings,
  getCheckHotelBlockRoom,
  getGetPaxTourCostWithName,
  getMonthWiseTourDatesByTourID,
  getTourBookingByID,
  getTourBySectorIDAndTravelType,
  getTourCost,
  getTourPlacesByTourID,
  updateTourBooking,
} from "./services/tourBooking.services";

const initialState: ITourBookingInitialState = {
  loading: false,
  error: "",
  TourBookingList: [],
  TourDateCodeDetails: {},
  TouristPaxDetail: {
    ID: null,
    RoomPaxes: [],
  },
  TouristPaxInfo: [],
  TourBookingID: null,
  ActiveStep: 0,
  deleteIndex: -1,
  TourBookingDetail: {
    IsActive: true,
    IsLTCTour: true,
    SectorID: null,
    SubSectorID: null,
    TourID: null,
    RoomTypeID: null,
    CompanyID: null,
    TravelType: "",
    TourMonth: "",
    TourDate: "",
    TourDateCode: "",
    TourStartDate: "",
    NoOfNights: null,
    TourEndDate: "",
    FirstName: "",
    LastName: "",
    MobileNo: "",
    EmailID: "",
    OTP: "",
    Address: "",
    CityName: "",
    CityID: null,
    StateID: null,
    CountryID: null,
    PinCode: "",
    MobileNoDuringTravelling: "",
    ResidentPhoneNo: "",
    EmergencyNo: "",
    PANCardNo: "",
    PassportNo: "",
    AadharNo: "",
    PickUpDate: "",
    PickUpPlace: "",
    PickUpTime: "",
    DropDate: "",
    DropPlace: "",
    DropTime: "",
    PageNo: null,
    Pagesize: null,
    IsDelete: true,
    SMS: "",
    EmployeeMobileNo: "",
    EmailTemplate: "",
    EmailSubject: "",
    TourPlaceBooking: [],
    TotalNoOfRooms: null,
    TotalNoOfSeats: null,
    VehicleSharingPaxID: null,
    SpecialityTypeID: null,
    OldTourBookingNo: "",
    IsGST: false,
    IsTCS: false,
    TotalAmount: 0,
    TotalAdults: null,
    TotalExtraAdults: null,
    TotalCWB: null,
    TotalCNB: null,
    TotalInfants: null,
    TotalNoOfPax: null,
    TotalCost: 0,
    VehicleCost: 0,
    DiscountType: 1,
    DiscountValue: 0,
    DiscountAmount: null,
    AdminDiscountAmount: 0,
    TotalDiscountAmount: null,
    TotalTCSAmount: 0,
    TotalGSTAmount: 0,
    TotalExtraCost: 0,
    Kasar: 0,
    ExtraBed: null,
    ServiceChargeAmount: 0,
    PaxTypeList: [],
    VehicleSharing: "",
    GroupBookingNo: "",
    WhatsappTemplate: "",
    FoodOptionID: null,
    Remarks: "",
  },
  TourBookingCostDetails: {
    TotalAmount: null,
    SectorType: "",
    PPRate: null,
    ThreeSharingRate: null,
    FourSharingRate: null,
    ExtraAdultRate: null,
    CNBRate: null,
    CWBRate: null,
    InfantRate: null,
  },
  TourBookingPlaces: [],
  TourBookingDataListDetails: {
    NoOfNights: null,
    GSTPer: 0,
    TourCode: "",
    SectorType: "",
    TCSPer: 0,
    IsApplyOnServiceTax: false,
    MonthData: [],
    PlaceData: [],
    PickupPlaceData: [],
    DropPlaceData: [],
    TourSpecialityData: [],
    RoomTypeData: [],
    VehicleSharingData: [],
  },
  TourList: [],
};

const tourBookingSlice = createSlice({
  name: "tourBooking",
  initialState,
  reducers: {
    setTourBookingID: (state, action) => {
      state.TourBookingID = action.payload;
    },
    clearTourBookingDetails: (state) => {
      state.TourBookingDetail = initialState.TourBookingDetail;
    },
    clearTourBookingCostDetails: (state) => {
      state.TourBookingCostDetails = initialState.TourBookingCostDetails;
    },
    clearTourBookingID: (state) => {
      state.TourBookingID = initialState.TourBookingID;
    },
    setTourBookingActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearTourBookingActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseTourBookingActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseTourBookingActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setTourBookingDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTourBookings.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTourBookings.fulfilled,
      (state, action: PayloadAction<ITourBooking[]>) => {
        state.loading = false;
        state.TourBookingList = action.payload || [];
      }
    );
    builder.addCase(getAllTourBookings.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingList = [];
    });
    builder.addCase(createTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(createTouristInfo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTouristInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTouristInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourBooking.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourBookingByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourBookingByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingDetail = action.payload;
    });
    builder.addCase(getTourBookingByID.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingDetail = initialState.TourBookingDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getCheckHotelBlockRoom.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getCheckHotelBlockRoom.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourDateCodeDetails = action.payload;
    });
    builder.addCase(getCheckHotelBlockRoom.rejected, (state, action) => {
      state.loading = false;
      state.TourDateCodeDetails = initialState.TourDateCodeDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourCost.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourCost.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourBookingCostDetails = action.payload;
    });
    builder.addCase(getTourCost.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingCostDetails = initialState.TourBookingCostDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourPlacesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourPlacesByTourID.fulfilled,
      (state, action: PayloadAction<ITourBookingPlace[]>) => {
        state.loading = false;
        state.TourBookingPlaces = action.payload || [];
      }
    );
    builder.addCase(getTourPlacesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourBookingPlaces = [];
    });
    builder.addCase(getMonthWiseTourDatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getMonthWiseTourDatesByTourID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourBookingDataListDetails = action.payload;
      }
    );
    builder.addCase(getMonthWiseTourDatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourBookingDataListDetails =
        initialState.TourBookingDataListDetails;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getGetPaxTourCostWithName.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGetPaxTourCostWithName.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TouristPaxInfo = action.payload;
      }
    );
    builder.addCase(getGetPaxTourCostWithName.rejected, (state, action) => {
      state.loading = false;
      state.TouristPaxInfo = initialState.TouristPaxInfo;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourBySectorIDAndTravelType.pending, (state) => {
      // state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourBySectorIDAndTravelType.fulfilled,
      (state, action: PayloadAction<ITourList[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(
      getTourBySectorIDAndTravelType.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.TourList = [];
      }
    );
  },
});

export const {
  setTourBookingID,
  clearTourBookingID,
  clearTourBookingDetails,
  clearTourBookingCostDetails,
  setTourBookingActiveStep,
  clearTourBookingActiveStep,
  increaseTourBookingActiveStep,
  decreaseTourBookingActiveStep,
  setTourBookingDeleteIndex,
} = tourBookingSlice.actions;
export default tourBookingSlice.reducer;
