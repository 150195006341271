import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import RippleButton from "../../components/common/RippleButton";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import { ISector } from "../sector/sectorModel";
import { getAllActiveSectors } from "../sector/services/sector.services";
import {
  createHotelBlock,
  getTourBySectorID,
  getHotelListByTour,
  getTourDates,
  updateHotelBlock,
  getHotelBlockByID,
} from "./services/hotelBlock.services";
import moment from "moment";
import HotelRoomData from "./HotelRoomData";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextArea from "../../components/formFields/FormTextArea";
import { ITourDateList, ITourList } from "./hotelBlockModel";
import { getCityBySectorID } from "../city/services/city.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const SectorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = useRef(true);
  const SectorID = formRenderProps.valueGetter("SectorID");
  useEffect(() => {
    if (SectorID) {
      dispatch(getTourBySectorID(SectorID));
      dispatch(getCityBySectorID(SectorID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourID", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [SectorID]);
  return null;
};

const HotelListChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isHotelListRef = useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");
  const HotelBlockDetail = useAppSelector(
    (state) => state.hotelBlock.HotelBlockDetail
  );
  const HotelBlockID = location.state?.HotelBlockID;

  useEffect(() => {
    if (TourID) {
      dispatch(getTourDates(TourID));
      if (!HotelBlockID) {
        dispatch(getHotelListByTour(TourID));
      }
    }
    if (!isHotelListRef.current) {
      formRenderProps.onChange("TourDateID", {
        value: null,
      });
    } else {
      isHotelListRef.current = false;
    }
  }, [TourID]);

  useEffect(() => {
    const roomDataCopy = JSON.parse(
      JSON.stringify(HotelBlockDetail?.RoomData || [])
    );
    formRenderProps.onChange("RoomData", {
      value: roomDataCopy || [],
    });
  }, [HotelBlockDetail?.RoomData]);

  return null;
};

const AdvanceAmountChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TotalAmount = formRenderProps.valueGetter("TotalAmount");
  const AdvanceAmount = formRenderProps.valueGetter("AdvanceAmount");
  useEffect(() => {
    formRenderProps.onChange("RemainingAmount", {
      value: TotalAmount - AdvanceAmount,
    });
  }, [AdvanceAmount, TotalAmount]);
  return null;
};

const TotalNoOfRoomsChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TotalNoOfRooms = formRenderProps.valueGetter("TotalNoOfRooms");
  const RoomData = formRenderProps.valueGetter("RoomData");
  useEffect(() => {
    if (RoomData && RoomData.length > 0) {
      RoomData.map((item: any, index: number) => {
        formRenderProps.onChange(`RoomData.${index}.NoOfRooms`, {
          value: TotalNoOfRooms,
        });
      });
    }
  }, [TotalNoOfRooms]);
  return null;
};

const TourDatechangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const TourDateList = useAppSelector((state) => state.hotelBlock.TourDateList);
  const TourDateID = formRenderProps.valueGetter("TourDateID");

  const RoomData = formRenderProps.valueGetter("RoomData");
  const TourDate = TourDateList.filter(
    (item: any) => +item.ID === +TourDateID
  )[0]?.TourDate;

  useEffect(() => {
    if (TourDateID) {
      formRenderProps.onChange("RoomData[0].CheckInDate", {
        value: moment(TourDate, "DD/MM/YYYY").toDate(),
      });
    }
  }, [TourDateID, TourDateList]);

  useEffect(() => {
    if (TourDateID && RoomData && RoomData.length > 0) {
      RoomData.map((item: any, index: number) => {
        const LastCheckOutDate = formRenderProps.valueGetter(
          `RoomData.${index - 1}.CheckOutDate`
        );
        if (index > 0) {
          formRenderProps.onChange(`RoomData.${index}.CheckInDate`, {
            value: moment(LastCheckOutDate).toDate(),
          });
        }
        const CheckOutDate = moment(item.CheckInDate)
          .add(+item?.NoOfNights || 0, "days")
          .toDate();
        formRenderProps.onChange(`RoomData.${index}.CheckOutDate`, {
          value: CheckOutDate,
        });
      });
    }
  }, [
    RoomData.map((item: any) => item.NoOfNights).join("-"),
    RoomData.map((item: any) => item.CheckInDate).join("-"),
    TourDateID,
  ]);

  return null;
};

const CreateHotelBlock: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const HotelBlockID = location.state?.HotelBlockID;
  const gridRef = useRef<any>(null);

  const loading = useAppSelector((state) => state.hotelBlock.loading);
  const SectorList = useAppSelector((state) => state.sector.SectorList);
  const TourList = useAppSelector((state) => state.hotelBlock.TourList);
  const TourDateList = useAppSelector((state) => state.hotelBlock.TourDateList);
  const HotelBlockDetail = useAppSelector(
    (state) => state.hotelBlock.HotelBlockDetail
  );

  useEffect(() => {
    dispatch(getAllActiveSectors());
  }, []);

  useEffect(() => {
    if (HotelBlockID) {
      dispatch(getHotelBlockByID(HotelBlockID));
    }
  }, [HotelBlockID]);

  const handleSubmit = async (values: any) => {
    if (HotelBlockID) {
      const editPayload = {
        ID: HotelBlockID,
        SectorID: values.SectorID,
        TourID: values.TourID,
        TourDateID: values.TourDateID,
        TotalNoOfRooms: +values.TotalNoOfRooms || 0,
        TotalAmount: +values.TotalAmount || 0,
        AdvanceAmount: +values.AdvanceAmount || 0,
        RemainingAmount: +values.RemainingAmount || 0,
        ChequeNo: values.ChequeNo ? values.ChequeNo : "",
        BankName: values.BankName ? values.BankName : "",
        ChequeDate: values.ChequeDate
          ? moment(values.ChequeDate).format("YYYY-MM-DD")
          : null,
        Remarks: values.Remarks ? values.Remarks : "",
        IsActive: values.IsActive ? values.IsActive : false,
        RoomData: values.RoomData
          ? values.RoomData.map((room: any) => {
              return {
                MealPlanID: room.MealPlanID,
                CityID: room.CityID,
                HotelID: room.HotelID,
                SupplierID: room.SupplierID,
                RoomTypeID: room.RoomTypeID,
                NoOfRooms: room.NoOfRooms,
                NoOfNights: room.NoOfNights,
                CheckInDate: room.CheckInDate
                  ? moment(room.CheckInDate).format("YYYY-MM-DD")
                  : "",
                CheckOutDate: room.CheckOutDate
                  ? moment(room.CheckOutDate).format("YYYY-MM-DD")
                  : "",
              };
            })
          : [],
      };
      try {
        const response = await dispatch(updateHotelBlock(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/hotelblock");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      const insertPayload = {
        SectorID: values.SectorID,
        TourID: values.TourID,
        TourDateID: values.TourDateID,
        TotalNoOfRooms: +values.TotalNoOfRooms || 0,
        TotalAmount: +values.TotalAmount || 0,
        AdvanceAmount: +values.AdvanceAmount || 0,
        RemainingAmount: +values.RemainingAmount || 0,
        ChequeNo: values.ChequeNo ? values.ChequeNo : "",
        BankName: values.BankName ? values.BankName : "",
        ChequeDate: values.ChequeDate
          ? moment(values.ChequeDate).format("YYYY-MM-DD")
          : null,
        Remarks: values.Remarks ? values.Remarks : "",
        IsActive: values.IsActive ? values.IsActive : true,
        RoomData: values.RoomData
          ? values.RoomData.map((room: any) => {
              return {
                MealPlanID: room.MealPlanID,
                CityID: room.CityID,
                HotelID: room.HotelID,
                SupplierID: room.SupplierID,
                RoomTypeID: room.RoomTypeID,
                NoOfRooms: +room.NoOfRooms || 0,
                NoOfNights: +room.NoOfNights || 0,
                CheckInDate: room.CheckInDate
                  ? moment(room.CheckInDate).format("YYYY-MM-DD")
                  : "",
                CheckOutDate: room.CheckOutDate
                  ? moment(room.CheckOutDate).format("YYYY-MM-DD")
                  : "",
              };
            })
          : [],
      };
      try {
        const response = await dispatch(createHotelBlock(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate(-1);
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={HotelBlockDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <HotelListChangeWatcher formRenderProps={formRenderProps} />
            <SectorChangeWatcher formRenderProps={formRenderProps} />
            <AdvanceAmountChangeWatcher formRenderProps={formRenderProps} />
            <TourDatechangeWatcher formRenderProps={formRenderProps} />
            <TotalNoOfRoomsChangeWatcher formRenderProps={formRenderProps} />
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {HotelBlockID ? "Update Hotel Block" : "Add Hotel Block"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"SectorID"}
                    name={"SectorID"}
                    label={"Sector"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={SectorList?.map((sector: ISector) => {
                      return {
                        value: sector?.ID,
                        label: sector?.SectorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    id={"TourID"}
                    name={"TourID"}
                    label={"Tour"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TourList.map((tour: ITourList) => {
                      return {
                        value: tour.TourID,
                        label: tour.TourName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name={"TourDateID"}
                    label={"Tour Date"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={TourDateList.map((tourDates: ITourDateList) => {
                      return {
                        value: tourDates.ID,
                        label: tourDates.TourDate,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="TotalNoOfRooms"
                    type="number"
                    label="Total No Of Rooms"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            {formRenderProps.valueGetter("RoomData") &&
              formRenderProps.valueGetter("RoomData")?.length > 0 && (
                <ShadowCard style={{ padding: 12, marginTop: 10 }}>
                  <FieldArray
                    formRenderProps={formRenderProps}
                    component={HotelRoomData}
                    name="RoomData"
                  />
                </ShadowCard>
              )}
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Price Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="TotalAmount"
                    maxLength="100"
                    type="number"
                    label="Total Amount"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="AdvanceAmount"
                    maxLength="100"
                    type="number"
                    label="Advance Amount"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="RemainingAmount"
                    type="number"
                    disable={"true"}
                    label="Remaining Amount"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3} style={{ marginTop: 40 }}>
                  <Typography.h4>Cheque Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="ChequeNo"
                    label="Cheque No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="BankName"
                    label="Bank Name"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }}>
                  <Field
                    name="ChequeDate"
                    label="Cheque Date"
                    format={"dd/MM/yyyy"}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem style={{ width: "100%" }} colSpan={3}>
                  <Field
                    name="Remarks"
                    label="Remarks"
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={3}
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <RippleButton
                    fillMode="outline"
                    themeColor="primary"
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/hotelblock")}
                  >
                    Cancel
                  </RippleButton>
                  <ButtonWithLoading
                    label={HotelBlockID ? "Update" : "Add"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateHotelBlock;
