import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import React, { useEffect } from "react";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import IconButton from "../../components/common/IconButton";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormTextField from "../../components/formFields/FormTextField";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { setReturnSegmentDeleteIndex } from "./airlineVoucherSlice";
import { getAllActiveVendors } from "../vendor/services/vendor.services";
import { IVendor } from "../vendor/vendorModel";
import { IAirline } from "../airline/airlineModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { getAllActiveAirlines } from "../airline/services/airline.services";
import { IFlightPlace } from "../flightplace/flightplaceModel";
import { getAllActiveFlightPlaces } from "../flightplace/services/flightplace.services";
import { requiredValidator } from "../../components/formFields/CommonValidator";

export const JOURNEY_TYPE = [
  { label: "DEPARTURE", value: "DEPARTURE" },
  { label: "RETURN", value: "RETURN" },
];
export const FLIGHT_CLASS = [
  { label: "BUSINESS", value: 1 },
  { label: "ECONOMY", value: 2 },
];

const ReturnSegmentArray = (
  fieldArrayRenderProps: FieldArrayRenderProps,
  AirlineVoucherID: any
) => {
  const dispatch = useAppDispatch();
  const name = fieldArrayRenderProps.name;

  const segmentDeleteIndex = useAppSelector(
    (state) => state.airlineVoucher.returnDeleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const VendorList = useAppSelector((state) => state.vendor.VendorList);
  const AirlineList = useAppSelector((state) => state.airline.AirlineList);
  const FlightPlaceList = useAppSelector(
    (state) => state.flightplace.FlightPlaceList
  );

  useEffect(() => {
    dispatch(getAllActiveVendors());
    dispatch(getAllActiveAirlines());
    dispatch(getAllActiveFlightPlaces());

    return () => {
      dispatch(closeDialog());
    };
  }, []);

  const Journey =
    fieldArrayRenderProps?.formRenderProps?.valueGetter("Journey");
  useEffect(() => {
    if (Journey === "ONE WAY" || Journey === "MULTI WAY") {
      if (!AirlineVoucherID || fieldArrayRenderProps.value.length < 1) {
        pushElementInarray();
      }
    }
  }, [Journey]);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("returnSegmentDeleteDialog"));
    dispatch(setReturnSegmentDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setReturnSegmentDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        VendorID: null,
        AirlineID: null,
        FlightNo: "",
        JourneyType: "",
        FromAirportID: null,
        ToAirportID: null,
        PNRNo: "",
        TotalPrice: 0,
        DepartureDate: "",
        DepartureTime: "",
        ArrivalDate: "",
        ArrivalTime: "",
        Boarding: "",
        ClassID: null,
      },
    });
  }, [fieldArrayRenderProps]);

  return (
    <GridLayout gap={{ rows: 10, cols: 10 }} cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Segment
        </Button>
      </GridLayoutItem>
      {dialogName === "returnSegmentDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Segment"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(segmentDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map(
          (airlineVoucherSegments: any, index: number) => (
            <GridLayoutItem key={index}>
              <Card
                className="fieldarray-card"
                style={{
                  padding: "20px",
                }}
              >
                <GridLayout
                  style={{ marginRight: 50 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "16.66%" },
                    { width: "16.66%" },
                    { width: "16.66%" },
                    { width: "16.66%" },
                    { width: "16.66%" },
                    { width: "16.66%" },
                  ]}
                >
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.VendorID`}
                      label="Vendor"
                      component={FormSelectionField}
                      options={VendorList?.map((vendor: IVendor) => {
                        return {
                          value: vendor?.ID,
                          label: vendor?.VendorName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.JourneyType`}
                      label="Journey Type"
                      component={FormSelectionField}
                      options={JOURNEY_TYPE?.map((journeytype: any) => {
                        return {
                          value: journeytype?.value,
                          label: journeytype?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.AirlineID`}
                      label="Airline"
                      component={FormSelectionField}
                      options={AirlineList?.map((airline: IAirline) => {
                        return {
                          value: airline?.ID,
                          label: airline?.AirlineName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.FlightNo`}
                      label="Flight No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.FromAirportID`}
                      label="From"
                      component={FormSelectionField}
                      options={FlightPlaceList?.map(
                        (flightplace: IFlightPlace) => {
                          return {
                            value: flightplace?.ID,
                            label: flightplace?.FlightPlaceName,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.ToAirportID`}
                      label="To"
                      component={FormSelectionField}
                      options={FlightPlaceList?.map(
                        (flightplace: IFlightPlace) => {
                          return {
                            value: flightplace?.ID,
                            label: flightplace?.FlightPlaceName,
                          };
                        }
                      )}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.PNRNo`}
                      label="PNR No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.DepartureDate`}
                      label="Departure Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.DepartureTime`}
                      label={"Departure Time"}
                      component={FormTimePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.ArrivalDate`}
                      label="Arrival Date"
                      format="dd/MM/yyyy"
                      component={FormDatePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.ArrivalTime`}
                      label={"Arrival Time"}
                      component={FormTimePicker}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.Boarding`}
                      label={"Boarding"}
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      validator={requiredValidator}
                      name={`${name}.${index}.ClassID`}
                      label="Class"
                      component={FormSelectionField}
                      options={FLIGHT_CLASS?.map((flightclass: any) => {
                        return {
                          value: flightclass?.value,
                          label: flightclass?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name={`${name}.${index}.TotalPrice`}
                      label={"Total Price"}
                      type="number"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    style={{
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(index)}
                      disabled={fieldArrayRenderProps.value.length === 1}
                      themeColor={"error"}
                      fillMode={"solid"}
                      type="button"
                      style={{
                        height: 40,
                        width: 40,
                        position: "absolute",
                        top: 43,
                        left: 28,
                      }}
                    >
                      <MdDelete
                        className="absolute-position"
                        style={{ fontSize: "20px" }}
                      />
                    </IconButton>
                  </GridLayoutItem>
                </GridLayout>
              </Card>
            </GridLayoutItem>
          )
        )}
    </GridLayout>
  );
};

export default ReturnSegmentArray;
