import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { getAllActiveAirlines } from "../airline/services/airline.services";
import { getAllActiveAirlineClass } from "../airlineclass/services/airlineclass.services";
import { getAllActiveFlightPlaces } from "../flightplace/services/flightplace.services";
import { IAirline } from "../airline/airlineModel";
import { IFlightPlace } from "../flightplace/flightplaceModel";
import FormTimePicker from "../../components/formFields/FormTimeField";
import { IAirlineClass } from "../airlineclass/airlineclassModel";
import FormDatePicker from "../../components/formFields/FormDateField";
import { getAllActiveVendors } from "../vendor/services/vendor.services";
import { IVendor } from "../vendor/vendorModel";
import { ISelectionType, JOURNEYTYPE } from "../../_contstants/common";
import FormTextArea from "../../components/formFields/FormTextArea";
import AirlineBlockDateArray from "./AirlineBlockDateArray";
import moment from "moment";
import { clearAirlineBlockDetails } from "./airlineblockSlice";
import {
  createAirlineBlock,
  getAirlineBlockByID,
  updateAirlineBlock,
} from "./services/airlineblock.services";

const CreateAirlineBlock: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const AirlineBlockID = location.state?.AirlineBlockID;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.airlineblock.loading);
  const AirlineBlockDetails = useAppSelector(
    (state) => state.airlineblock.AirlineBlockDetails
  );
  const AirlineList = useAppSelector((state) => state.airline.AirlineList);
  const VendorList = useAppSelector((state) => state.vendor.VendorList);
  const AirlineClassList = useAppSelector(
    (state) => state.airlineclass.AirlineClassList
  );
  const FlightPlaceList = useAppSelector(
    (state) => state.flightplace.FlightPlaceList
  );

  useEffect(() => {
    dispatch(getAllActiveAirlines());
    dispatch(getAllActiveAirlineClass());
    dispatch(getAllActiveFlightPlaces());
    dispatch(getAllActiveVendors());
    if (AirlineBlockID) {
      dispatch(getAirlineBlockByID(AirlineBlockID));
    }
    return () => {
      dispatch(clearAirlineBlockDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    if (AirlineBlockID) {
      try {
        const editPayload = {
          ID: values.ID,
          Title: values.Title ? values.Title : "",
          AirlineID: values.AirlineID ? +values.AirlineID : null,
          FlightNo: values.FlightNo ? values.FlightNo : "",
          DepartureAirportID: values.DepartureAirportID
            ? +values.DepartureAirportID
            : null,
          DepartureTime: values.DepartureTime
            ? moment(values.DepartureTime).format("HH:mm:ss")
            : "",
          ClassID: values.ClassID ? +values.ClassID : null,
          ArrivalAirportID: values.ArrivalAirportID
            ? +values.ArrivalAirportID
            : null,
          ArrivalTime: values.ArrivalTime
            ? moment(values.ArrivalTime).format("HH:mm:ss")
            : "",
          TotalNoOfSeats: values.TotalNoOfSeats ? +values.TotalNoOfSeats : null,
          DateOfBooking: values.DateOfBooking
            ? moment(values.DateOfBooking).format("YYYY-MM-DD")
            : "",
          JourneyType: values.JourneyType ? values.JourneyType : "",
          PurchasePrice: values.PurchasePrice ? +values.PurchasePrice : null,
          Remarks: values.Remarks ? values.Remarks : "",
          VendorID: values.VendorID ? +values.VendorID : null,
          DepartureTerminal: values.DepartureTerminal
            ? values.DepartureTerminal
            : "",
          StopageAirportID: values.StopageAirportID
            ? +values.StopageAirportID
            : null,
          StopageFlightNo: values.StopageFlightNo ? values.StopageFlightNo : "",
          StopageArrivalTime: values.StopageArrivalTime
            ? moment(values.StopageArrivalTime).format("HH:mm:ss")
            : "",
          StopageDepartureTime: values.StopageDepartureTime
            ? moment(values.StopageDepartureTime).format("HH:mm:ss")
            : "",
          StopageTerminal: values.StopageTerminal ? values.StopageTerminal : "",
          ArrivalTerminal: values.ArrivalTerminal ? values.ArrivalTerminal : "",
          BlockDatesDetails: values.BlockDatesDetails
            ? values.BlockDatesDetails.map((tourDate: any) => {
                return {
                  ID: tourDate.ID ? +tourDate.ID : null,
                  ArrivalDate: tourDate.ArrivalDate
                    ? moment(tourDate.ArrivalDate).format("YYYY-MM-DD")
                    : "",
                  DepartureDate: tourDate.DepartureDate
                    ? moment(tourDate.DepartureDate).format("YYYY-MM-DD")
                    : "",
                  StopageArrivalDate: tourDate.StopageArrivalDate
                    ? moment(tourDate.StopageArrivalDate).format("YYYY-MM-DD")
                    : "",
                  StopageDepartureDate: tourDate.StopageDepartureDate
                    ? moment(tourDate.StopageDepartureDate).format("YYYY-MM-DD")
                    : "",
                  PNRNo: tourDate.PNRNo ? tourDate.PNRNo : "",
                  TotalSeats: tourDate.TotalSeats ? +tourDate.TotalSeats : null,
                  SalesAmount: tourDate.SalesAmount
                    ? +tourDate.SalesAmount
                    : null,
                  DepartureTerminal: tourDate.DepartureTerminal
                    ? tourDate.DepartureTerminal
                    : "",
                  StopageTerminal: tourDate.StopageTerminal
                    ? tourDate.StopageTerminal
                    : "",
                  ArrivalTerminal: tourDate.ArrivalTerminal
                    ? tourDate.ArrivalTerminal
                    : "",
                };
              })
            : [],
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(updateAirlineBlock(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/airlineblock");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload = {
          ID: values.ID,
          Title: values.Title ? values.Title : "",
          AirlineID: values.AirlineID ? +values.AirlineID : null,
          FlightNo: values.FlightNo ? values.FlightNo : "",
          DepartureAirportID: values.DepartureAirportID
            ? +values.DepartureAirportID
            : null,
          DepartureTime: values.DepartureTime
            ? moment(values.DepartureTime).format("HH:mm:ss")
            : "",
          ClassID: values.ClassID ? +values.ClassID : null,
          ArrivalAirportID: values.ArrivalAirportID
            ? +values.ArrivalAirportID
            : null,
          ArrivalTime: values.ArrivalTime
            ? moment(values.ArrivalTime).format("HH:mm:ss")
            : "",
          // TotalNoOfSeats: values.TotalNoOfSeats ? +values.TotalNoOfSeats : null,
          DateOfBooking: values.DateOfBooking
            ? moment(values.DateOfBooking).format("YYYY-MM-DD")
            : "",
          JourneyType: values.JourneyType ? values.JourneyType : "",
          PurchasePrice: values.PurchasePrice ? +values.PurchasePrice : null,
          Remarks: values.Remarks ? values.Remarks : "",
          VendorID: values.VendorID ? +values.VendorID : null,
          DepartureTerminal: values.DepartureTerminal
            ? values.DepartureTerminal
            : "",
          StopageAirportID: values.StopageAirportID
            ? +values.StopageAirportID
            : null,
          StopageFlightNo: values.StopageFlightNo ? values.StopageFlightNo : "",
          StopageArrivalTime: values.StopageArrivalTime
            ? moment(values.StopageArrivalTime).format("HH:mm:ss")
            : "",
          StopageDepartureTime: values.StopageDepartureTime
            ? moment(values.StopageDepartureTime).format("HH:mm:ss")
            : "",
          StopageTerminal: values.StopageTerminal ? values.StopageTerminal : "",
          ArrivalTerminal: values.ArrivalTerminal ? values.ArrivalTerminal : "",
          BlockDatesDetails: values.BlockDatesDetails
            ? values.BlockDatesDetails.map((tourDate: any) => {
                return {
                  ID: tourDate.ID ? +tourDate.ID : null,
                  ArrivalDate: tourDate.ArrivalDate
                    ? moment(tourDate.ArrivalDate).format("YYYY-MM-DD")
                    : "",
                  DepartureDate: tourDate.DepartureDate
                    ? moment(tourDate.DepartureDate).format("YYYY-MM-DD")
                    : "",
                  StopageArrivalDate: tourDate.StopageArrivalDate
                    ? moment(tourDate.StopageArrivalDate).format("YYYY-MM-DD")
                    : "",
                  StopageDepartureDate: tourDate.StopageDepartureDate
                    ? moment(tourDate.StopageDepartureDate).format("YYYY-MM-DD")
                    : "",
                  PNRNo: tourDate.PNRNo ? tourDate.PNRNo : "",
                  TotalSeats: tourDate.TotalSeats ? +tourDate.TotalSeats : null,
                  SalesAmount: tourDate.SalesAmount
                    ? +tourDate.SalesAmount
                    : null,
                  DepartureTerminal: tourDate.DepartureTerminal
                    ? tourDate.DepartureTerminal
                    : "",
                  StopageTerminal: tourDate.StopageTerminal
                    ? tourDate.StopageTerminal
                    : "",
                  ArrivalTerminal: tourDate.ArrivalTerminal
                    ? tourDate.ArrivalTerminal
                    : "",
                };
              })
            : [],
          IsActive: values.IsActive ? values.IsActive : true,
        };
        const response = await dispatch(createAirlineBlock(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/airlineblock");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        onSubmit={handleSubmit}
        initialValues={AirlineBlockDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4 style={{ margin: 0 }}>
                    {AirlineBlockID
                      ? "Update Airline Block"
                      : "Create Airline Block"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"AirlineID"}
                    name={"AirlineID"}
                    label={"Airline"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={AirlineList.map((airline: IAirline) => {
                      return {
                        value: airline.ID,
                        label: airline.AirlineName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="Title"
                    label="Title"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="FlightNo"
                    label="Flight No"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"DepartureAirportID"}
                    name={"DepartureAirportID"}
                    label={"Departure Airport"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={FlightPlaceList.map((airport: IFlightPlace) => {
                      return {
                        value: airport.ID,
                        label: airport.FlightPlaceName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"DepartureTime"}
                    name={"DepartureTime"}
                    label={"Departure Time"}
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"ClassID"}
                    name={"ClassID"}
                    label={"Class"}
                    component={FormSelectionField}
                    options={AirlineClassList.map((airclass: IAirlineClass) => {
                      return {
                        value: airclass.ID,
                        label: airclass.ClassName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"StopageAirportID"}
                    name={"StopageAirportID"}
                    label={"Stopage Airport"}
                    component={FormSelectionField}
                    options={FlightPlaceList.map((airport: IFlightPlace) => {
                      return {
                        value: airport.ID,
                        label: airport.FlightPlaceName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="StopageFlightNo"
                    label="Stopage Flight No"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  style={{ width: "100%", display: "flex", gap: 10 }}
                >
                  <Field
                    wrapperClassName="w-100"
                    id={"StopageArrivalTime"}
                    name={"StopageArrivalTime"}
                    label={"Stopage Arrival Time"}
                    component={FormTimePicker}
                  />
                  <Field
                    wrapperClassName="w-100"
                    id={"StopageDepartureTime"}
                    name={"StopageDepartureTime"}
                    label={"Stopage Departure Time"}
                    component={FormTimePicker}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"ArrivalAirportID"}
                    name={"ArrivalAirportID"}
                    label={"Arrival Airport"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={FlightPlaceList.map((airport: IFlightPlace) => {
                      return {
                        value: airport.ID,
                        label: airport.FlightPlaceName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"ArrivalTime"}
                    name={"ArrivalTime"}
                    label={"Arrival Time"}
                    component={FormTimePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="TotalNoOfSeats"
                    label="Total Seats"
                    type="number"
                    disabled
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`DateOfBooking`}
                    label="Booking Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"VendorID"}
                    name={"VendorID"}
                    label={"Vendor"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={VendorList.map((vendor: IVendor) => {
                      return {
                        value: vendor.ID,
                        label: vendor.VendorName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    id={"JourneyType"}
                    name={"JourneyType"}
                    label={"Journey Type"}
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={JOURNEYTYPE.map((vendor: ISelectionType) => {
                      return {
                        value: vendor.value,
                        label: vendor.label,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="DepartureTerminal"
                    label="Departure Terminal"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="StopageTerminal"
                    label="Stopage Terminal"
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="ArrivalTerminal"
                    label="Arrival Terminal"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              {/* <GridLayoutItem style={{ marginTop: 10 }} colSpan={3}> */}
              <FieldArray
                formRenderProps={formRenderProps}
                component={AirlineBlockDateArray}
                name="BlockDatesDetails"
              />
              {/* </GridLayoutItem> */}
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={2}>
                  <Field
                    name="Remarks"
                    label="Remarks"
                    component={FormTextArea}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="PurchasePrice"
                    label="Purchase Price"
                    type="number"
                    component={FormTextField}
                  />
                  <div style={{ position: "absolute", bottom: 10, right: 10 }}>
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginRight: 4 }}
                      onClick={() => navigate("/airlineblock")}
                    >
                      Cancel
                    </Button>
                    <ButtonWithLoading
                      label={AirlineBlockID ? "Update" : "Create"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateAirlineBlock;
