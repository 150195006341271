import React, { useEffect } from "react";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { DATETYPE, TRAVELTYPEALL } from "../../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllActiveCompanies } from "../../company/services/company.services";
import { ICompany } from "../../company/companyModel";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormDatePicker from "../../../components/formFields/FormDateField";
import moment from "moment";
import {
  clearCompanyWiseBookingReportList,
  setCompanyWiseBookingReport,
} from "../reportSlice";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import ShadowCard from "../../../components/common/ShadowCard";
import { getAllActiveTours } from "../../tour/services/tour.services";
import { ITour } from "../../tour/tourModel";
import { ITourDateList } from "../../hotelblock/hotelBlockModel";
import { getTourDates } from "../../hotelblock/services/hotelBlock.services";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}

const TourChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const isTourDateRef = React.useRef(true);
  const TourID = formRenderProps.valueGetter("TourID");
  const DateType = formRenderProps.valueGetter("DateType");

  useEffect(() => {
    if (TourID) {
      dispatch(getTourDates(TourID));
    }
    if (!isTourDateRef.current) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    } else {
      isTourDateRef.current = false;
    }
  }, [TourID]);

  useEffect(() => {
    if (DateType !== 3) {
      formRenderProps.onChange("TourDate", {
        value: null,
      });
    }
    if (DateType === 3) {
      formRenderProps.onChange("FromDate", {
        value: null,
      });
      formRenderProps.onChange("ToDate", {
        value: null,
      });
    }
  }, [DateType]);

  return null;
};

const CompanyWiseBookingReportForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.reports.loading);
  const CompanyList = useAppSelector((state) => state.company.CompanyList);
  const TourList = useAppSelector((state) => state.tour.TourList);
  const TourDateList = useAppSelector((state) => state.hotelBlock.TourDateList);
  useEffect(() => {
    dispatch(getAllActiveCompanies());
    dispatch(getAllActiveTours());

    return () => {
      dispatch(clearCompanyWiseBookingReportList());
    };
  }, []);

  const handleSubmit = (values: any) => {
    const payload = {
      CompanyID: values?.CompanyID ? values?.CompanyID : null,
      FromDate: values?.FromDate
        ? moment(values?.FromDate).format("YYYY-MM-DD")
        : "0",
      ToDate: values?.ToDate
        ? moment(values?.ToDate).format("YYYY-MM-DD")
        : "0",
      DateType: values?.DateType ? values?.DateType : null,
      Type: values?.Type ? values?.Type : null,
      TourID: values?.TourID ? values?.TourID : "0",
      TourDate: values?.TourDate ? values?.TourDate : "0",
    };
    dispatch(setCompanyWiseBookingReport(payload));
    window.open(
      `companywisebookingreportview/${payload?.CompanyID}/${payload?.DateType}/${payload?.Type}/${payload.TourID}/${payload.TourDate}/${payload?.FromDate}/${payload?.ToDate}`
    );
  };

  return (
    <GridLayout gap={{ rows: 10 }} cols={[{ width: "100%" }]}>
      <GridLayoutItem>
        <ShadowCard>
          <Form
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ width: "100%" }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 10, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4
                      style={{ marginBottom: 0, marginLeft: "3px" }}
                    >
                      Company Wise Booking Report
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="DateType"
                      label="Date Type"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={DATETYPE?.map((datetype: any) => {
                        return {
                          value: datetype?.value,
                          label: datetype?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="Type"
                      label="Travel Type"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={TRAVELTYPEALL?.map((traveltype: any) => {
                        return {
                          value: traveltype?.value,
                          label: traveltype?.label,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="TourID"
                      label="Tour"
                      component={FormSelectionField}
                      options={TourList?.filter((e: ITour) => {
                        const type = formRenderProps.valueGetter("Type");
                        if (type === 1) {
                          return e.TravelType === "TOUR";
                        } else if (type === 2) {
                          return e.TravelType === "PACKAGE";
                        }
                        return true;
                      })?.map((tour: ITour) => {
                        return {
                          value: tour?.ID,
                          label: tour?.TourName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem>
                    <Field
                      name="CompanyID"
                      label="Company"
                      component={FormSelectionField}
                      validator={requiredValidator}
                      options={CompanyList?.map((company: ICompany) => {
                        return {
                          value: company?.ID,
                          label: company?.CompanyName,
                        };
                      })}
                    />
                  </GridLayoutItem>
                  {formRenderProps.valueGetter("DateType") === 3 && (
                    <GridLayoutItem style={{ width: "100%" }}>
                      <Field
                        name={"TourDate"}
                        label={"Tour Date"}
                        component={FormSelectionField}
                        validator={requiredValidator}
                        options={TourDateList?.map(
                          (tourDates: ITourDateList) => {
                            return {
                              value: `${moment(
                                tourDates?.TourDate,
                                "DD/MM/YYYY"
                              ).format("YYYY-MM-DD")}`,
                              label: tourDates?.TourDate,
                            };
                          }
                        )}
                      />
                    </GridLayoutItem>
                  )}
                  {formRenderProps.valueGetter("DateType") !== 3 && (
                    <>
                      <GridLayoutItem>
                        <Field
                          name="FromDate"
                          label="From Date"
                          format="dd/MM/yyyy"
                          component={FormDatePicker}
                          validator={requiredValidator}
                        />
                      </GridLayoutItem>
                      <GridLayoutItem>
                        <Field
                          name="ToDate"
                          label="To Date"
                          format="dd/MM/yyyy"
                          component={FormDatePicker}
                          validator={requiredValidator}
                        />
                      </GridLayoutItem>
                    </>
                  )}
                  <GridLayoutItem
                    colSpan={3}
                    style={{
                      textAlign: "end",
                    }}
                  >
                    <ButtonWithLoading
                      label={"Get Details"}
                      type="submit"
                      Size="large"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                  </GridLayoutItem>
                  <TourChangeWatcher formRenderProps={formRenderProps} />
                </GridLayout>
              </FormElement>
            )}
          />
        </ShadowCard>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default CompanyWiseBookingReportForm;
