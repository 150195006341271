import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITour, ITourCostDetails, ITourDate, ITourPlace } from "../tourModel";
import moment from "moment";

export const getAllTours = createAsyncThunk("Tour/FindAllTour", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Tour/FindAllTour`,
      {
        SectorType: "",
        TravelType: "",
        TourName: "",
        NoOfNights: null,
        IsActive: true,
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Tours:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createTour = createAsyncThunk(
  "Tour/InsertTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/InsertTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTour = createAsyncThunk(
  "Tour/UpdateTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTour = createAsyncThunk(
  "Tour/DeleteTour",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/DeleteTour`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourByID = createAsyncThunk(
  "Tour/FindByIDTour?id",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/FindByIDTour?id=${ID}`
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        SectorType: result.SectorType,
        SectorID: result.SectorID,
        SubSectorID: result.SubSectorID,
        GSTID: result.GSTID,
        TravelType: result.TravelType,
        TourName: result.TourName,
        SpecialityTypeID: result.SpecialityTypeID
          ? result.SpecialityTypeID.split(",").map((ID: any) => +ID)
          : [],
        TourCode: result.TourCode,
        CompanyID: result.CompanyID,
        NoOfNights: result.NoOfNights,
        NoOfDays: result.NoOfDays,
        SpecialRemarks: result.SpecialRemarks,
        PickupPlace: result.PickupPlace,
        PickupTime: result.PickupTime
          ? moment(result.PickupTime, "HH:mm:ss").toDate()
          : "",
        PickupRemarks: result.PickupRemarks,
        DropPlace: result.DropPlace,
        DropTime: result.DropTime
          ? moment(result.DropTime, "HH:mm:ss").toDate()
          : "",
        DropRemarks: result.DropRemarks,
        PickupFlightPlace: result.PickupFlightPlace,
        DropFlightPlace: result.DropFlightPlace,
        CreatedBy: result.CreatedBy,
        IsActive: result.IsActive ? 1 : 2,
      } as ITour;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTours = createAsyncThunk(
  "Tour/FindAllActiveTour",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/FindAllActiveTour`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching tours:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTour = createAsyncThunk(
  "Tour/ActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/ActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourDateArray = createAsyncThunk(
  "Tour/TourDateInsert",
  async (TourDateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourDateInsert`,
        TourDateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour date:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createTourPlaceArray = createAsyncThunk(
  "Tour/TourPlaceInsert",
  async (TourDateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourPlaceInsert`,
        TourDateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour place:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourDatesByTourID = createAsyncThunk(
  "Tour/TourDateListByTourID?tourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/TourDateListByTourID?tourID=${TourID}`
      );
      const result = response.data?.Data as any;
      const TourDateDetails = result.map((tourDate: any) => ({
        ID: tourDate.ID,
        TourID: tourDate.TourID,
        TourDateCode: tourDate.TourDateCode,
        Remarks: tourDate.Remarks,
        NoOfRooms: tourDate.NoOfRooms,
        EmployeeID: tourDate.EmployeeID
          ? tourDate.EmployeeID.split(",").map((ID: any) => +ID)
          : [],
        TourDate: tourDate.TourDate
          ? moment(tourDate.TourDate, "YYYY-MM-DD").toDate()
          : "",
      })) as ITourDate[];
      return TourDateDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourPlacesByTourID = createAsyncThunk(
  "TourPlaceListByTourID?tourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/TourPlaceListByTourID?tourID=${TourID}`
      );
      const result = response.data?.Data as any;
      const TourPlaceDetails = result.map((tourPlace: any) => ({
        ID: tourPlace.ID,
        TourID: tourPlace.TourID,
        HotelID: tourPlace.HotelID,
        NoOfNights: tourPlace.NoOfNights,
        PlaceID: tourPlace.PlaceID,
        Position: tourPlace.Position,
        TourDateCode: tourPlace.TourDateCode
          ? tourPlace.TourDateCode.split(",")
          : [],
      })) as ITourPlace[];
      return TourPlaceDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourRatesByTourID = createAsyncThunk(
  "Tour/TourCostListByTourID?tourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/TourCostListByTourID?tourID=${TourID}`
      );
      const result = response.data?.Data as ITourCostDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourRate = createAsyncThunk(
  "Tour/TourCostInsert",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostInsert`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourRate = createAsyncThunk(
  "Tour/TourCostUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostUpdate`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourRate = createAsyncThunk(
  "Tour/TourCostDelete?id",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/TourCostDelete?id=${ID}`
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
