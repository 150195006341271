import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute, IRouteInitialState } from "./routeModel";
import {
  ActiveInactiveRoute,
  createRoute,
  deleteRoute,
  getAllActiveRoutes,
  getAllRoutes,
  getRouteByID,
  updateRoute,
} from "./services/route.services";

const initialState: IRouteInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RouteList: [],
  RouteID: null,
  RouteDetail: {
    SectorID: null,
    RouteName: "",
    Description: "",
    IsActive: false,
  },
};

const routeSlice = createSlice({
  name: "route",
  initialState,
  reducers: {
    clearRouteDetails: (state) => {
      state.RouteDetail = initialState.RouteDetail;
    },
    setRouteID: (state, action) => {
      state.RouteID = action.payload;
    },
    clearRouteID: (state) => {
      state.RouteID = initialState.RouteID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoutes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRoutes.fulfilled,
      (state, action: PayloadAction<IRoute[]>) => {
        state.loading = false;
        state.RouteList = action.payload || [];
      }
    );
    builder.addCase(getAllRoutes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RouteList = [];
    });
    builder.addCase(createRoute.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRoute.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRoute.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoute.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRoute.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRoute.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRoute.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRouteByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRouteByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RouteDetail = action.payload;
    });
    builder.addCase(getRouteByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RouteDetail = initialState.RouteDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRoutes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRoutes.fulfilled,
      (state, action: PayloadAction<IRoute[]>) => {
        state.loading = false;
        state.RouteList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRoutes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RouteList = [];
    });
    builder.addCase(ActiveInactiveRoute.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRoute.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRoute.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { clearRouteDetails, clearRouteID, setRouteID } =
  routeSlice.actions;
export default routeSlice.reducer;
