import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAirlineBlock,
  deleteAirlineBlock,
  getAirlineBlockByID,
  getAllAirlineBlocks,
  getBlockDatesByAirlineBlockID,
  updateAirlineBlock,
} from "./services/airlineblock.services";
import {
  IAirlineBlock,
  IAirlineBlockDates,
  IAirlineBlockState,
} from "./airlineblockModel";

const initialState: IAirlineBlockState = {
  loading: false,
  error: "",
  AirlineBlockList: [],
  AirlineBlockID: null,
  deleteIndex: -1,
  AirlineBlockDetails: {
    ID: null,
    Title: "",
    AirlineID: null,
    FlightNo: "",
    DepartureAirportID: null,
    DepartureTime: "",
    ClassID: null,
    ArrivalAirportID: null,
    ArrivalTime: "",
    TotalNoOfSeats: null,
    DateOfBooking: "",
    JourneyType: "",
    PurchasePrice: null,
    Remarks: "",
    VendorID: null,
    DepartureTerminal: "",
    StopageAirportID: null,
    StopageFlightNo: "",
    StopageArrivalTime: "",
    StopageDepartureTime: "",
    StopageTerminal: "",
    ArrivalTerminal: "",
    IsActive: true,
    BlockDatesDetails: [],
  },
  AirlineBlockDates: {
    tourdates: [],
  },
};

const airlineblockSlice = createSlice({
  name: "airlineblock",
  initialState,
  reducers: {
    clearAirlineBlockDetails: (state) => {
      state.AirlineBlockDetails = initialState.AirlineBlockDetails;
    },
    clearAirlineBlockDates: (state) => {
      state.AirlineBlockDates.tourdates = [];
    },
    setAirlineBlockID: (state, action) => {
      state.AirlineBlockID = action.payload;
    },
    clearAirlineBlockID: (state) => {
      state.AirlineBlockID = initialState.AirlineBlockID;
    },
    setDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAirlineBlocks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllAirlineBlocks.fulfilled,
      (state, action: PayloadAction<IAirlineBlock[]>) => {
        state.loading = false;
        state.AirlineBlockList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllAirlineBlocks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineBlockList = [];
    });
    builder.addCase(createAirlineBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createAirlineBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAirlineBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateAirlineBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateAirlineBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateAirlineBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAirlineBlockByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getAirlineBlockByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.AirlineBlockDetails = action.payload;
    });
    builder.addCase(getAirlineBlockByID.rejected, (state, action) => {
      state.loading = false;
      state.AirlineBlockDetails = initialState.AirlineBlockDetails;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getBlockDatesByAirlineBlockID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getBlockDatesByAirlineBlockID.fulfilled,
      (state, action: PayloadAction<IAirlineBlockDates>) => {
        state.loading = false;
        state.AirlineBlockDates = action.payload;
        state.error = "";
      }
    );
    builder.addCase(getBlockDatesByAirlineBlockID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.AirlineBlockDates = initialState.AirlineBlockDates;
    });

    builder.addCase(deleteAirlineBlock.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteAirlineBlock.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteAirlineBlock.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
  },
});
export const {
  clearAirlineBlockDetails,
  clearAirlineBlockDates,
  setAirlineBlockID,
  clearAirlineBlockID,
  setDeleteIndex,
} = airlineblockSlice.actions;
export default airlineblockSlice.reducer;
