import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IAirlineBlock, IAirlineBlockDetails } from "../airlineblockModel";
import moment from "moment";

export const getAllAirlineBlocks = createAsyncThunk(
  "AirlineBlock/FindAllAirlineBlock",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/FindAllAirlineBlock`,
        {
          Limit: 100,
          Skip: 0,
        }
      );
      return response.data.Data.map((airlineblock: IAirlineBlock) => {
        return {
          ID: airlineblock.ID,
          DateOfBooking: airlineblock.DateOfBooking,
          Title: airlineblock.Title,
          FlightNo: airlineblock.FlightNo,
          DepartureAirport: airlineblock.DepartureAirport,
          DepartureAirportID: airlineblock.DepartureAirportID,
          VendorName: airlineblock.VendorName,
          VendorID: airlineblock.VendorID,
          TotalNoOfSeats: airlineblock.TotalNoOfSeats,
          IsActive: airlineblock.IsActive,
        };
      });
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airlineblock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createAirlineBlock = createAsyncThunk(
  "AirlineBlock/InsertAirlineBlock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/InsertAirlineBlock`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating airlineblock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateAirlineBlock = createAsyncThunk(
  "AirlineBlock/UpdateAirlineBlock",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/UpdateAirlineBlock`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating airlineblock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteAirlineBlock = createAsyncThunk(
  "AirlineBlock/DeleteAirlineBlock",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/DeleteAirlineBlock`,
        { ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Delete airlineblock:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAirlineBlockByID = createAsyncThunk(
  "AirlineBlock/FindByIDAirlineBlock",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/FindByIDAirlineBlock`,
        { ID }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID ? +result.ID : null,
        Title: result.Title ? result.Title : "",
        AirlineID: result.AirlineID ? +result.AirlineID : null,
        FlightNo: result.FlightNo ? result.FlightNo : "",
        DepartureAirportID: result.DepartureAirportID
          ? +result.DepartureAirportID
          : null,
        DepartureTime: result.DepartureTime
          ? moment(result.DepartureTime, "HH:mm:ss").toDate()
          : "",
        ClassID: result.ClassID ? +result.ClassID : null,
        ArrivalAirportID: result.ArrivalAirportID
          ? +result.ArrivalAirportID
          : null,
        ArrivalTime: result.ArrivalTime
          ? moment(result.ArrivalTime, "HH:mm:ss").toDate()
          : "",
        TotalNoOfSeats: result.TotalNoOfSeats ? +result.TotalNoOfSeats : null,
        DateOfBooking: result.DateOfBooking
          ? moment(result.DateOfBooking, "YYYY-MM-DD").toDate()
          : "",
        JourneyType: result.JourneyType ? result.JourneyType : "",
        PurchasePrice: result.PurchasePrice ? +result.PurchasePrice : null,
        Remarks: result.Remarks ? result.Remarks : "",
        VendorID: result.VendorID ? +result.VendorID : null,
        DepartureTerminal: result.DepartureTerminal
          ? result.DepartureTerminal
          : "",
        StopageAirportID: result.StopageAirportID
          ? +result.StopageAirportID
          : null,
        StopageFlightNo: result.StopageFlightNo ? result.StopageFlightNo : "",
        StopageArrivalTime: result.StopageArrivalTime
          ? moment(result.StopageArrivalTime, "HH:mm:ss").toDate()
          : "",
        StopageDepartureTime: result.StopageDepartureTime
          ? moment(result.StopageDepartureTime, "HH:mm:ss").toDate()
          : "",
        StopageTerminal: result.StopageTerminal ? result.StopageTerminal : "",
        ArrivalTerminal: result.ArrivalTerminal ? result.ArrivalTerminal : "",
        IsActive: true,
        BlockDatesDetails: result.BlockDatesDetails
          ? result.BlockDatesDetails.map((airlineBlock: any) => ({
              ID: airlineBlock.ID ? +airlineBlock.ID : null,
              ArrivalDate: airlineBlock.ArrivalDate
                ? moment(airlineBlock.ArrivalDate, "YYYY-MM-DD").toDate()
                : "",
              DepartureDate: airlineBlock.DepartureDate
                ? moment(airlineBlock.DepartureDate, "YYYY-MM-DD").toDate()
                : "",
              StopageArrivalDate: airlineBlock.StopageArrivalDate
                ? moment(airlineBlock.StopageArrivalDate, "YYYY-MM-DD").toDate()
                : "",
              StopageDepartureDate: airlineBlock.StopageDepartureDate
                ? moment(
                    airlineBlock.StopageDepartureDate,
                    "YYYY-MM-DD"
                  ).toDate()
                : "",
              PNRNo: airlineBlock.PNRNo ? airlineBlock.PNRNo : "",
              TotalSeats: airlineBlock.TotalSeats
                ? +airlineBlock.TotalSeats
                : null,
              SalesAmount: airlineBlock.SalesAmount
                ? +airlineBlock.SalesAmount
                : null,
              DepartureTerminal: airlineBlock.DepartureTerminal
                ? airlineBlock.DepartureTerminal
                : "",
              StopageTerminal: airlineBlock.StopageTerminal
                ? airlineBlock.StopageTerminal
                : "",
              ArrivalTerminal: airlineBlock.ArrivalTerminal
                ? airlineBlock.ArrivalTerminal
                : "",
            }))
          : [],
      } as IAirlineBlockDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching airlineblocks:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getBlockDatesByAirlineBlockID = createAsyncThunk(
  "AirlineBlock/FindByBlockDates",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/AirlineBlock/FindByBlockDates`,
        formdata
      );
      // SuccessToast(response.data?.Details || "Success");
      const result = response.data?.Data as any;
      return {
        tourdates: result.tourdates
          ? result.tourdates.map((tourdate: any) => {
              return {
                AirlineBlockDateID: tourdate.AirlineBlockDateID
                  ? tourdate.AirlineBlockDateID
                  : null,
                TourDate: tourdate.TourDate
                  ? moment(tourdate.TourDate, "YYYY-MM-DD").format("DD-MM-YYYY")
                  : "",
                AvailableSeats: tourdate.AvailableSeats
                  ? tourdate.AvailableSeats
                  : null,
              };
            })
          : [],
      };
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Block dates :", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
