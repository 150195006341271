import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getGroupRouteByID } from "./services/groupRoute.services";
import Logo from "../../assets/Images/logo.png";

const GroupRoutePrint: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const GroupRouteID = params?.GroupRouteID;
  const GroupRouteDetail = useAppSelector(
    (state) => state.groupRoute.GroupRouteDetail
  );
  // console.log("GroupRouteDetail", GroupRouteDetail);

  useEffect(() => {
    if (GroupRouteID) {
      dispatch(getGroupRouteByID(+GroupRouteID));
    }
  }, [GroupRouteID]);

  return (
    <>
      <div className="container-fluid p-5" style={{ fontSize: 12 }}>
        <div className="row">
          <div className="col-2">
            <img src={Logo} alt="" width="100%" />
          </div>
          <div className="col-7" style={{ borderLeft: "1px solid black" }}>
            <h5>RADHE KRISHNA TOURISM</h5>
            <p>
              11, Podium Floor, City Center, Chimanlal Girdharlal Rd, near
              Swastik Cross Road, Swastik Society, Navrangpura, Ahmedabad -
              380009
            </p>
            <div className="d-flex" style={{ gap: 100 }}>
              {/* <span>
                {TourBookingInvoice?.CompanyGSTNo && "(GST) "}
                {TourBookingInvoice?.CompanyGSTNo} <br />
                {TourBookingInvoice?.CompanyMobileNO && "(P) "}
                {TourBookingInvoice?.CompanyMobileNO} <br />
                {TourBookingInvoice?.CompanyEmailID && "(Email) "}
                {TourBookingInvoice?.CompanyEmailID}
              </span> */}
            </div>
          </div>
          <div className="col-3 text-end">
            {/* <div>
              <p className="fw-600 m-0">Scan here to pay</p>
              <img src={RKQRCode} alt="" width={100} />
            </div> */}
          </div>
        </div>
        <div
          className="row mt-2 pt-2 pb-2"
          style={{
            borderTop: "1px solid black",
          }}
        >
          <div className="col-12 fs-6">
            <span className="fw-600">Title</span> :{" "}
            {GroupRouteDetail?.RouteName}
          </div>
        </div>
        {GroupRouteDetail?.GroupRouteInfo &&
          GroupRouteDetail?.GroupRouteInfo?.length > 0 && (
            <div className="row mt-2 pt-2 pb-2">
              <div className="col-12 p-0">
                <h5
                  className="text-center p-2 m-0"
                  style={{ background: "lightgray" }}
                >
                  Itinerary Details
                </h5>
                <table cellPadding={2} className="itinerarytable w-100">
                  <tr className="bg-dark text-white text-center">
                    <th className="border-black p-2">Day</th>
                    <th className="border-black p-2">Details</th>
                    <th className="border-black p-2">Night Staying</th>
                  </tr>
                  {GroupRouteDetail?.GroupRouteInfo &&
                    GroupRouteDetail?.GroupRouteInfo?.length > 0 &&
                    GroupRouteDetail?.GroupRouteInfo?.map((item: any) => (
                      <tr>
                        <td
                          className="border-black p-2"
                          style={{ width: "10%" }}
                        >
                          <span className="fw-600">Day {item?.DayNo} </span><br />
                          {item?.StartDate}
                        </td>
                        <td
                          className="border-black p-2"
                          style={{ width: "70%" }}
                        >
                          <div style={{ textAlign: "justify" }}>
                            <span className="d-block fw-600">
                              {item?.RouteName}
                            </span>
                            {item?.Description}
                          </div>
                        </td>
                        <td
                          className="border-black p-2 text-center"
                          style={{ width: "20%" }}
                        >
                          {item?.CityName}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            </div>
          )}
        <div className="row mt-3">
          <table>
            <tr>
              <td
                style={{
                  width: 80,
                  verticalAlign: "baseline",
                  fontWeight: 600,
                }}
              >
                Remarks :
              </td>
              <td>
                <span
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: GroupRouteDetail?.Remarks,
                  }}
                />
              </td>
            </tr>
          </table>
          {/* <b className="fw-bold">Remarks</b>:{" "}
          <span
            // style={{ display: "inline-block" }}
            dangerouslySetInnerHTML={{ __html: GroupRouteDetail?.Remarks }}
          /> */}
        </div>
      </div>
    </>
  );
};

export default GroupRoutePrint;
