import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { LoadingPanel } from "../../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { getTourReport } from "../services/reports.services";
import { checkAcessRights } from "../../../_helper/helper";
import { EXCEL_EXPORT_ACCESS, PRINT_ACCESS } from "../../../_contstants/common";
import AlertBox from "../../../components/common/AlertBox";
import moment from "moment";

const TourReportView = () => {
  const gridRef = React.useRef<any>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const isExcelExportAccess = checkAcessRights(
    "/tourreportform",
    EXCEL_EXPORT_ACCESS
  );
  const isPrintAccess = checkAcessRights("/tourreportform", PRINT_ACCESS);

  const TourID = params?.TourID;
  const NoOfNights = params?.NoOfNights;
  const TourDate = params?.TourDate;
  const FromDate = params?.FromDate;
  const ToDate = params?.ToDate;

  const loading = useAppSelector((state) => state.reports.loading);
  const TourReport = useAppSelector((state) => state.reports.TourReport);

  const [uiLoading, setLoading] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (TourID) {
      const payload = {
        TourID: TourID ? TourID : null,
        NoOfNights: NoOfNights !== "0" ? NoOfNights && parseInt(NoOfNights) : 0,
        TourDate: TourDate !== "0" ? TourDate : "",
        FromDate: FromDate !== "0" ? FromDate : "",
        ToDate: ToDate !== "0" ? ToDate : "",
      };
      dispatch(getTourReport(payload));
    }
  }, []);

  let totalRoom = 0;
  let totalNoOfNights = 0;
  let totalExtraBed = 0;
  let totalNoBed = 0;
  // let totalInfants = 0;
  let totalAdults = 0;
  let totalNoOfPax = 0;
  TourReport &&
    TourReport.length > 0 &&
    TourReport?.forEach((booking: any) => {
      totalRoom += booking?.TotalNoOfRooms || 0;
      totalNoOfNights += booking?.NoOfNights || 0;
      totalAdults += booking?.TotalAdults || 0;
      totalExtraBed += booking?.ExtraBed || 0;
      totalNoBed += booking?.TotalCNB || 0;
      // totalInfants += booking?.TotalInfants || 0;
      totalNoOfPax += booking?.TotalNoOfPax || 0;
    });

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.table_to_sheet(
      document.querySelector(".table-bordered")
    );
    XLSX.utils.book_append_sheet(wb, ws, "TourReport");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "tourreport.xlsx");
  };

  const printTable = () => {
    let table = document.querySelector(
      ".table-bordered"
    ) as HTMLTableElement | null;
    let printbuttons = document.querySelector(".printbuttons") as any;
    let report_container = document.querySelector(".report-container") as any;
    if (!table) {
      console.error("Table element not found.");
      return;
    }
    printbuttons.style.display = "none";
    report_container.style.padding = "20px 0";
    let tableRows = table.rows.length;
    let maxRows = table.rows.length;
    let pageCount = Math.ceil(tableRows / maxRows);
    let printContent = document.createElement("div");
    let content = "";

    for (let i = 0; i < pageCount; i++) {
      content += '<div class="print-page">';
      //   content += "<h2>Page " + (i + 1) + "</h2>";
      content += '<table class="table table-bordered reportScreens">';

      let startRow = i * maxRows;
      let endRow = Math.min((i + 1) * maxRows, tableRows);

      for (let j = startRow; j < endRow; j++) {
        content += table.rows[j].outerHTML;
      }

      content += "</table>";
      content += "</div>";
    }

    printContent.innerHTML = content;
    table.style.display = "none";
    document.body.appendChild(printContent);

    const style = document.createElement("style");
    style.textContent = `@page { margin: 1cm; }`;
    document.head.appendChild(style);

    window.print();
    printContent.remove();
    table.style.display = "table";
    printbuttons.style.display = "block";
    report_container.style.padding = "20px 10px";
  };

  if (uiLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <div
        className="report-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 10px",
        }}
      >
        <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
          Tour Report{" "}
          <span style={{ fontSize: 15 }}>
            {TourReport &&
              TourReport.length > 0 &&
              `(
              ${TourReport[0]?.TourName} ${
                FromDate !== "0" || ToDate !== "0" ? "-" : ""
              } ${
                FromDate !== "0"
                  ? moment(FromDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }${FromDate !== "0" && ToDate !== "0" ? " to " : ""}${
                ToDate !== "0"
                  ? moment(ToDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : " "
              }
              ${
                TourDate !== "0"
                  ? moment(TourDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : ""
              }
              )`}
          </span>
        </Typography.h4>
        <div className="printbuttons">
          {isPrintAccess && TourReport && TourReport.length > 0 && (
            <Button
              style={{ marginRight: 5 }}
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={printTable}
            >
              Print
            </Button>
          )}

          {isExcelExportAccess && TourReport && TourReport.length > 0 && (
            <Button
              type="button"
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={handleExportExcel}
            >
              Excel Export
            </Button>
          )}
        </div>
      </div>
      {TourReport && TourReport.length > 0 ? (
        <div className="table-responsive" style={{ margin: "0 10px" }}>
          <table className="table table-bordered reportScreens">
            <thead>
              <tr>
                <th style={{ background: "black", color: "white" }}>No</th>
                <th style={{ background: "black", color: "white" }}>
                  Guest Name
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Tour Date
                </th>
                <th style={{ background: "black", color: "white" }}>Rooms</th>
                <th style={{ background: "black", color: "white" }}>
                  No of Nights
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Total Adults
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Extra Bed
                </th>
                <th style={{ background: "black", color: "white" }}>No Bed</th>
                {/* <th style={{ background: "black", color: "white" }}>Infants</th> */}
                <th style={{ background: "black", color: "white" }}>
                  No Of Pax
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Mobile No
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Pickup Place
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Drop Place
                </th>
                <th style={{ background: "black", color: "white" }}>
                  Vehicle Sharing Pax
                </th>
                <th style={{ background: "black", color: "white" }}>Food</th>
              </tr>
            </thead>
            <tbody>
              {TourReport &&
                TourReport.length > 0 &&
                TourReport?.map((tour: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{tour?.CustomerName}</td>
                    <td>
                      {tour?.TourDate
                        ? moment(tour?.TourDate, "DD/MM/YYYY").format(
                            "DD MMM YYYY"
                          )
                        : ""}
                    </td>
                    <td>{tour?.TotalNoOfRooms || 0}</td>
                    <td>{tour?.NoOfNights || 0}</td>
                    <td>{tour?.TotalAdults || 0}</td>
                    <td>{tour?.ExtraBed || 0}</td>
                    <td>{tour?.TotalCNB || 0}</td>
                    {/* <td>{tour?.TotalInfants || 0}</td> */}
                    <td>{tour?.TotalNoOfPax || 0}</td>
                    <td>{tour?.MobileNo}</td>
                    <td>{tour?.PickUpPlace}</td>
                    <td>{tour?.DropPlace}</td>
                    <td>{tour?.VehicleSharingPax}</td>
                    <td>{tour?.Foodoption}</td>
                  </tr>
                ))}
              <tr className="fw-bold">
                <td
                  colSpan={3}
                  className="text-end pe-5"
                  style={{ background: "lightgray", color: "black" }}
                >
                  Total
                </td>
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalRoom}
                </td>
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalNoOfNights}
                </td>
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalAdults}
                </td>
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalExtraBed}
                </td>
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalNoBed}
                </td>
                {/* <td style={{ background: "lightgray", color: "black" }}>
                {totalInfants}
              </td> */}
                <td style={{ background: "lightgray", color: "black" }}>
                  {totalNoOfPax}
                </td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
                <td style={{ background: "lightgray", color: "black" }}></td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <AlertBox style={{ margin: "0 10px 10px" }} />
      )}
    </>
  );
};

export default TourReportView;
