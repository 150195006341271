import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
// import { openDialog } from "../../components/dialog/dialogSlice";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
// import { MdDelete } from "react-icons/md";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import AlertBox from "../../components/common/AlertBox";
// import { setHotelBookingID } from "./hotelVoucherSlice";
import { getAllHotelBookings } from "./services/hotelVoucher.services";
import { checkAcessRights } from "../../_helper/helper";
import { EDIT_ACCESS } from "../../_contstants/common";

const HotelVoucher: React.FC = () => {
  return (
    <>
      <DeleteHotelBookingDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Hotel Voucher List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <HotelBookingGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const HotelBookingGridComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);

  const HotelBookingList = useAppSelector(
    (state) => state.hotelVoucher.HotelBookingList
  );
  const loading = useAppSelector((state) => state.hotelVoucher.loading);

  useEffect(() => {
    dispatch(getAllHotelBookings());
  }, []);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();
  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? HotelBookingList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditHotelBooking = (
      ID: number
      // HotelBookingNo: any,
      // TourBookingNo: any
    ) => {
      navigate("/hotelvoucher/edit", {
        state: {
          HotelBookingID: ID,
          // HotelBookingNo: HotelBookingNo,
          // TourBookingID: TourBookingNo,
        },
      });
    };
    // const handleOpenDeleteDialog = (ID: number) => {
    //   dispatch(openDialog("deleteHotelBooking"));
    //   dispatch(setHotelBookingID(ID));
    // };
    return (
      <td className={`action-td ${props.className}`} style={props.style}>
        {isEditAccess && (
          <IconButton
            type="button"
            fillMode="flat"
            size="small"
            title="Edit"
            onClick={() =>
              handleEditHotelBooking(
                props.dataItem?.ID
                // props.dataItem?.HotelBookingNo,
                // props.dataItem?.TourBookingNo
              )
            }
          >
            <FiEdit
              className="absolute-position"
              style={{ fontSize: "20px" }}
            />
          </IconButton>
        )}
        {/* <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.ID)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton> */}
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 8 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const gridRef = useRef<any>(null);
  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {HotelBookingList && HotelBookingList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(HotelBookingList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : HotelBookingList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={HotelBookingList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="HotelBookingNo" title="Hotel Booking No" width={200} />
          <Column field="TourBookingNo" title="Tour Booking No" width={200} />
          <Column field="TourName" title="Package Name" width={350} />
          <Column field="TourDateCode" title="Tour Date Code" width={200} />
          <Column field="TourDate" title="Tour Date" width={150} />
          <Column field="Name" title="Name" width={200} />
          <Column field="NoOfNights" title="NoOfNights" width={150} />
          <Column field="BookByName" title="BookByName" width={200} />
          {isEditAccess && (
            <Column
              field="ID"
              title="Actions"
              cell={MyEditCommandCell}
              width={"110px"}
              filterable={false}
              locked={true}
            />
          )}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteHotelBookingDialog: React.FC = () => {
  //   const dispatch = useAppDispatch();
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const HotelBookingID = useAppSelector(
    (state) => state.hotelVoucher.HotelBookingID
  );
  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      //   const response = await dispatch(deleteHotelBooking(ID));
      //   if (response.payload.Data.Status === 200) {
      //     dispatch(getAllHotelBookings());
      //     dispatch(closeDialog());
      //     dispatch(clearHotelBookingID());
      //   } else {
      //     dispatch(getAllHotelBookings());
      //     dispatch(closeDialog());
      //     dispatch(clearHotelBookingID());
      //   }
    }
  };
  return (
    <>
      {dialogName === "deleteHotelBooking" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Hotel Booking"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Hotel Booking?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(HotelBookingID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default HotelVoucher;
