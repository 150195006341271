import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IRouteVoucher, IRouteVoucherState } from "./routeVoucherModel";
import {
  createRouteVoucher,
  deleteRouteVoucher,
  getAllRouteVouchers,
  getGroupRouteInfo,
  getMultiTourBookingData,
  getRouteVoucherByID,
  updateRouteVoucher,
} from "./services/routeVoucher.services";

const initialState: IRouteVoucherState = {
  loading: false,
  error: "",
  deleteIndex: -1,
  RouteVoucherID: null,
  RouteVoucherList: [],
  RouteVoucherDetail: {
    ID: null,
    BookBy: "",
    CompanyID: null,
    TotalPax: null,
    VehicleSharing: "",
    TourBookingNo: "",
    RouteVoucher: "",
    SectorID: null,
    Sector: "",
    TourID: null,
    Tour: "",
    TourDateCode: "",
    Company: "",
    VehicleTypeID: null,
    VehicleType: "",
    TransporterID: null,
    Transporter: "",
    TransporterCityID: null,
    GroupRouteID: null,
    GroupRoute: "",
    PickUpDate: "",
    DropDate: "",
    PickUpFrom: "",
    DropAt: "",
    TotalNoOfDays: null,
    TourDateID: null,
    ItemCount: null,
    TotalPages: null,
    TouristPaxInfo: [],
    RouteInfo: [],
    Pagesize: null,
    PageNo: null,
    Remarks: "",
    OldRouteVoucherNo: "",
    Branch: null,
    Name: null,
    PickUpFlightNo: "",
    DropFlightNo: "",
    IsActive: true,
  },
  RouteInfo:[],
};

const routeVoucherSlice = createSlice({
  name: "routeVoucher",
  initialState,
  reducers: {
    clearRouteVoucherDetails: (state) => {
      state.RouteVoucherDetail = initialState.RouteVoucherDetail;
    },
    setRouteVoucherID: (state, action) => {
      state.RouteVoucherID = action.payload;
    },
    clearRouteVoucherID: (state) => {
      state.RouteVoucherID = initialState.RouteVoucherID;
    },
    setRouteVoucherDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRouteVouchers.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRouteVouchers.fulfilled,
      (state, action: PayloadAction<IRouteVoucher[]>) => {
        state.loading = false;
        state.RouteVoucherList = action.payload || [];
      }
    );
    builder.addCase(getAllRouteVouchers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RouteVoucherList = [];
    });
    //     builder.addCase(getAllActiveHotelBookings.pending, (state) => {
    //       state.loading = true;
    //       state.error = "";
    //     });
    //     builder.addCase(
    //       getAllActiveHotelBookings.fulfilled,
    //       (state, action: PayloadAction<IHotelBooking[]>) => {
    //         state.loading = false;
    //         state.HotelBookingList = action.payload || [];
    //       }
    //     );
    //     builder.addCase(getAllActiveHotelBookings.rejected, (state, action) => {
    //       state.loading = false;
    //       state.error = action.error.message || "Something went wrong";
    //       state.HotelBookingList = [];
    //     });
    builder.addCase(createRouteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createRouteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createRouteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRouteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateRouteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRouteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRouteVoucher.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRouteVoucher.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRouteVoucher.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    //     builder.addCase(ActiveInactiveHotelBooking.pending, (state) => {
    //       state.loading = true;
    //       state.error = "";
    //     });
    //     builder.addCase(ActiveInactiveHotelBooking.fulfilled, (state) => {
    //       state.loading = false;
    //     });
    //     builder.addCase(ActiveInactiveHotelBooking.rejected, (state, action) => {
    //       state.loading = false;
    //       state.error = action.error.message || "Something went wrong";
    //     });
    builder.addCase(getRouteVoucherByID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getRouteVoucherByID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RouteVoucherDetail = action.payload;
    });
    builder.addCase(getRouteVoucherByID.rejected, (state, action) => {
      state.loading = false;
      state.RouteVoucherDetail = initialState.RouteVoucherDetail;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getMultiTourBookingData.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getMultiTourBookingData.fulfilled, (state, action: any) => {
      state.loading = false;
      state.RouteVoucherDetail = action.payload;
    });
    builder.addCase(getMultiTourBookingData.rejected, (state, action) => {
      state.loading = false;
      state.RouteVoucherDetail = initialState.RouteVoucherDetail;
      state.error = action.error.message || "Something went wrong";
    });   

    builder.addCase(getGroupRouteInfo.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getGroupRouteInfo.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.loading = false;
        state.RouteInfo = action.payload
      }
    );
    builder.addCase(getGroupRouteInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RouteInfo = [];
    });
  },
});

export const {
  clearRouteVoucherID,
  setRouteVoucherID,
  clearRouteVoucherDetails,
  setRouteVoucherDeleteIndex,
} = routeVoucherSlice.actions;
export default routeVoucherSlice.reducer;
