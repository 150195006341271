import React, { useEffect } from "react";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { MdDelete } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { setRouteVoucherDeleteIndex } from "./routeVoucherSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import IconButton from "../../components/common/IconButton";
import { getAllActiveHotels } from "../hotel/services/hotel.services";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormTextArea from "../../components/formFields/FormTextArea";
import { IRoute } from "../route/routeModel";
import {
  getAllActiveRoutes,
  getRouteByID,
} from "../route/services/route.services";
import { ISectorCity } from "../city/cityModel";

const RouteArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const RouteDeleteIndex = useAppSelector(
    (state) => state.routeVoucher.deleteIndex
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const RouteList = useAppSelector((state) => state.route.RouteList);
  const HotelList = useAppSelector((state) => state.hotel.HotelList);
  const SectorCityList = useAppSelector((state) => state.city.SectorCityList);

  useEffect(() => {
    dispatch(getAllActiveHotels());
    dispatch(getAllActiveRoutes());
  }, []);

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("routeDeleteDialog"));
    dispatch(setRouteVoucherDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    (index: number) => {
      fieldArrayRenderProps.onRemove({ index: index });
      dispatch(closeDialog());
      dispatch(setRouteVoucherDeleteIndex(-1));
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        DayNo: "",
        CityID: null,
        HotelID: null,
        RouteID: null,
        Description: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleRouteChange = async (
    e: any,
    index: number,
    fieldArrayRenderProps: FieldArrayRenderProps
  ) => {
    if (e?.value) {
      const res = await dispatch(getRouteByID(e?.value));
      const RouteDetail = res?.payload as any;
      fieldArrayRenderProps?.formRenderProps?.onChange(
        `RouteInfo.${index}.Description`,
        {
          value: RouteDetail?.Description || "",
        }
      );
    }
  };

  const filterHotels = (CityID: number) => {
    if (CityID) {
      const result = HotelList.filter((hotel: any) => {
        return hotel.CityID === CityID;
      });
      return result.map((hotel) => {
        return {
          value: hotel.ID,
          label: hotel.HotelName,
        };
      });
    }
  };

  return (
    <GridLayout
      style={{ marginRight: 10 }}
      gap={{ rows: 5, cols: 10 }}
      cols={[{ width: "50%" }, { width: "50%" }]}
    >
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      <GridLayoutItem style={{ marginBottom: 10 }} colSpan={2}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Route
        </Button>
      </GridLayoutItem>
      {dialogName === "routeDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Route"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(RouteDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((RouteInfo: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card
              className="fieldarray-card"
              style={{
                padding: "20px",
              }}
            >
              <GridLayout
                style={{ marginRight: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "50%" }, { width: "50%" }]}
              >
                <GridLayoutItem>
                  <div
                    style={{
                      display: "inline-block",
                      color: "white",
                      background: "red",
                      padding: "8px 15px",
                      borderRadius: "20px",
                    }}
                  >
                    Day - {index + 1}
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name={`RouteInfo.${index}.RouteID`}
                    label="Route"
                    component={FormSelectionField}
                    onChange={(e) =>
                      handleRouteChange(e, index, fieldArrayRenderProps)
                    }
                    options={RouteList?.map((route: IRoute) => {
                      return {
                        value: route?.ID,
                        label: route?.RouteName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name={`RouteInfo.${index}.CityID`}
                    label="Place"
                    component={FormSelectionField}
                    options={SectorCityList?.map((city: ISectorCity) => {
                      return {
                        value: city.ID,
                        label: city.CityName,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <Field
                    name={`RouteInfo.${index}.HotelID`}
                    label="Hotel"
                    component={FormSelectionField}
                    options={HotelList && filterHotels(RouteInfo.CityID)}
                  />
                </GridLayoutItem>

                <GridLayoutItem rowSpan={2} colSpan={2}>
                  <Field
                    name={`RouteInfo.${index}.Description`}
                    label="Description"
                    component={FormTextArea}
                    rows={5}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
    </GridLayout>
  );
};

export default RouteArray;
